import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import axios from "axios"

module.export = Vue.component('editMeterReadingModal',
    {
        name: 'editMeterReadingModal',
        props: {
            item: Object,
            enabledTypes: Array
        },
        components: {
            DatePicker
        },
        data: function () {
            return {
                alerts: [],
                currentItem: {},
                imageUrl: '',
                formData: new FormData(),
                Comments: '',
                types: Object.assign([], this.enabledTypes.sort(x => x.displayName)),
                isUploading: undefined,
                isSaved: false
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            resetForm: function () {
                this.formData = new FormData();
                this.lastReadingValue = "-";
                this.currentItem = {
                    Date: null,
                    Type: null,
                    Value: null,
                    Attachment: null
                };
                this.imageUrl = '';
                this.clearAlert();
                this.Comments = '';
                this.isSaved = false;
                this.isUploading = undefined;
                const form = document.getElementById("editForm");
                form.reset();
            },
            validateBeforeSubmit: function (event) {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        $("#saveButton").prop("disabled", true);
                        this.isUploading = true;

                        this.formData.set("meterReadingId", this.currentItem.Id);
                        this.formData.set("type", this.currentItem.Type);
                        this.formData.set("date", this.currentItem.Date);
                        this.formData.set("value", this.currentItem.Value);
                        this.formData.set("comments", this.Comments);

                        axios.post('/MeterReadings/Edit',
                            this.formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }).then((response) => {
                                this.isUploading = false;
                                if (response.data.success) {
                                    this.isSaved = true;
                                    $("#saveButton").prop("disabled", false)
                                    $('#asset-details--edit-meter-reading').modal('hide');
                                    this.$emit('meter-reading-updated', "Reading was saved successfully", "UPDATED", Object.assign({}, this.currentItem));
                                    this.resetForm();
                                } else {
                                    this.addAlert(
                                        {
                                            "message": `Could not edit meter reading: ${response.data.data}`,
                                            "isSuccess": false
                                        });
                                    $("#saveButton").removeAttr("disabled");
                                }
                            }).catch(error => {
                                this.isUploading = false;
                                $("#saveButton").prop("disabled", false)
                                console.error(error);
                                this.isSaved = false;
                                if (error.message === 'Network Error') {
                                    this.addAlert(
                                        {
                                            "message": 'Uploading and scanning file took longer than expected. Please refresh page to see if upload was successful',
                                            "isSuccess": false
                                        });
                                } else {
                                    this.addAlert(
                                        {
                                            "message": 'Could not add document due to a server error',
                                            "isSuccess": false
                                        });
                                }
                                $("#saveButton").removeAttr("disabled");
                            });
                        return;
                    } else {
                        this.addAlert(
                            {
                                "message": 'Some fields in the form are invalid',
                                "isSuccess": false
                            });
                    }
                });
            },
            chooseFile: function () {
                const uploadControl = document.getElementById("imageUploadForEdit");

                if (uploadControl) {
                    uploadControl.click();
                }
            },
            imageChanged: function (e) {
                if (e.target.files.length === 1) {
                    const selectedFile = e.target.files[0];
                    this.currentItem.Attachment = selectedFile;
                    this.imageUrl = URL.createObjectURL(selectedFile);

                    this.formData.set("file", this.currentItem.Attachment, this.currentItem.Attachment.name)
                }
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.resetForm();
                $('#asset-details--edit-meter-reading').modal('hide');
            },
        },
        watch: {
            'item': function (newValue) {
                this.currentItem = Object.assign({}, newValue);

                if (this.currentItem.Attachment) {
                    this.imageUrl = this.currentItem.Attachment.Url;
                }
                else {
                    this.imageUrl = '';
                }
            }
        }
    });