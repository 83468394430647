import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import axios from "axios"

module.export = Vue.component('requestnewlease',
    {
        name: "requestnewlease",
        props: {
            data: Object
        },
        components: {
            DatePicker
        },
        data: function () {
            return {
                alerts: [],
                RequestNewLeaseModel: structuredClone(this.data), //create deep a copy of the object
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            validateBeforeSubmit: function (event) {
                event.preventDefault();
                const vueObject = this;

                this.$validator.validateAll().then((result) => {
                    if (result) {
                        const postData = JSON.stringify({
                            Country: vueObject.RequestNewLeaseModel.Country,
                            Industry: vueObject.RequestNewLeaseModel.Industry,
                            ExtraInformation: vueObject.RequestNewLeaseModel.ExtraInformation,
                            FirstName: vueObject.RequestNewLeaseModel.FirstName,
                            LastName: vueObject.RequestNewLeaseModel.LastName,
                            Email: vueObject.RequestNewLeaseModel.Email,
                            TelephoneNumber: vueObject.RequestNewLeaseModel.TelephoneNumber
                        });
                        axios.post('/Forms/RequestNewLease',
                            postData,
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }).then((response) => {
                                if (response.data.success) {
                                    this.$emit('lease-requested', "Successfully sent new lease request");
                                    this.closeModal();
                                } else {
                                    this.addAlert(
                                        {
                                            "message": `Could not send lease request: ${response.data.data}`,
                                            "isSuccess": false
                                        });
                                }
                            }).catch(error => {
                                console.error(error);
                                this.addAlert(
                                    {
                                        "message": 'Could not send lease request due to a server error',
                                        "isSuccess": false
                                    });
                            });
                    } else {
                        this.addAlert({
                            "message": 'Some fields in the form are invalid',
                            "isSuccess": false
                        });
                    }
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.closeModal();
            },
            closeModal: function () {
                this.clearAlert();
                this.RequestNewLeaseModel = structuredClone(this.data);
                $('#request-new-lease-modal').modal('hide');
            }
        }
    });
