import Vue from 'vue'
import VeeValidate from "vee-validate"
import "./Index.less"
import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'

if (document.getElementById('vue-asset-types-overview')) {
    const actionbar = require("../Actionbar/Actionbar.js");
    const alerts = require("../Alerts/Alerts.js");
    require("./Add.js");
    require("./Edit.js");
    require("./SetDefaultRights.js");

    Vue.use(GridInstaller);
    Vue.use(VeeValidate);

    const v = new Vue({
        el: '#vue-asset-types-overview',
        components: {},
        data: function () {
            return {
                GridConfiguration: window.preLoadedData.GridConfiguration,
                AddModel: {
                    AssetTypeName: '',
                    MasterAssetTypeName: '',
                    DefaultFieldsToBeHidden: null,
                },
                EditModel: window.preLoadedData.DefaultFields,
                dataSource: {
                    data: [],
                    transport: {
                        read: {
                            url: "/AssetTypes/List",
                            contentType: "application/json",
                            type: "POST"
                        },
                        parameterMap: function (options) {
                            return kendo.stringify(options);
                        }
                    },
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    pageSize: 20,
                    schema: {
                        data: "data",
                        total: "total"
                    }
                },
                pageable: {
                    buttonCount: 3,
                    info: true,
                    type: 'numeric',
                    pageSizes: [10, 20, 30, 50],
                    previousNext: true
                },
                columns: [
                    { 'field': 'selected', 'title': ' ', 'filterable': false, 'template': '<div #= selected ? \'class="state-selected"\' : "" #><a class="visual-checkbox" href="\\#"><input type="checkbox" #= selected ? \'checked="checked"\' : "" # /></a></div>', 'width': '40px' },
                    { 'field': 'assetTypeName', 'title': 'Asset Type', 'filterable': false },
                    { 'field': 'masterAssetTypeName', 'title': 'Master Asset Type', 'filterable': false },
                    { 'field': 'numberOfFields', 'title': 'Number of fields', 'filterable': false }
                ],
                actionbar: window.preLoadedData.Actionbar,
                search: {
                    visible: true
                },
                alerts: []
            };
        },
        created: function () {
            this.actionbar.searchVisible = true;
        },
        mounted: function () {
            const deleteAssetTypeButton = this.actionbar.ItemsRight.find(f => f.event === "ab-delete-asset-types") ? this.actionbar.ItemsRight.find(f => f.event === "ab-delete-asset-types") : this.actionbar.ItemsLeft.find(f => f.event === "ab-delete-asset-types");
            if (deleteAssetTypeButton != null) {
                deleteAssetTypeButton.visible = window.preLoadedData.rights.deleteAssetType;
            }

            const addAssetTypeButton = this.actionbar.ItemsRight.find(f => f.event === "ab-add-asset-type") ? this.actionbar.ItemsRight.find(f => f.event === "ab-add-asset-type") : this.actionbar.ItemsLeft.find(f => f.event === "ab-add-asset-type");
            if (addAssetTypeButton != null) {
                addAssetTypeButton.visible = window.preLoadedData.rights.addAssetType;
            }

            const setDefaultRightsButton = this.actionbar.ItemsRight.find(f => f.event === "ab-set-default-rights") ? this.actionbar.ItemsRight.find(f => f.event === "ab-set-default-rights") : this.actionbar.ItemsLeft.find(f => f.event === "ab-set-default-rights");
            if (setDefaultRightsButton != null) {
                setDefaultRightsButton.visible = window.preLoadedData.rights.setDefaultRights;
            }

            const downloadComplianceButton = this.actionbar.ItemsRight.find(f => f.event === "ab-download-compliance") ? this.actionbar.ItemsRight.find(f => f.event === "ab-download-compliance") : this.actionbar.ItemsLeft.find(f => f.event === "ab-download-compliance");
            if (downloadComplianceButton != null) {
                downloadComplianceButton.visible = window.preLoadedData.rights.downloadCompliance;
            }

            const grid = this.$refs.grid.kendoWidget();
            const _this = this;
            grid.element.on('click', '.visual-checkbox',
                function (e) {
                    e.preventDefault();
                    const cb = this.querySelector("input[type='checkbox']");
                    cb.checked = !cb.checked;
                    const dataItem = grid.dataItem($(this).closest("tr[data-uid"));
                    if (cb.checked) {
                        $(this.parentElement).addClass("state-selected");
                    } else {
                        $(this.parentElement).removeClass("state-selected");
                    }
                    dataItem.selected = cb.checked;

                    _this.selectOnChange();
                });
        },
        computed: {
            columnsWithRights: function () {
                let columnsWithRights = JSON.parse(JSON.stringify(this.columns));
                if (window.preLoadedData.rights.addAssetType) {
                    columnsWithRights.push({ 'title': ' ', 'filterable': false, 'template': '<a class="anchor-view" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--edit" title="Edit"></span></a>', 'width': '90px' });
                } else {
                    columnsWithRights.push({ 'title': ' ', 'filterable': false, 'template': '<a class="anchor-view" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--view" title="View"></span></a>', 'width': '90px' });
                }
                return columnsWithRights;
            },
        },
        methods: {
            refreshGrid: function () {
                const grid = this.$refs.grid.kendoWidget();
                grid.dataSource.read();
            },
            RedirectToDetails: function (id) {
                window.location.href = "./AssetTypes/Details?id=" + id;
            },
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            addTooltip: function addTooltipToTableHeaders(grid) {
                grid.element[0].querySelectorAll(".k-header").forEach(element => {
                    const title = $(element).data("title");

                    if (!/<[a-z\][\s\S]*>/i.test(title)) {

                        $(element).attr('title', title);
                    }
                });
            },
            actionbarSelectAll: function (checked) {
                const grid = this.$refs.grid.kendoWidget();
                if (checked) {
                    grid.dataSource.data().forEach(i => i.selected = true);
                    grid.element[0].querySelectorAll(".visual-checkbox").forEach(cb => $(cb.parentElement).addClass("state-selected"));
                    grid.element[0].querySelectorAll("input[type='checkbox']").forEach(cb => cb.checked = true);
                } else {
                    grid.dataSource.data().forEach(i => i.selected = false);
                    grid.element[0].querySelectorAll(".visual-checkbox").forEach(cb => $(cb.parentElement).removeClass("state-selected"));
                    grid.element[0].querySelectorAll("input[type='checkbox']").forEach(cb => cb.checked = false);
                }
                this.selectOnChange();
            },
            actionbarDownloadCompliance: function () {
                window.open("/AssetTypes/DownloadCompliance", "_blank");
            },
            actionbarAddAssetType: function () {
                $('#add-asset-type').modal('show');
            },

            actionbarSetDefaultRights: function () {
                $('#set-default-rights').modal('show');
            },
            actionbarDeleteAssetTypes: function () {
                const grid = this.$refs.grid.kendoWidget();
                const selectedItems = grid.dataSource.data().filter(i => i.selected);
                if (selectedItems.length >= 1) {
                    $('#delete-asset-type').modal('show');
                } else {
                    alert('Please select one or more Asset Type\'s to delete');
                }
            },
            confirmedDeleteAssetType: function () {
                const grid = this.$refs.grid.kendoWidget();
                const selectedItems = grid.dataSource.data().filter(i => i.selected).map(i => i.id);
                const _this = this;
                $.ajax({
                    type: "POST",
                    url: "/AssetTypes/Delete",
                    data: JSON.stringify({
                        assetTypeIds: selectedItems
                    }),
                    success: function (data) {
                        $("#delete-asset-type").modal('hide');

                        if (!data.success) {
                            _this.addAlert({
                                "message": data.message,
                                "isSuccess": false
                            });
                        }
                        else {
                            _this.refreshGrid();
                        }
                    },
                    error: function (data) {
                        _this.addAlert({
                            "message": "Could not delete Asset Type(\'s)!",
                            "isSuccess": false
                        });
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            actionbarSearch: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                let filter = {};
                if (e !== null && e !== '') {
                    filter = { logic: 'or', filters: [] };
                    grid.columns.forEach(function (x) {
                        if (x.field) {
                            filter.filters.push({
                                field: x.field,
                                operator: 'contains',
                                value: e.trim()
                            });
                        }
                    });
                }
                grid.dataSource.filter(filter);
            },
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                grid.element.unbind('dblclick');

                grid.element.on('dblclick', 'tbody tr[data-uid]',
                    function (e) {
                        e.preventDefault();
                        e.stopImmediatePropagation();

                        const selectedItems = grid.select().toArray().map(function (s) { return grid.dataItem(s).id; });
                        if (selectedItems.length === 1) {
                            window.location.href = "./AssetTypes/Details?id=" + selectedItems[0];
                        }
                    });

                grid.element.on('click', '.anchor-view',
                    function (e) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        const selectedItems = grid.select().toArray().map(function (s) { return grid.dataItem(s).id; });
                        if (selectedItems.length === 1) {
                            window.location.href = "./AssetTypes/Details?id=" + selectedItems[0];
                        }
                    });

                grid.element.on('click', '.anchor-remove', function (e) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    const assetType = grid.dataItem($(this).closest("tr[data-uid"));
                    $.ajax({
                        type: 'POST',
                        url: '/AssetTypes/Delete',
                        data: JSON.stringify({
                            assetTypeIds: [assetType.Id]
                        }),
                        success: function (data) {
                            if (data.success) {
                                location.reload();
                            } else {
                                _this.$emit('alert',
                                    {
                                        "message": "Could not remove Asset Type.",
                                        "isSuccess": false
                                    });
                                _this.ShowError = true;
                            }
                        },
                        error: function (data) {
                            _this.ShowError = true;
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });

                this.addTooltip(grid);
            },
            selectOnChange: function () {
                const grid = this.$refs.grid.kendoWidget();
                const selected = grid.dataSource.data().filter(i => i.selected);

                const selectAll = document.getElementById('actionbarSelectAll');
                if (selectAll.checked && selected.length < grid.tbody[0].rows.length) {
                    selectAll.checked = false;
                }
            }
        }
    });
    $('.k-header').dblclick(function () {
        if (e.offsetY > $(this).outerHeight() - 2) {
            this.width = getTextWidth(this.textContent, this.font);
            //get column number
            const colNr = this.getAttribute("data-index");
            //select column
            //add search in class k-grid-content
            let gridCols = document.getElementById("asset-type-management-grid").getElementsByClassName("k-grid-content")[0].querySelector("colgroup").childNodes;
            gridCols[colNr].width = $(this).outerWidth();
        }
    })
}
