import Vue from 'vue'
import "./ApprovalAccounts.less"

module.export = Vue.component('approvalaccountsmodal',
    {
        name: 'approvalaccounts',
        props: {
            data: Object,
            selectedAccounts: Array,
            accountCount: Number
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                EditModel: {
                    companyStatus: "",
                },
                ErrorMessage: "Could not change approval companies",
                isSaving: false
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();
                _this.isSaving = true;
                this.$validator.validateAll().then((isValid) => {
                    if (isValid) {
                        $.ajax({
                            type: 'POST',
                            url: '/Settings/ManageAccounts/ChangeApprovalAccounts',
                            data: JSON.stringify({
                                accountIds: _this.selectedAccounts.map(account => account.id),
                                accountStatus: _this.EditModel.companyStatus,
                                remark: _this.EditModel.remark
                            }),
                            success: function (data) {
                                if (!data.success) {
                                    _this.addAlert(
                                        {
                                            "message": `Could not change approval for companies: ${data.data}`,
                                            "isSuccess": false
                                        });
                                    _this.isSaving = false;
                                } else {
                                    const status = _this.EditModel.companyStatus;
                                    _this.close();
                                    _this.$emit('approval-changed', `Company status successfully changed to ${status}`, status, Object.assign({}, this.newItem));
                                }
                            },
                            error: function (data) {
                                _this.addAlert(
                                    {
                                        "message": 'Could not change approval for companies due to a server error',
                                        "isSuccess": false
                                    });
                                _this.isSaving = false;
                            },
                            contentType: "application/json",
                            dataType: 'json'
                        });
                    } else {
                        _this.addAlert(
                            {
                                "message": 'Please fill in all required fields correctly',
                                "isSuccess": false
                            });
                        _this.isSaving = false;
                    }
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                this.EditModel = Object.assign(this.data);
                $('#accountoverview-approval-accounts').modal('hide');
                this.isSaving = false;
            },
        }
    });