import Vue from 'vue'
import axios from "axios"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { fetchJson } from '../Shared/request-utils'

module.export = Vue.component('leasedassetadd',
    {
        components: {
            DatePicker
        },
        name: "leasedassetadd",
        props: {
            AddModel: Object
        },
        data: function () {
            return {
                alerts: [],
                selectedType: null,
                dataSource: null,
                searchText: null,
                statuses: ['Booked', 'Terminated', 'Other'],
                customStatus: '',
                formData: {
                    InvoiceLocation: {},
                    AssetLocation: {}
                }
            };
        },
        mounted: function() {
            const _this = this;
            const $assetTypeSelector = $("#assetTypeSelectorForAdd");

            _this.dataSource = new kendo.data.DataSource({
                serverFiltering: true,
                transport: {
                    read: function (options) {
                        if (options.data.filter &&
                            options.data.filter.filters.length > 0 &&
                            options.data.filter.filters[0].value.length > 0) {
                            _this.searchText = options.data.filter.filters[0].value;
                        }

                        fetchJson('/LeasedAssets/GetAssetTypesForAdd?search=' + _this.searchText)
                            .then(
                                (result) => {
                                    options.success(result);
                                },
                                (error) => {
                                    console.error(error);
                                });
                    }
                },
            });

            $assetTypeSelector.kendoDropDownList({
                filter: true,
                placeholder: "Search for asset type",
                autoBind: false,
                minLength: 3,
                enforceMinLength: true,
                dataSource: _this.dataSource,
                dataTextField: "Name",
                dataValueField: "Id",
                template: ({ Name, MasterAssetTypeName }) => Name + ' (' + MasterAssetTypeName + ')',
                valueTemplate: () => _this.selectedType.Name + ' (' + _this.selectedType.MasterAssetTypeName + ')',
                select: (e) => {
                    const convertArray = (items) => {
                        if (items.length > 0){
                            return items.slice(0, items.length)
                        }

                        return [];
                    }

                    _this.selectedType = {
                        Id: e.dataItem.Id,
                        MasterAssetTypeName: e.dataItem.MasterAssetTypeName,
                        Name: e.dataItem.Name,
                        Fields: convertArray(e.dataItem.Fields).map((field => ({...field, Value: undefined}))),
                        RequiredGeneralFields: convertArray(e.dataItem.RequiredGeneralFields).map((field => ({...field, Value: undefined})))
                    };
                }
            });

            $("#assetTypeSelectorForAdd-list .k-textbox").attr("placeholder", "Search for asset type");                
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            autoFormat: function (evt) {
                if (evt && evt.target && evt.target.value) {

                    if (evt.target.value.indexOf(",") > 0) {
                        const matchingField = this.AddModel.AssetTypeFields.Value.find(x => x.Name === evt.target.name);

                        if (matchingField) {
                            matchingField.Value = evt.target.value.replaceAll(",", ".");

                            this.$validator.validate(evt.target.name, matchingField.Value).then((result) => {
                                if (result) {
                                    const validatedField = this.$validator.fields.find({
                                        name: evt.target.name
                                    });

                                    this.$validator.reset(validatedField);
                                }
                            });
                        }
                    }
                }
            },
            show: function () {
                this.selectedType = undefined;
                $('#leased-asset-add-modal').modal('show');
            },
            save: function() {
                document.getElementById('formboxes').scrollTop = 0;
                this.$validator.validateAll().then((result) => {
                    this.clearAlert();
                    if (result) {
                        const _this = this;
                        const mdl = _this.formData;
                        mdl.AssetStatusDll = _this.formData.AssetStatusDll == 'Other' ? _this.customStatus : _this.formData.AssetStatusDll;
                        mdl["AssetTypeFields"] = _this.selectedType.Fields.reduce(function (accumulator, current) {
                            const name = current["Name"];
                            const value = current["Value"];

                            accumulator[name] = value;
                            return accumulator;
                        },
                            {} /* Empty object, as initial value */);

                        mdl["assetType"] = _this.selectedType.Id;

                        $.ajax({
                            type: 'POST',
                            url: '/LeasedAssets/Add',
                            data: JSON.stringify(mdl),
                            success: function (data) {
                                if (data.success) {
                                    _this.selectedType = null;
                                    _this.formData = {
                                        InvoiceLocation: {},
                                        AssetLocation: {}
                                    };
                                    _this.addAlert(
                                        {
                                            "message": 'Successfully added asset',
                                            "isSuccess": true
                                        });
                                }
                                else {
                                    _this.addAlert(
                                        {
                                            "message": `Could not add asset: ${data.data}`,
                                            "isSuccess": false
                                        });
                                    $("#saveButton").removeAttr("disabled");
                                }
                            },
                            error: function (data) {
                                _this.addAlert(
                                    {
                                        "message": 'Could not add asset due to a server error',
                                        "isSuccess": false
                                    });
                                $("#saveButton").removeAttr("disabled");
                            },
                            contentType: "application/json",
                            dataType: 'json'
                        });
                        return;
                    } else {
                        this.addAlert(
                            {
                                "message": 'Some fields in the form are invalid',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    }
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.selectedType = { Name: "" };
                this.clearAlert();
                this.formData = {
                    InvoiceLocation: {},
                    AssetLocation: {}
                }
                $('#leased-asset-add-modal').modal('hide');

                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            }
        },
        computed: {
            isTerminated() {
                return this.formData.AssetStatusDll == 'Terminated' || this.customStatus == 'Terminated'
            }
        }
    });
