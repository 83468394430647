import Vue from 'vue'
import axios from "axios"

module.export = Vue.component('deleteAssetDocumentModal',
    {
        name: 'deleteAssetDocumentModal',
        props: {
            item: Object
        },
        data: function () {
            return {
                alerts: [],
                formData: new FormData(),
                currentItem: {},
                isDeleted: false
            }
        },
        components: {},
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            resetForm: function () {
                this.currentItem = {};
                this.formData = new FormData();
                this.clearAlert();
            },
            validateBeforeSubmit: function (event) {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.clearAlert();
                        $("#deleteButton").prop("disabled", true);

                        this.formData.set("DocumentId", this.currentItem.Id);

                        axios.post('/Documents/DeleteForAsset', this.formData, {})
                            .then((response) => {
                                if (response.data.success) {
                                    this.isDeleted = true;
                                    $("#deleteButton").prop("disabled", false);
                                    $('#asset-details--delete-document').modal('hide');
                                    this.$emit('document-deleted', "Document was deleted succesfully", "DELETED", Object.assign({}, this.currentItem));
                                    this.resetForm();
                                } else {
                                    this.addAlert(
                                        {
                                            "message": `Could not delete document: ${response.data.data}`,
                                            "isSuccess": false
                                        });
                                    $("#deleteButton").prop("disabled", false);
                                }
                            }).catch(error => {
                                $("#deleteButton").prop("disabled", false)
                                console.error(error);
                                this.isDeleted = false;
                                this.addAlert( 
                                    {
                                        "message": 'Could not delete document due to a server error',
                                        "isSuccess": false
                                    });
                                $("#deleteButton").prop("disabled", false);
                            });
                        return;
                    }
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.resetForm();
                $('#asset-details--delete-document').modal('hide');
            },
        },
        watch: {
            'item': function (newValue) {
                this.currentItem = newValue;
            }
        }
    });