import Vue from 'vue'
import axios from "axios"

module.export = Vue.component('editContractDocumentModal',
    {
        name: 'editContractDocumentModal',
        props: {
            item: Object
        },
        data: function () {
            return {
                alerts: [],
                currentItem: {},
                isSaved: false
            }
        },
        components: {},
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            resetForm: function () {
                this.currentItem = {};
                this.clearAlert();
                this.isSaved = false;
                const form = document.getElementById("editForm");
                form.reset();
            },
            validateBeforeSubmit: function (event) {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.clearAlert();
                        $("#saveDocumentButton").prop("disabled", true);

                        axios.post('/Documents/EditForContract', { 
                                LinkedDocumentId: this.currentItem.Id,
                                DocumentName: this.currentItem.DocumentName
                            }, 
                            {})
                            .then((response) => {
                                if (response.data.success) {
                                    this.isSaved = true;
                                    $("#saveDocumentButton").prop("disabled", false);
                                    $('#contract-details--edit-document').modal('hide');
                                    this.$emit('document-edited', "Linked document was edited succesfully", "EDITED", Object.assign({}, this.currentItem));
                                    this.resetForm();
                                } else {
                                    this.addAlert(
                                        {
                                            "message": `Could not edit linked document: ${response.data.data}`,
                                            "isSuccess": false
                                        });
                                    $("#saveDocumentButton").prop("disabled", false);
                                }
                            }).catch(error => {
                                $("#saveDocumentButton").prop("disabled", false)
                                console.error(error);
                                this.isSaved = false;
                                this.addAlert( 
                                    {
                                        "message": 'Could not edit linked document due to a server error',
                                        "isSuccess": false
                                    });
                                $("#saveDocumentButton").prop("disabled", false);
                            });
                        return;
                    }
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.resetForm();
                $('#contract-details--edit-document').modal('hide');
            },
        },
        watch: {
            'item': function (newValue) {
                this.currentItem = newValue;
            }
        }
    });