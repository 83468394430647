import Vue from 'vue'

module.export = Vue.component('offboardaccountmodal',
    {
        name: "offboardaccountmodal",
        props: {
            data: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                OffboardModel: Object.assign({}, this.data),
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            show: function(data) {
                this.OffboardModel = Object.assign({}, data);
                $('#accountoverview-offboard-account').modal('show');
            },
            save: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();
                $("#saveOffboardButton").prop("disabled", true);
                $.ajax({
                    type: 'POST',
                    url: '/Settings/ManageAccounts/OffboardAccount',
                    data:  JSON.stringify({
                        accountId: _this.OffboardModel.id
                    }),                 
                    success: function (data) {
                        if (data.success) {
                            _this.close();
                            _this.$emit('account-offboarded', `Company successfully offboarded! ${data.data}`);
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not offboard company: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveOffboardButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not offboard company due to a server error',
                                "isSuccess": false
                            });
                        $("#saveOffboardButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                $('#accountoverview-offboard-account').modal('hide');
            }
        }
    });