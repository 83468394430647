import Vue from 'vue'

module.export = Vue.component('editmailsettingsmodal',
    {
        name: 'editmailsettings',
        props: {
            data: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                EditModel: Object.assign({}, this.data),
                isSaving: false
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();

                _this.isSaving = true;

                this.$validator.validateAll().then((isValid) => {
                    if (isValid) {
                        $.ajax({
                            type: 'POST',
                            url: '/Settings/ManageMailSettings/Edit',
                            data: JSON.stringify({
                                country: _this.EditModel.country,
                                requestNewLeaseEmail: _this.EditModel.requestNewLeaseEmail,
                                messageUsEmail: _this.EditModel.messageUsEmail,
                                terminationRequestEmail: _this.EditModel.terminationRequestEmail,
                                returnRequestEmail: _this.EditModel.returnRequestEmail,
                                extensionRequestEmail: _this.EditModel.extensionRequestEmail
                            }),
                            success: function (data) {
                                if (data.success) {
                                    _this.close();
                                    _this.$emit('mail-settings-changed', 'Default mail settings changed successfully');
                                } else {
                                    _this.addAlert(
                                        {
                                            "message": `Could not change mail settings: ${data.data}`,
                                            "isSuccess": false
                                        });
                                }
                                _this.isSaving = false;
                            },
                            error: function (data) {
                                _this.addAlert(
                                    {
                                        "message": 'Could not change mail settings due to a server error',
                                        "isSuccess": false
                                    });
                                _this.isSaving = false;
                            },
                            contentType: "application/json",
                            dataType: 'json'
                        });
                    } else {
                        _this.addAlert(
                            {
                                "message": 'Could not change default mail settings due to incorrect or incomplete fields.',
                                "isSuccess": false
                            });
                    }
                });
                
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            show: function(data) {
                this.EditModel = Object.assign({}, data);
                $('#emailoverview-edit-email-setting').modal('show');
            },
            close: function () {
                this.clearAlert();
                $('#emailoverview-edit-email-setting').modal('hide');

                // Enable save button again.
                this.isSaving = false;
            }
        }
    }
);
