import Vue from 'vue'

if (document.getElementById('pre-login-form')) {

    new Vue({
        el: '#pre-login-form',
        data: function () {
            return {
                isSubmitted: false,
                isValid: undefined,
                email: null,
            }
        },
        watch: {
            'email': function (newValue) {
                this.isValid = this.validEmail(newValue);
                this.isSubmitted = !this.isValid;
            }
        },
        methods: {
            validateLogin: function (evt) {
                const form = evt.target;

                this.isSubmitted = true;
                this.isValid = this.validEmail(this.email);

                if (this.isValid) {
                    form.submit();
                }

                evt.preventDefault();
            },
            validEmail: function (email) {
                if (!email)
                    return false;

                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }
        }
    });
}