import Vue from 'vue'
import VeeValidate from "vee-validate"
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import "./Details.less"
import "../Grid/Grid.less"

if (document.getElementById('lessee-details')) {
    require('./Edit.js');
    Vue.use(GridInstaller);
    Vue.use(VeeValidate);

    new Vue({
        el: '#lessee-details',
        components: {},
        data: {
            alerts: [],
            EditModel: window.detailModel,
            dataSource: {
                data: window.detailModel.Contracts,
                pageSize: 20
            },
            pageable: {
                buttonCount: 3,
                info: true,
                type: 'numeric',
                pageSizes: [10, 20, 30, 50],
                previousNext: true
            },
            skip: 0,
            take: 20,
            columns: []
        },
        created: function () {
            this.loadColumns();
        },
        mounted: function () {
            if (getUrlParameter('action') === 'edit') {
                this.edit();
            }
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            edit: function () {
                $('#edit-lessee--change-lessee').modal('show');
            },
            loadColumns: function (e) {
                this.columns.push({ 'field': 'ContractName', 'title': 'Contract' });
                this.columns.push({ 'field': 'Portfolios', 'title': 'Portfolio(s)' });
                this.columns.push({
                    'title': ' ',
                    'filterable': false,
                    'template': function (dataItem) {
                        if (window.detailModel.ContractDetailsUrl !== null && dataItem.IsPartOfMasterAssetPortfolio) {
                            return '<a class="anchor-view" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--view" title="View Contract"></span></a>';
                        } else {
                            return '';
                        }
                    }, 'width': '90px'
                })
            },
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                const _this = this;
                grid.element.on('click', '.anchor-view',
                    function (e) {
                        e.preventDefault();
                        if (window.detailModel.ContractDetailsUrl !== null) {
                            const contract = grid.dataItem($(this).closest("tr[data-uid"));
                            window.location.href = window.detailModel.ContractDetailsUrl + "?id=" + contract.ContractName;
                        }
                    });
            }
        }
    });

    function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
}