import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import "./EditAccountSettings.less"

module.export = Vue.component('editaccountsettingsmodal',
    {
        name: 'editaccountsettings',
        props: {
            data: Object
        },
        components: {
            DatePicker
        },
        data: function () {
            return {
                alerts: [],
                EditModel: Object.assign({}, this.data),
                CompanyStatusOptions: ['Active', 'Pending', 'Rejected', 'Offboarded']
            };
        },
        computed: {
            isRejected: function () {
                return this.EditModel.CompanyStatus == "Rejected"
            }
        },        
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            show: function (data) {
                this.EditModel = Object.assign({}, data);
                $('#edit-account-settings-modal').modal('show');
            },
            save: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();
                $("#saveSettingsButton").prop("disabled", true);

                const trimmedCompanyName = this.EditModel.CompanyName.trim();

                $.ajax({
                    type: 'PUT',
                    url: '/Settings/ManageAccounts/EditAccountSettings',
                    data: JSON.stringify({
                        AccountId: this.EditModel.AccountId,
                        CompanyName: trimmedCompanyName !== this.data.CompanyName ? trimmedCompanyName: null,
                        CompanyManager: this.EditModel.CompanyManager,
                        SelectedCompanyManager: this.EditModel.SelectedCompanyManager,
                        CatchAllEmail: this.EditModel.CatchAllEmail,
                        IsDemoCompany: this.EditModel.IsDemoCompany,
                        Email: this.EditModel.Email,
                        FirstContact: this.EditModel.FirstContact,
                        PhoneNumber: this.EditModel.PhoneNumber,
                        ProgramManager: this.EditModel.ProgramManager,
                        Country: this.EditModel.Country
                    }),
                    success: function (data) {
                        if (data.success) {
                            _this.close();
                            location.reload();
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not edit company settings: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveSettingsButton").removeAttr("disabled");
                        }
                    },
                    error: function () {
                        _this.addAlert(
                            {
                                "message": 'Could not edit company settings due to a server error',
                                "isSuccess": false
                            });
                        $("#saveSettingsButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                sessionStorage.setItem("account.details.activeTab", 'general-info');
                $('#edit-account-settings-modal').modal('hide');
            }
        }
    });