import Vue from 'vue'

module.export = Vue.component('editfunctionsmodal',
    {
        name: 'editfunctions',
        props: {
            data: Object
        },
        components: {},
        data: function () {
            return {
                alerts:[],
                EditModel: Object.assign({}, this.data)
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();
                $("#saveFunctionsButton").prop("disabled", true);
                $.ajax({
                    type: 'PUT',
                    url: '/Settings/ManageAccounts/EditFeatures',
                    data: JSON.stringify({
                        AccountId: this.EditModel.AccountId,
                        AccountFeatures: this.EditModel.AccountFeatures
                    }),
                    success: function (data) {
                        if (data.success) {
                            _this.close();
                            location.reload();
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not edit company features: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveFunctionsButton").removeAttr("disabled");
                        }
                    },
                    error: function () {
                        _this.addAlert(
                            {
                                "message": 'Could not add company features due to a server error',
                                "isSuccess": false
                            });
                        $("#saveFunctionsButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                sessionStorage.setItem("account.details.activeTab", 'available-functions');
                $('#edit-functions-modal').modal('hide');
            }
        }
    });