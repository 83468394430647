import Vue from 'vue'
import axios from "axios"

module.export = Vue.component('addContractDocumentModal',
    {
        name: 'addContractDocumentModal',
        props: {
            contract: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                relatedContract: this.contract,
                countries: this.contract.Documents.Countries,
                newItem: {
                    country: this.contract.Documents.Countries.filter(c => c.CountryName ===  this.contract.Documents.DefaultCountry).length > 0 ? this.contract.Documents.DefaultCountry : '',
                    searchDocument: ''
                },
                isSaved: false,
                dataSource: {
                    data: []
                },
                columns: [
                    { 'template': '<div #= !Selectable ? \'class="state-selected"\' : "" #><a class="visual-checkbox" #= Selectable ? \'href="\\#"\' : "" #><input type="checkbox" #= !Selectable ? \'checked="checked" disabled\' : "" # /></a></div>', 'width': '40px' },
                    { 'field': 'DocumentName', 'title': 'Document name', 'filterable': false },
                    { 'field': 'TimestampFromEpoch', 'title': 'Last modified', 'filterable': false, 'template': "#= kendo.toString(kendo.parseDate(TimestampFromEpoch), 'yyyy/MM/dd HH:mm') #", 'width': '200px' },
                    {
                        'title': ' ',
                        'filterable': false,
                        'template': [
                            '<a class="anchor-view--document" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--view" title="View"></span></a>'
                        ].join(''),
                        'width': '60px'
                    }
                ],
                isFetching: undefined,
                documentList: []
            };
        },
        watch: {
            'contract': function (newValue) {
                this.relatedContract = newValue;
            }
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            resetForm: function () {
                this.newItem = {
                    country: this.contract.Documents.Countries.filter(c => c.CountryName ===  this.contract.Documents.DefaultCountry).length > 0 ? this.contract.Documents.DefaultCountry : '',
                    searchDocument: ''
                },
                this.clearAlert();
                this.isSaved = false;
                this.dataSource = {};
                this.documentList = [];
                const form = document.getElementById("addForm");
                form.reset();
            },
            searchKeydown: function(e) {
                if (this.newItem.country !== '' && this.newItem.searchDocument !== '' && e.keyCode == 13) {
                    this.searchInLibraries();
                }         
            },
            searchInLibraries: function() {
                const library = this.countries.filter(c => c.CountryName === this.newItem.country).map(c => c.Library);
                this.searchDocuments(library, 'Contract Number', this.newItem.searchDocument);
            },
            validateBeforeSubmit: function (event) {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        $("#saveDocumentButton").prop("disabled", true);
                        axios.post('/Documents/AddForContract', { LinkedDocuments: this.documentList } , {})
                            .then(response => {
                                if (response.data.success) {
                                    this.isSaved = true;
                                    $("#saveDocumentButton").prop("disabled", false)
                                    $('#contract-details--add-document').modal('hide');
                                    this.$emit('document-added', "Linked document(s) added succesfully", "ADDED", Object.assign({}, this.newItem));
                                    this.resetForm();
                                } else {
                                    this.addAlert(
                                        {
                                            "message": `Could not addlinked documents: ${response.data.data}`,
                                            "isSuccess": false
                                        });
                                    $("#saveDocumentButton").prop("disabled", false);
                                }
                            }).catch(error => {
                                $("#saveDocumentButton").prop("disabled", false)
                                console.error(error);
                                this.isSaved = false;
                                this.isFetching = false;
                                this.addAlert(
                                    {
                                        "message": 'Could not add linked documents due to a server error',
                                        "isSuccess": false
                                    });
                            });                        
                    } else {
                        this.addAlert(
                            {
                                "message": 'Some fields in the form are invalid',
                                "isSuccess": false
                            });
                        $("#saveDocumentButton").prop("disabled", false);
                    }
                });
            },
            searchDocuments: function(template, param, value) {
                const _this = this;
                _this.isFetching = true;
                _this.dataSource = {};
                axios.get(`/Documents/SearchDocuments?template=${template}&param=${param}&value=${value}`)
                    .then(response => {
                        const existingDocuments = _this.contract.Documents.Values;
                        response.data.forEach((d) => {
                            if (existingDocuments.find(ed => ed.DIN === d.DIN && ed.Timestamp === d.Timestamp)) {
                                d.Selectable = false;
                                d.Selected = false;
                            } else {
                                d.Selectable = true;
                                d.Selected = false;
                            }
                            d.DocumentName = d.DocumentName
                            d.TimestampFromEpoch = new Date(d.Timestamp);
                        });
                        _this.isFetching = false;
                        _this.dataSource = response.data;
                    }).catch(error => {
                        _this.addAlert(
                            {
                                "message": 'Could not search documents due to a server error',
                                "isSuccess": false
                            });
                        _this.isFetching = false;
                    });
            },
            selectedRows(e) {
                this.documentList = [];
                const _this = this;
                const isoCountryCode = this.countries.filter(c => c.CountryName === this.newItem.country).map(c => c.IsoCode)[0];
                const rows = e.sender.dataSource.data();
                rows.forEach(r => {
                    if (r.Selected) {
                        _this.documentList.push({ ContractId: _this.relatedContract.ContractId, Template: r.Template, DocumentName: r.DocumentName, DIN: r.DIN, Timestamp: r.Timestamp, IsoCountryCode: isoCountryCode });
                    }
                });
                if (_this.documentList.length > 0) {
                    $("#saveDocumentButton").prop("disabled", false);
                } else {
                    $("#saveDocumentButton").prop("disabled", true);
                }
            },
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                const _this = this;
                grid.element.off('click', '.anchor-view--document');
                grid.element.off('click', '.visual-checkbox');
                
                grid.element.on('click', '.anchor-view--document',
                function (e) {
                    e.preventDefault();
                    const document = grid.dataItem($(this).closest("tr[data-uid"));
                    window.open(document.DownloadUrl, "_blank");
                });

                grid.element.on('click', '.visual-checkbox',
                    function (e) {
                        e.preventDefault();
                        const cb = this.querySelector("input[type='checkbox']");
                        if (!cb.disabled) {
                            cb.checked = !cb.checked;
                            const item = $(this).closest("tr[data-uid");
                            const index = item.index();
                            const doc = grid.dataItem(item);
                            doc.Selected = cb.checked;
                            if (cb.checked) {
                                $(this.parentElement).addClass("state-selected");
                               _this.dataSource[index].Selected  = true;
                            } else {
                                $(this.parentElement).removeClass("state-selected");
                                _this.dataSource[index].Selected  = false;
                            }
                            grid.trigger('change');
                        }
                    });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.resetForm();
                this.isFetching = undefined;
                $('#contract-details--add-document').modal('hide');
            },
        }
    });