import Vue from 'vue'
import "./Actionbar.less"
import { debounce } from "../Shared/grid-utils"

module.export = Vue.component('actionbar', {
    name: 'actionbar',
    props: ['data'],
    data: function () {
        return {
            searchText: "",
            portfolio: "",
            activePreview: ""
        };
    },
    created: function () {
        this.onAutoSearch = debounce(() => {
            this.$emit('search', this.searchText)
        }, 500);
    },
    methods: {
        updateSearchText: function (searchValue) {
            this.searchText = searchValue;
        }
    }
});