import Vue from 'vue'

if (document.getElementById('customer-support')) {
    require("./ContactUs.js");
    require("../Alerts/Alerts.js");

    new Vue({
        el: '#customer-support',
        data: function () {
            return {
                alerts: [],
                ContactUsViewModel: window.preLoadedData.ContactUsViewModel
            }
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            contactUs: function (message) {
                this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });
            },
            openContactUsForm: function () {
                $('#contact-us-modal').modal('show');
            }
        }
    });
}