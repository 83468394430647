import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

module.export = Vue.component('editusermodal',
    {
        name: 'edituser',
        props: {
            data: Object
        },
        components: {
            DatePicker
        },
        data: function () {
            return {
                alerts: [],
                EditModel: Object.assign({}, this.data), //create deep a copy of the object
                isSaving: false
            };
        },
        computed: {
            isRejected: function () {
                return this.EditModel.status == "Rejected"
            }
        },          
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            show: function (data) {
                this.EditModel = Object.assign({}, data);
                $('#useroverview-edit-user').modal('show');
            },            
            save: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();
                _this.isSaving = true;

                $.ajax({
                    type: 'POST',
                    url: '/Settings/ManageUsers/Edit',
                    data: JSON.stringify({
                        userId: window.User.Id,
                        roleId: _this.EditModel.roleId,
                    }),
                    success: function (data) {
                        if (data.success) {
                            _this.close();
                            _this.$emit('user-changed');
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not edit user: ${data.data}`,
                                    "isSuccess": false
                                });
                        }
                        _this.isSaving = false;
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not edit user due to a server error',
                                "isSuccess": false
                            });
                        _this.isSaving = false;
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                this.EditModel = Object.assign({}, this.data);
                $('#useroverview-edit-user').modal('hide');

                // Enable save button again.
                this.isSaving = false;
            }
        }
    });