import Vue from 'vue'


module.export = Vue.component('selectcountrymodal',
    {
        name: 'selectcountrymodal',
        props: {
            SelectCountryModel: Object
        },
        components: {},
        data: function () {
            return {
                ShowFormError: false
            };
        },
        methods: {
            open: function (e) {
                e.preventDefault();
                const _this = this;
                this.$validator.validateAll().then((result) => {
                    if (result && _this.SelectCountryModel.countryId !== -1 && _this.SelectCountryModel.hrefsProp) {
                        $("#" + _this.SelectCountryModel.elementId).modal('hide');
                        window.location.href = _this.SelectCountryModel.allCountries[_this.SelectCountryModel.countryId].hrefs[_this.SelectCountryModel.hrefsProp]
                    } else {
                        _this.ShowFormError = true;
                    }
                });
            },
            close: function () {
                $("#" + this.SelectCountryModel.elementId).modal('hide');
                // Enable open button again.
                $("#" + this.SelectCountryModel.elementId + "openButton").removeAttr("disabled");
            }
        }
    });