import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import axios from "axios"

module.export = Vue.component('addAssetDocumentModal',
    {
        name: 'addAssetDocumentModal',
        props: {
            asset: Object,
            enabledTypes: Array,
            sourceSystem: String
        },
        components: {
            DatePicker
        },
        data: function () {
            return {
                alerts: [],
                relatedAsset: this.asset,
                formData: new FormData(),
                assignments: ['Contract', 'Asset'],
                newItem: {
                    Assignment: ''
                },
                isSaved: false,
                isUploading: undefined,
                source: this.sourceSystem
            };
        },
        watch: {
            'asset': function (newValue) {
                this.relatedAsset = newValue;
            },
            'sourceSystem': function (newValue) {
                if (newValue) {
                    this.source = newValue;
                }
            }
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            resetForm: function () {
                this.formData = new FormData();
                this.clearAlert();
                this.isSaved = false;
                this.isUploading = undefined;
                const form = document.getElementById("addForm");
                form.reset();
            },
            validateBeforeSubmit: function (event) {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        $("#saveDocumentButton").prop("disabled", true);
                        this.isUploading = true;
                        this.formData.set("assignment", this.newItem.Assignment);
                        this.newItem.Assignment == 'Asset' ? this.formData.set("associatedString", this.relatedAsset.Id) : this.formData.set("associatedString", this.relatedAsset.ContractIdentifier.Value) ;
                        axios.post('/documents/AddForAsset',
                            this.formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }).then((response) => {
                                this.isUploading = false;
                                if (response.data.success) {
                                    this.isSaved = true;
                                    $("#saveDocumentButton").prop("disabled", false)
                                    $('#asset-details--add-document').modal('hide');
                                    this.$emit('document-added', "Document(s) added succesfully", "ADDED", Object.assign({}, this.newItem));
                                    this.resetForm();
                                } else {
                                    this.addAlert(
                                        {
                                            "message": `Could not add document: ${response.data.data}`,
                                            "isSuccess": false
                                        });
                                    $("#saveDocumentButton").prop("disabled", false);
                                }
                            }).catch(error => {
                                this.isUploading = false;
                                $("#saveDocumentButton").prop("disabled", false)
                                console.error(error);
                                this.isSaved = false;
                                if (error.message === 'Network Error') {
                                    this.addAlert(
                                        {
                                            "message": 'Uploading and scanning file took longer than expected. Please refresh page to see if upload was successful',
                                            "isSuccess": false
                                        });
                                } else {
                                    this.addAlert(
                                        {
                                            "message": 'Could not add document due to a server error',
                                            "isSuccess": false
                                        });
                                }
                            });
                        return;
                    } else {
                        this.addAlert(
                            {
                                "message": 'Some fields in the form are invalid',
                                "isSuccess": false
                            });
                        $("#saveDocumentButton").prop("disabled", false);
                    }
                });
            },
            documentChanged: function (e) {
                if (e.target.files.length > 0) {
                    $("#saveDocumentButton").prop("disabled", false);
                } else {
                    $("#saveDocumentButton").prop("disabled", true);
                }
                this.formData.delete('files');
                for (let i = 0; i < e.target.files.length; i++) {
                    const selectedFile = e.target.files[i];
                    this.newItem.Attachment = selectedFile;
                    this.formData.append('files', this.newItem.Attachment, this.newItem.Attachment.name);
                }
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.resetForm();

                $('#asset-details--add-document').modal('hide');
            },
        }
    });