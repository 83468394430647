import Vue from 'vue'
import VeeValidate from "vee-validate"
import 'vue2-datepicker/index.css'
import axios from "axios"

Vue.use(VeeValidate);
module.export = Vue.component('contactus',
    {
        name: 'contactus',
        props: {
            data: Object
        },
        data: function () {
            return {
                alerts: [],
                ContactUsViewModel: structuredClone(this.data)
            }
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            validateBeforeSubmit: function (event) {
                event.preventDefault();
                const vueObject = this;

                this.$validator.validateAll().then((result) => {
                    if (result) {
                        const postData = JSON.stringify({
                            Subject: vueObject.ContactUsViewModel.Subject,
                            ContractNumber: vueObject.ContactUsViewModel.ContractNumber,
                            Question: vueObject.ContactUsViewModel.Question,
                            FirstName: vueObject.ContactUsViewModel.FirstName,
                            LastName: vueObject.ContactUsViewModel.LastName,
                            Email: vueObject.ContactUsViewModel.Email,
                            TelephoneNumber: vueObject.ContactUsViewModel.TelephoneNumber
                        });
                        axios.post('/Forms/ContactUs',
                            postData,
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }).then((response) => {
                                if (response.data.success) {
                                    this.$emit('contact-us', "Successfully sent your message/request");
                                    this.closeModal();
                                } else {
                                    this.addAlert(
                                        {
                                            "message": `Could not send message/request: ${response.data.data}`,
                                            "isSuccess": false
                                        });
                                }
                            }).catch(error => {
                                console.error(error);
                                this.addAlert(
                                    {
                                        "message": 'Could not send message/request due to a server error',
                                        "isSuccess": false
                                    });
                            });
                    } else {
                        this.addAlert({
                            "message": 'Some fields in the form are invalid',
                            "isSuccess": false
                        });
                    }
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.closeModal();
            },
            closeModal: function () {
                this.clearAlert();
                this.RequestNewLeaseModel = structuredClone(this.data);
                $('#contact-us-modal').modal('hide');
            }
        }
    });