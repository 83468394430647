import Vue from 'vue'
import VeeValidate from "vee-validate"
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import "./Details.less"
import "../Grid/Grid.less"

if (document.getElementById('portfolio-details')) {
    if (document.getElementById('portfolioandusers')) {
        require("../Alerts/Alerts.js");
        require('./AddUser.js');
        require('./Edit.js');
        require('./UserRights.js');
        
        Vue.use(GridInstaller);
        Vue.use(VeeValidate);

        new Vue({
            el: '#portfolioandusers',
            components: {},
            data: {
                alerts: [],
                EditModel: window.detailModel,
                AddUsersModel: window.detailModel.SelectPortfolioUsers,
                connectionType: window.detailModel.FormattedType,
                assetType: window.detailModel.FormattedAssetType,
                User: {},
                UserRights: {},
                dataSource: {
                    data: window.detailModel.PortfolioUsers,
                    pageSize: 20
                },
                pageable: {
                    buttonCount: 3,
                    info: true,
                    type: 'numeric',
                    pageSizes: [10, 20, 30, 50],
                    previousNext: true
                },
                skip: 0,
                take: 20,
                columns: [
                    { 'field': 'Name', 'title': 'User', 'filterable': false },
                    { 'title': ' ', 'filterable': false, 'template': '<a class="anchor-changeuserrights" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--user--role" title="Change User Rights"></span></a><a class="anchor-removeuser" href="\\#""><span class="icon-rowaction icon-rowaction--dark icon-rowaction--trash-can" title="Remove User"></span></a>', 'width': '90px' }
                ]
            },
            methods: {
                addAlert: function (event) {
                    this.alerts = [];
                    this.alerts.push(event);
                },
                clearAlert: function (index) {
                    this.alerts.splice(index, 1);
                },
                edit: function () {
                    $('#edit-portfolio--change-name').modal('show');
                },
                addUser: function () {
                    $('#edit-portfolio--add-user').modal('show');
                },
                gridOnDatabound: function (e) {
                    const grid = this.$refs.grid.kendoWidget();
                    const _this = this;
                    grid.element.on('click', '.anchor-changeuserrights',
                        function (e) {
                            e.preventDefault();
                            const user = grid.dataItem($(this).closest("tr[data-uid"));
                            _this.User = {
                                Id: user.Id,
                                Name: user.Name
                            };
                            _this.UserRights = user.Rights;
                            $('#edit-portfolio--user-rights').modal('show');
                        });
                    grid.element.on('click', '.anchor-removeuser',
                        function (e) {
                            e.preventDefault();
                            const user = grid.dataItem($(this).closest("tr[data-uid"));
                            $.ajax({
                                type: 'POST',
                                url: '/Portfolios/RemoveUser',
                                data: JSON.stringify({
                                    userId: user.Id,
                                    portfolioId: window.detailModel.PortfolioId
                                }),
                                success: function (data) {
                                    if (data.success) {
                                        let url = new URL(location.href);
                                        url.searchParams.delete('action');
                                        window.location.href = url.href;
                                    } else {
                                        _this.addAlert(
                                            {
                                                "message": `Could not remove user: ${data.data}`,
                                                "isSuccess": false
                                            });
                                    }
                                },
                                error: function (data) {
                                    _this.addAlert(
                                        {
                                            "message": 'Could not remove user due to a server error',
                                            "isSuccess": false
                                        });
                                },
                                contentType: "application/json",
                                dataType: 'json'
                            });
                        });
                }
            }
        });
    }
    if (document.getElementById('connections')) {
        require('./AddConnection.js');

        new Vue({
            el: '#connections',
            components: {},
            data: {
                alerts: [],
                AddConnectionsModel: window.detailModel.SelectConnections,
                dataSource: {
                    data: window.detailModel.Connections,
                    pageSize: 20
                },
                pageable: {
                    buttonCount: 3,
                    info: true,
                    type: 'numeric',
                    pageSizes: [10, 20, 30, 50],
                    previousNext: true
                },
                skip: 0,
                take: 20,
                columns: []
            },
            created: function () {
                this.loadColumns();
            },
            mounted: function () {
                if (getUrlParameter('action') === 'edit') {
                    const connectionsTab = document.getElementById('tab2');
                    connectionsTab.checked = true;
                    this.addConnection();
                }
            },
            methods: {
                addAlert: function (event) {
                    this.alerts = [];
                    this.alerts.push(event);
                },
                clearAlert: function (index) {
                    this.alerts.splice(index, 1);
                },
                addConnection: function () {
                    $('#edit-portfolio--add-connection').modal('show');
                },
                loadColumns: function (e) {
                    let rowactions = '';
                    this.columns.push({ 'field': 'Name', 'title': 'name', 'filterable': false });
                    if (window.detailModel.FormattedType == 'Contract') {
                        this.columns.push({ 'field': 'BackOfficeSource', 'title': 'Back office', 'filterable': false });
                        if (window.detailModel.ContractDetailsUrl !== null) {
                            rowactions += '<a class="anchor-viewcontract" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--view" title="View Contract"></span></a>';
                        }
                        else {
                            rowactions += '<a class="anchor-viewcontract" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--locked" title="No access to Contract"></span></a>';
                        }
                    }
                    rowactions += '<a class="anchor-removeconnection" href="\\#""><span class="icon-rowaction icon-rowaction--dark icon-rowaction--trash-can" title="Remove Connection"></span></a>';
                    this.columns.push({ 'title': ' ', 'filterable': false, 'template': rowactions, 'width': '90px' })
                },
                gridOnDatabound: function (e) {
                    const grid = this.$refs.grid.kendoWidget();
                    const _this = this;
                    grid.element.on('click', '.anchor-viewcontract',
                        function (e) {
                            e.preventDefault();
                            if (window.detailModel.ContractDetailsUrl !== null) {
                                const contract = grid.dataItem($(this).closest("tr[data-uid"));
                                window.location.href = window.detailModel.ContractDetailsUrl + "?id=" + contract.Id;
                            }
                        });
                    grid.element.on('click', '.anchor-removeconnection',
                        function (e) {
                            e.preventDefault();
                            _this.clearAlert();
                            const connection = grid.dataItem($(this).closest("tr[data-uid"));
                            const updateConnections = window.detailModel.Connections.map(x => x.Id);
                            updateConnections.splice(updateConnections.indexOf(connection.Id), 1);
                            $.ajax({
                                type: 'POST',
                                url: '/Portfolios/UpdateConnections',
                                data: JSON.stringify({
                                    portfolioId: window.detailModel.PortfolioId,
                                    selectedConnections: updateConnections,
                                    portfolioType: window.detailModel.PortfolioType,
                                    portfolioAssetType: window.detailModel.PortfolioAssetType
                                }),
                                success: function (data) {
                                    if (data.success) {
                                        let url = new URL(location.href);
                                        url.searchParams.delete('action');
                                        window.location.href = url.href;
                                    } else {
                                        _this.addAlert(
                                            {
                                                "message": `Could not remove connection: ${data.data}`,
                                                "isSuccess": false
                                            });
                                    }
                                },
                                error: function (data) {
                                    _this.addAlert(
                                        {
                                            "message": 'Could not remove connection due to a server error',
                                            "isSuccess": false
                                        });
                                },
                                contentType: "application/json",
                                dataType: 'json'
                            });
                        });
                }
            }
        });
    }
    function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
}