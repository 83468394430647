import Vue from 'vue'
import VeeValidate from "vee-validate"
import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import "./Details.less"
import "../Grid/Grid.less"
import { setColumnTemplate } from "../Shared/grid-utils"

if (document.getElementById('asset-type-details')) {
    const alerts = require("../Alerts/Alerts.js");
    require('./Edit.js');
    require('./AddOrEditField.js');
    require('./ToggleMeterReadingFields.js');
    Vue.use(GridInstaller);
    Vue.use(VeeValidate);

    new Vue({
        el: '#asset-type-details',
        components: {},
        data: {
            alerts: [],
            EditModel: window.detailModel,
            EditTypeField: null,
            EditTypeFieldContext: null,
            specificDataSource: {
                data: window.detailModel.AssetTypeFields,
                pageSize: 20
            },
            generalDataSource: {
                data: window.detailModel.GeneralAssetTypeFields,
                pageSize: 20
            },
            computedDataSource: {
                data: window.detailModel.ComputedFields,
                pageSize: 20
            },
            pageable: {
                buttonCount: 3,
                info: true,
                type: 'numeric',
                pageSizes: [10, 20, 30, 50],
                previousNext: true
            },
            skip: 0,
            take: 20,
            columns: {
                name: { 'field': 'Name', 'title': 'Name', 'filterable': false, 'width': '375px' },
                type: { 'field': 'Type', 'title': 'Type', 'filterable': false, 'width': '100px' },
                required: { 'field': 'Required', 'title': 'Required', 'filterable': false, 'width': '100px' },
                viewableBy: { 'field': "ViewableBy", 'title': 'Viewable by', 'filterable': false, 'width': '250px', 'template': "#: ViewableBy == null ? ' ' : ViewableBy.sort().join(' / ') #</span>" },
                editableBy: { 'field': "EditableBy", 'title': 'Editable by', 'filterable': false, 'width': '250px', 'template': "#: EditableBy == null ? ' ' : EditableBy.sort().join(' / ') #</span>" },
                isDMBI: { 'field': "IsDMBI", 'title': 'DMBI*', 'template': "#= IsDMBI ? 'yes' : 'no' #", 'filterable': false, 'width': '250px' },
                isDefault: { 'field': "IsDefault", 'title': 'Default setting', 'template': "#= IsDefault ? 'yes' : 'no' #", 'width': '150px' }
            }
        },
        computed: {
            specificColumns: function () {
                let specificColumns = [
                    this.columns.name,
                    this.columns.type,
                    this.columns.required,
                    this.columns.viewableBy,
                    this.columns.editableBy
                ];

                specificColumns.push(
                    setColumnTemplate(this.columns.isDefault, function (dataItem) {
                        if (dataItem.IsLegacy) {
                            return "-";
                        }
                        else {
                            return dataItem.IsDefault ? "yes" : "no";
                        }
                    })
                );

                if (this.EditModel.Editable) {
                    specificColumns.push({
                        'title': ' ',
                        'filterable': false,
                        'template': function (dataItem) {
                            if (dataItem.IsStatic) {
                                return '<span class="icon-rowaction icon-rowaction--dark icon-rowaction--info"></span><span class="icon-tooltip">Depending on the company settings, this field will be available for that company.</span>';
                            } else {
                                return '<a class="anchor-edit" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--edit" title="Edit"></span></a><a class="anchor-remove" href="\\#""><span class="icon-rowaction icon-rowaction--dark icon-rowaction--trash-can" title="Remove Asset Type"></span></a>';
                            }
                        },
                        'width': '90px'
                    });
                }

                return specificColumns;
            },
            generalColumns: function () {
                let generalColumns = [
                    this.columns.name,
                    this.columns.type,
                    this.columns.required,
                    this.columns.viewableBy,
                    this.columns.editableBy,
                    this.columns.isDMBI,
                    this.columns.isDefault
                ];

                if (this.EditModel.Editable) {
                    generalColumns.push({ 'title': ' ', 'filterable': false, 'template': '<a class="anchor-edit" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--edit" title="Edit"></span></a>', 'width': '90px' });
                }
                return generalColumns;
            },
            computedColumns: function () {
                let computedColumns = [
                    this.columns.name,
                    this.columns.type,
                    setColumnTemplate(this.columns.required, "-"),
                    this.columns.viewableBy,
                    setColumnTemplate(this.columns.editableBy, "-"),
                    setColumnTemplate(this.columns.isDefault, "-")
                ];

                computedColumns.push({
                    'title': ' ',
                    'filterable': false,
                    'template': function (dataItem) {
                        return '<span class="icon-rowaction icon-rowaction--dark icon-rowaction--info"></span><span class="icon-tooltip">' + dataItem.Information + '</span>';
                    },
                    'width': '90px'
                });
                return computedColumns;
            }
        },
        mounted: function () {
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            changeName: function () {
                $('#edit-assettype--change-name').modal('show');
            },
            addField: function () {
                $('#edit-assettype--add-field').modal('show');
            },
            toggleMeterReadingFields: function () {
                $('#assettype--toggle--meter-reading-fields').modal('show');
            },
            clearField: function () {
                this.EditTypeField = undefined;
            },
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                this.remove(grid);
                this.edit(grid);
            },
            // databound method for the second grid, so the two grids wont process the onbound for eachother twice.
            grid2OnDatabound: function (e) {
                const grid = this.$refs.grid2.kendoWidget();
                this.edit(grid);
            },
            edit: function (grid) {
                const _this = this
                grid.element.on('click', '.anchor-edit', function (e) {
                    e.preventDefault();
                    const fields = grid.element.attr('id') == "asset-type-fields-grid" ? window.detailModel.AssetTypeFields : window.detailModel.GeneralAssetTypeFields;
                    const assetTypeField = grid.dataItem($(this).closest("tr[data-uid"));
                    grid.element.attr('id') == "asset-type-fields-grid" ? _this.EditTypeFieldContext = 'specific' : _this.EditTypeFieldContext = 'general';
                    _this.EditTypeField = fields.find(x => x.Name == assetTypeField.Name);
                    _this.addField();
                });
            },
            remove: function (grid) {
                const _this = this
                grid.element.on('click', '.anchor-remove', function (e) {
                    e.preventDefault();
                    const assetTypeField = grid.dataItem($(this).closest("tr[data-uid"));
                    $.ajax({
                        type: 'POST',
                        url: '/AssetTypes/RemoveAssetTypeField',
                        data: JSON.stringify({
                            id: _this.EditModel.AssetTypeId,
                            name: assetTypeField.Name
                        }),
                        success: function (data) {
                            if (data.success) {
                                window.GeneralUtilities.loading(true);
                                location.reload();
                            } else {
                                _this.addAlert(
                                    {
                                        "message": `Could not remove asset type field: ${data.data}`,
                                        "isSuccess": false
                                    });
                            }
                        },
                        error: function (data) {
                            _this.addAlert(
                                {
                                    "message": 'Could not remove asset type field due to a server error',
                                    "isSuccess": false
                                });
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            }
        }
    });
}