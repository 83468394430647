import Vue from 'vue'

module.export = Vue.component('addconnectionmodal',
    {
        name: 'addconnection',
        props: {
            data: Array
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                AddConnectionsModel: Object.assign([], this.data), //create deep a copy of the object
                showBackoffice: window.detailModel.PortfolioType == 'ContractIdentifier' && window.detailModel.PortfolioAssetType == 'LeasedAssets',
                searchText: '',
                backOfficeFilter: "Select backoffice",
                backOffices: [],
                dataSource: {
                    data: window.detailModel.SelectConnections
                },
                columns: [
                    { 'field': 'Selected', 'title': ' ', 'filterable': false, 'template': '<div #= Selected ? \'class="state-selected"\' : "" #><a class="visual-checkbox" href="\\#"><input type="checkbox" #= Selected ? \'checked="checked"\' : "" # /></a></div>', 'width': '40px' },
                    { 'field': 'Name', 'title': 'name', 'filterable': false }
                ]
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            add: function (e) {
                e.preventDefault();
                const grid = this.$refs.grid.kendoWidget();
                const selectedConnections = grid.dataSource.data().filter(i => i.Selected).map(i => i.Id);
                const _this = this;
                _this.clearAlert();
                $("#addButton").prop("disabled", true);
                $.ajax({
                    type: 'POST',
                    url: '/Portfolios/UpdateConnections',
                    data: JSON.stringify({
                        portfolioId: window.detailModel.PortfolioId,
                        selectedConnections: selectedConnections,
                        portfolioType: window.detailModel.PortfolioType,
                        portfolioAssetType: window.detailModel.PortfolioAssetType
                    }),
                    success: function (data) {
                        if (data.success) {
                            let url = new URL(location.href);
                            url.searchParams.delete('action');
                            window.location.href = url.href;
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not update connections: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#addButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": "Could not update connections due to a server error",
                                "isSuccess": false
                            });
                        $("#addButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.AddUserModel = Object.assign({}, this.data);
                this.clearAlert();
                $('#edit-portfolio--add-connection').modal('hide');

                // Enable save button again.
                $("#addButton").removeAttr("disabled");
            },
            search: function () {
                const filter = [];
                if (this.backOfficeFilter != "Select backoffice") {
                    filter.push({ field: 'BackOfficeSource', operator: 'contains', value: this.backOfficeFilter });
                }
                if (this.searchText) {
                    filter.push({ field: 'Id', operator: 'contains', value: this.searchText });
                }
                this.$refs.grid.kendoWidget().dataSource.filter(filter);
            },
            setBackoffices: function () {
                const allBackoffices = window.detailModel.SelectConnections.flatMap((element) => !element.BackOfficeSource ? [] : element.BackOfficeSource);
                this.backOffices = [...new Set(allBackoffices)];
            },
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                this.setBackoffices();
                grid.element.off('click', '.visual-checkbox');
                grid.element.on('click', '.visual-checkbox',
                    function (e) {
                        e.preventDefault();
                        const cb = this.querySelector("input[type='checkbox']");
                        cb.checked = !cb.checked;
                        const user = grid.dataItem($(this).closest("tr[data-uid"));
                        user.Selected = cb.checked;
                        if (cb.checked) {
                            $(this.parentElement).addClass("state-selected");
                        } else {
                            $(this.parentElement).removeClass("state-selected");
                        }
                    });
            }
        }
    });