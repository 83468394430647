import Vue from 'vue'

module.export = Vue.component('gridconfigurationmodal',
    {
        name: "gridconfigurationmodal",
        props: {
            data: Object
        },
        data: function () {
            return {
                alerts:[],
                SelectedColumns: this.data.SelectedColumns.slice(0),
                AvailableColumns: this.data.AvailableColumns.slice(0),
                GridName: this.data.GridName,
                ShowSelectAtLeastOne: false,
                dataSource: this.data.ColumnsConfiguration,
                originalDataSource: structuredClone(this.data.ColumnsConfiguration),
                checked: true,
                isSearching: false,
                dragging: false,
                draggedItem: null,
                displayedWarningDuringSearch: false,
                checkedNames: [],
                columns: [
                    { 
                        'field': 'Draggable', 
                        'title': ' ', 
                        'filterable': false, 
                        'template': function(dataItem){                            
                            if (dataItem.Selected){
                                return '<div><a class="visual-draggable" href="\\#"><span class="k-icon k-i-handle-drag"></span></a></div>';
                            }
                            else {
                                return "";
                            }
                        }, 
                        'width': '40px' 
                    },
                    { 'field': 'Selected', 'title': ' ', 'filterable': false, 'template': '<div #= Selected ? \'class="state-selected"\' : "" #><a class="visual-checkbox" href="\\#"><input type="checkbox" #= Selected ? \'checked="checked"\' : "" # /></a></div>', 'width': '40px' },
                    { 'field': 'DisplayName', 'title': 'Fields', 'filterable': false }
                ],
                searchText: ''
            };
        },
        watch: {
            data: function (newData) {
                this.SelectedColumns = newData.SelectedColumns.slice(0);

                this.dataSource = newData.ColumnsConfiguration;
                this.originalDataSource = structuredClone(newData.ColumnsConfiguration);                
            }
        },        
        computed: {
            isDragging() {
                return (this.dragging) ? this.draggedItem : false;
            }
        },
        mounted: function () {
            const grid = this.$refs.grid.kendoWidget();
            const _this = this;

            grid.element.on('click', '.visual-checkbox',
                function (e) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    const cb = this.querySelector("input[type='checkbox']");
                    cb.checked = !cb.checked;
                    const dataItem = grid.dataItem($(this).closest("tr[data-uid"));
                    const selectedItems = _this.dataSource.filter(item => item.Selected).map(item => item.Index);                    

                    if (cb.checked) {
                        dataItem.Selected = true;
                        dataItem.Index = Math.max(...selectedItems) + 1;
                        dataItem.SortOrder = `0_${dataItem.Index}`;
                        _this.dataSource.find(x => x.Id == dataItem.Id).SortOrder =  dataItem.SortOrder;                        
                        $(this.parentElement).addClass("state-selected");
                    } else 
                    {
                        dataItem.Selected = false;
                        dataItem.SortOrder = `1_${dataItem.DisplayName}`;
                        _this.dataSource.find(x => x.Id == dataItem.Id).SortOrder =  dataItem.SortOrder;                        
                        $(this.parentElement).removeClass("state-selected");
                    }

                    _this.dataSource.find(x => x.Id == dataItem.Id).Selected = cb.checked;
                    if (!_this.isSearching) {
                        _this.dataSource.sort((a, b) => a.SortOrder.localeCompare(b.SortOrder, undefined, { numeric: true }));
                        grid.dataSource.read();
                    } else {
                        if (!_this.displayedWarningDuringSearch) {
                            _this.displayedWarningDuringSearch = true;
                            _this.addAlert(
                                {
                                    "message": 'Please click on X next to searchbox to apply changes and go back to full overview',
                                    "isSuccess": true,
                                    "showPopup": true
                                });
                        }
                    }
                });

            grid.element.on('mousedown', '.visual-draggable', this.dragHandler);
        },
        created: function () {
            this.data.refreshColumns = this.refreshColumns;
        },
        methods: {
            mouseup: function (e) {
                if (this.dragging) {
                    this.dropHandler(e);
                }

                this.dragging = false;
                $('html,body').css('cursor', 'auto');
            },

            dragHandler: function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();

                const selectedItem = $(e.target).closest("tr[data-uid]");
                if (selectedItem.length >= 1) {
                    this.dragging = true;
                    $('html,body').css('cursor', 'move');

                    this.draggedItem = selectedItem;
                }
            },

            dropHandler: function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                const grid = this.$refs.grid.kendoWidget();

                const selectedItem = $(e.target).closest("tr[data-uid]");
                if (selectedItem.length >= 1 && !selectedItem.is(this.draggedItem)) {
                    const droppedItem = grid.dataItem(this.draggedItem);
                    const oldIndex = grid.dataSource.indexOf(droppedItem);

                    let newIndex = grid.dataSource.indexOf(grid.dataItem(selectedItem));
                    if (newIndex > oldIndex) {
                        newIndex -= 1;
                    }

                    grid.dataSource.remove(droppedItem);
                    grid.dataSource.insert(newIndex, droppedItem);

                    let selectedCounter = 0;
                    const selectedItemIds = grid.dataSource.data().filter(i => i.Selected).map(i => i.Id);
                    selectedItemIds.forEach(itemId => {
                        let item = this.dataSource.find(x => x.Id == itemId);
                        item.Index = selectedCounter;
                        item.SortOrder = "0_" + selectedCounter;
                        selectedCounter++;
                    });
                }
            },

            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            save: function () {
                this.clearAlert();
                this.ShowSelectAtLeastOne = false;
                $("#saveButton").prop("disabled", true);
                //Save the currently selected columns in user preferences through AjaxelectedColumns.length  call
                if (this.dataSource.filter(item => item.Selected).length < 1) {
                    this.ShowSelectAtLeastOne = true;
                    return;
                }

                const _this = this;
                const grid = this.$refs.grid.kendoWidget();
                const selectedIds = grid.dataSource.data().filter(item => item.Selected).map(item => item.Id);
                $.ajax({
                    type: 'POST',
                    url: '/GridConfiguration/Save',
                    data: JSON.stringify({
                        gridName: _this.GridName,
                        selectedColumnIds: selectedIds
                    }),
                    success: function (data) {
                        if (data.success) {
                            _this.data.SelectedColumns = grid.dataSource.data().filter(item => item.Selected)
                                .map(x => ({ Id: x.Id, DisplayName: x.DisplayName, PropertyName: x.PropertyName }));
                            location.reload();
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not save asset field selection: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not save asset field selection due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            close: function () {
                this.clearAlert();
                this.ShowSelectAtLeastOne = false;
                this.displayedWarningDuringSearch = false;
                this.searchText = "";
                this.isSearching = false;

                $('#grid-configuration-modal').modal('hide');
                this.dataSource = structuredClone(this.originalDataSource);
            },            
            searchColumnList: function () {
                let filter = [];
                const grid = this.$refs.grid.kendoWidget();
                if (this.searchText && this.searchText.length >= 2) {
                    this.isSearching = true;
                    grid.hideColumn(0);
                    filter.push({ field: 'DisplayName', operator: 'contains', value: this.searchText });
                } else {
                    this.isSearching = false;
                    grid.showColumn(0);
                    this.dataSource.sort((a, b) => a.SortOrder.localeCompare(b.SortOrder, undefined, { numeric: true }));
                    grid.dataSource.read();                    
                }
               grid.dataSource.filter(filter);
            },
            clearSearch: function() {
                this.searchText = '';
                this.searchColumnList();
                this.displayedWarningDuringSearch = false;
            },

            refreshColumns: function (columns) {
                let index = 0;

                const newColumnsConfiguration = this.data.ColumnsConfiguration.map((field) => {
                    const columnIndex = columns.findIndex(x => x.propertyName === field.PropertyName);
                    field.Selected = columnIndex >= 0;

                    if (field.Selected){
                        field.Index = columnIndex;                        
                        field.SortOrder = "0_" + columnIndex;
                    }
                    else {
                        field.Index = columns.length + index;
                        field.SortOrder = "1_" + field.DisplayName;
                    }
                    index++;

                    return field;
                });        

                this.SelectedColumns = columns.map(x => x.id);                    
                this.dataSource = newColumnsConfiguration.sort((x, y) => x.Index - y.Index);
            }
        }
    });