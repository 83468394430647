import Vue from 'vue'
import VeeValidate from "vee-validate"

if (document.getElementById('navbar-menu')) {
    require('../RedirectModal/SelectCountry.js');

    Vue.use(VeeValidate);

    new Vue({
        el: '#navbar-menu',
        components: {},
        data: {
            SelectCountryModel: {
                title: 'Contact us',
                elementId: 'navbar-menu--select-country',
                hrefsProp: 'contactUs',
                // Note: id prop equals index in allCountries array
                allCountries: [
                    {
                        id: 0,
                        name: 'United States',
                        hrefs: {
                            contactUs: 'https://www.lesseedirect.com/contact-us'
                        }
                    },
                    {
                        id: 1,
                        name: 'Other Countries',
                        hrefs: {
                            contactUs: 'https://www.dllgroup.com/en?uc=1'
                        }
                    }
                ],
                countryId: -1
            }
        },
        methods: {
            showSelectCountry: function () {
                $("#navbar-menu--select-country").modal('show');
            }
        }
    });    
}
