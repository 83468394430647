import Vue from 'vue'

module.export = Vue.component('addusermodal',
    {
        name: 'adduser',
        props: {
            data: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                AddModel: Object.assign({}, this.data),
                isSaving: false
            };
        },
        mounted: function () {
            const _this = this;
            const portfolios = _this.AddModel.allPortfolios;
            kendo.jQuery("#portfolioSelectorAddUser").kendoMultiSelect({
                dataSource: portfolios,
                filter: "contains",
                tagTemplate: kendo.template($("#tagTemplateAddUser").html()),
                placeholder: "Select portfolio",
                autoClose: false,
                tagMode: "single",
                noDataTemplate: "No portfolios available",
                dataTextField: "Name",
                dataValueField: "Id",
                itemTemplate: `<div class="checkbox">
                        #= Name #
                        <input type="checkbox" name="checkbox" value="#= Id #"/>
                        <span class="checkmark"></span>
                    </div>`,
                change: function () {
                    const selected = this.value();
                    const items = this.listView.content.find("li");
                    items.each(function () {
                        const checkbox = $(this).find("input[type='checkbox']");
                        const value = checkbox.val();
                        checkbox.prop("checked", selected.indexOf(value) > -1);
                    });

                    _this.AddModel.portfolios = selected;
                    if (selected.length == 0) {
                        this.tagList.children().each(function () {
                            this.remove();
                        });
                    }
                },
                open: function () {
                    const selected = this.value();
                    const items = this.listView.content.find("li");
                    items.each(function () {
                        const checkbox = $(this).find("input[type='checkbox']");
                        const value = checkbox.val();
                        checkbox.prop("checked", selected.indexOf(value) > -1);
                    });
                }
            });
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();

                _this.isSaving = true;

                this.$validator.validateAll().then((isValid) => {
                    if (isValid) {
                        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        const isValidMail = re.test(String(_this.AddModel.mail).toLowerCase());

                        if (!isValidMail) {
                            _this.addAlert(
                                {
                                    "message": 'Could not add user due to invalid email address',
                                    "isSuccess": false
                                });
                            _this.isSaving = false;
                        } else {
                            $.ajax({
                                type: 'POST',
                                url: '/Settings/ManageUsers/Add',
                                data: JSON.stringify({
                                    firstName: _this.AddModel.firstName,
                                    lastName: _this.AddModel.lastName,
                                    mail: _this.AddModel.mail,
                                    roleId: _this.AddModel.role,
                                    portfolios: _this.AddModel.portfolios
                                }),
                                success: function (data) {
                                    if (data.success) {
                                        _this.close();
                                        _this.$emit('user-added');
                                    } else {
                                        _this.addAlert(
                                            {
                                                "message": `Could not add user: ${data.data}`,
                                                "isSuccess": false
                                            });
                                    }
                                    _this.isSaving = false;
                                },
                                error: function (data) {
                                    _this.addAlert(
                                        {
                                            "message": 'Could not add user due to a server error',
                                            "isSuccess": false
                                        });
                                    _this.isSaving = false;
                                },
                                contentType: "application/json",
                                dataType: 'json'
                            });
                        }
                    }
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                this.AddModel = Object.assign({}, this.data);
                $('#useroverview-add-user').modal('hide');

                // Enable save button again.
                this.isSaving = false;
            }
        }
    }
);
