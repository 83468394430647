import Vue from 'vue'

module.export = Vue.component('editrolemodal',
    {
        name: 'editrole',
        props: {
            data: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                EditModel: Object.assign({}, this.data), //create deep a copy of the object
                columns: [
                    { 'field': 'Selected', 'title': ' ', 'filterable': false, 'template': '<div #= Selected ? \'class="state-selected"\' : "" #><a class="visual-checkbox" href="\\#"><input type="checkbox" #= Selected ? \'checked="checked"\' : "" # /></a></div>', 'width': '40px' },
                    { 'field': 'Name', 'title': 'Rights', 'filterable': false },
                ]
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();
                $("#saveButton").prop("disabled", true);
                $.ajax({
                    type: 'POST',
                    url: '/Settings/ManageRoles/Edit',
                    data: JSON.stringify({
                        roleId: window.Role.Id,
                        name: _this.EditModel.name,
                        rightIds: _this.EditModel.rights.filter(x => x.Selected).map(x => x.Id)
                    }),
                    success: function (data) {
                        if (data.success) {
                            _this.close();
                            _this.$emit('role-changed');
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not change role: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not change role due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                for (let i = 0; i < this.data.rights.length; i++) {
                    this.data.rights[i].Selected = false;
                }
                this.EditModel = Object.assign({}, this.data);
                $('#roleoverview-edit-role').modal('hide');

                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            },
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                const _this = this;
                grid.element.on('click', '.visual-checkbox',
                    function (e) {
                        e.preventDefault();
                        const cb = this.querySelector("input[type='checkbox']");
                        cb.checked = !cb.checked;
                        const right = grid.dataItem($(this).closest("tr[data-uid"));
                        right.Selected = cb.checked;
                        const rightIndex = _this.EditModel.rights.findIndex(x => x.Id == right.Id);
                        if (rightIndex != -1) {
                            _this.EditModel.rights[rightIndex].Selected = cb.checked;
                        }
                        if (cb.checked) {
                            $(this.parentElement).addClass("state-selected");
                        } else {
                            $(this.parentElement).removeClass("state-selected");
                        }
                    });
            }
        }
    });