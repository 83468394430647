import Vue from 'vue'

module.export = Vue.component('editassettypemodal',
    {
        name: 'editassettype',
        props: {
            data: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                EditModel: Object.assign({}, this.data), //create deep a copy of the object
                ShowError: false,
                showWarning: true
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();
                
                $("#saveButton").prop("disabled", true);
                $.ajax({
                    type: 'POST',
                    url: '/AssetTypes/Edit',
                    data: JSON.stringify({
                        assetTypeId: _this.EditModel.AssetTypeId,
                        assetTypeName: _this.EditModel.AssetTypeName,
                        masterAssetTypeName: _this.EditModel.MasterAssetTypeName
                    }),
                    success: function (data) {
                        if (data.success) {
                            _this.close();
                            let url = new URL(location.href);
                            url.searchParams.delete('action');
                            window.GeneralUtilities.loading(true);
                            location.reload();
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not change name: {data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not change name due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.ShowError = false;
                this.EditModel = Object.assign({}, this.data);
                $('#edit-assettype--change-name').modal('hide');

                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            }
        }
    });