import Vue from 'vue'
import VeeValidate from "vee-validate"
import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import "./Index.less"
import "../Styles/app.less"

if (document.getElementById('vue-asset-dashboard-overview')) {
    require("./RequestNewLease.js");
    require("../Alerts/Alerts.js");

    Vue.use(GridInstaller);
    Vue.use(VeeValidate);

    new Vue({
        el: '#vue-asset-dashboard-overview',
        components: {
            Grid
        },
        data: function () {
            return {
                alerts: [],
                provider: {
                    context: null
                },
                RequestNewLeaseModel: window.preLoadedData.RequestNewLeaseModel,
                ChartConfiguration: {
                    type: 'line',
                    data: {
                        labels: window.preLoadedData.AvailableMonths,
                        datasets: [{
                            label: window.hasBranding ? 'DLL Assets' : 'Leased Assets',
                            backgroundColor: window.chartColors.brandedBlue,
                            borderColor: window.chartColors.brandedBlue,
                            data: window.preLoadedData.DllAssets,
                            fill: false,
                        }, {
                            label: window.hasBranding ? 'Non DLL Assets' : 'Non Leased Assets',
                            fill: false,
                            backgroundColor: window.chartColors.brandedPurple,
                            borderColor: window.chartColors.brandedPurple,
                            data: window.preLoadedData.LesseeAssets,
                        }]
                    },
                    options: {
                        responsive: true,
                        backgroundColor: 'rgba(255,255,255)',
                        title: {
                            display: false,
                            text: 'Overview of assets and experiation date'
                        },
                        tooltips: {
                            mode: 'index',
                            intersect: false,
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: true
                        },
                        legend: {
                            labels: {
                                boxWidth: 15
                            }
                        },
                        scales: {
                            xAxes: [{
                                display: true,
                                scaleLabel: {
                                    display: true
                                }
                            }],
                            yAxes: [{
                                display: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Assets'
                                }
                            }]
                        },
                        onResize: function () {
                            $('#asset-type-status-overview').height(arguments[1].height);
                        }
                    }
                },
                dataSource: {
                    data: window.preLoadedData.AssetTypeStatusItems,
                    pageSize: 5
                },
                pageable: {
                    messages: {
                        display: "{1}/{2}"
                    }
                },
                skip: 0,
                take: 5,
                columns: [{
                    field: "AssetType",
                    title: "Asset type"
                }, {
                    field: "Brand",
                    title: "Brand"
                }, {
                    field: "StatusCounts",
                    title: "Status",
                    template: function () {
                        const dataItem = arguments[0];
                        let html = "";
                        if (dataItem.StatusCounts.Active > 0) {
                            html += "<span class='icon-assets icon-assets--blue icon-assets--active' title='Active assets: " + dataItem.StatusCounts.Active + "'></span>";
                        }
                        else {
                            html += "<span class='icon-assets icon-assets--gray icon-assets--active'></span>"
                        }
                        if (dataItem.StatusCounts.Blank > 0) {
                            html += "<span class='icon-assets icon-assets--blue icon-assets--blank' title='Blank assets:" + dataItem.StatusCounts.Blank + "'></span>";
                        }
                        else {
                            html += "<span class='icon-assets icon-assets--gray icon-assets--blank'></span>"
                        }
                        if (dataItem.StatusCounts.Terminated > 0) {
                            html += "<span class='icon-assets icon-assets--blue icon-assets--terminated' title='Terminated assets:" + dataItem.StatusCounts.Terminated + "'></span>";
                        }
                        else {
                            html += "<span class='icon-assets icon-assets--gray icon-assets--terminated'></span>"
                        }
                        return html;
                    }
                }, {
                    field: "TotalAssets",
                    title: "Total assets"
                }],
            };
        },
        provide() {
            return {
                provider: this.provider
            }
        },
        mounted: function () {
            if (!document.getElementById('maincontent').classList.contains('mobile-view')) {
                const canvas = document.getElementById('canvas');
                this.provider.context = canvas.getContext('2d')
                window.myLine = new Chart(this.provider.context, this.ChartConfiguration);
                $('#asset-type-status-overview').height(canvas.height);
            }
            const faqHeight = $('#frequently-asked-questions').parents('.dashboard-box').height();
            $('#request-new-lease').parents('.dashboard-box').attr('style', 'min-height: ' + (faqHeight + 40 ) + 'px'); // set min height in case of longer paragraph and add the padding of 40 px
            const pager = $(".k-grid-pager");
            pager.find("button.k-pager-first, button.k-pager-last, select.k-dropdown, div.k-pager-numbers").each(function (i) {
                $(this).remove();
            });
            pager.find("button.k-pager-nav").each(function (i) {
                $(this).removeClass("k-button-flat");
                $(this).removeClass("k-button-flat-base");
            });
            const nextButton = pager.find("button.k-pager-nav").last();
            nextButton.detach();
            pager.append("<div class=\"k-pager-numbers-wrap order-10\"></div>");
            $(".k-pager-numbers-wrap.order-10").append(nextButton);
            pager.detach();
            const legenda = $("#asset-type-status-bar");
            legenda.append(pager);
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            requestedNewLease: function (message) {
                this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });
            },
            requestNewLeaseForm: function () {
                $('#request-new-lease-modal').modal('show');
            }
        }
    });
}