import Vue from 'vue'
import VeeValidate from "vee-validate"
import { formatDateTime, formatDateTimeToDate } from '../Shared/date-utils'
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import "../Shared/Assets/Details.less"
import axios from 'axios';
import { ReadingTypes } from '../MeterReadings/ReadingTypes.js'

if (document.getElementById('vue-nonleased-asset-details')) {
    Vue.use(GridInstaller);
    Vue.use(VeeValidate);
    Vue.filter('formatDateTime', formatDateTime);

    require("../Alerts/Alerts.js");
    require("../MeterReadings/Add.js");
    require("../MeterReadings/Edit.js");
    require("../MeterReadings/View.js");
    require("../MeterReadings/Delete.js");
    require("../Documents/Assets/Add.js");
    require("../Documents/Assets/View.js");
    require("../Documents/Assets/Delete.js");
    require("../PreviewPhoto/PhotoSelector.js");
    require("./Edit.js");

    ReadingTypes.load(window.detailModel.MeterReadings.Attributes);
    new Vue({
        el: '#vue-nonleased-asset-details',
        components: {},
        data: function () {
            return {
                alerts: [],
                assetHistoryFilters: null,
                assetHistory: null,
                historyField: "All fields",
                detailsViewModel: window.detailModel,
                meterReadingsModalActive: false,
                EditModel: {
                    Model: window.detailModel,
                    ShowError: false
                },
                MeterReadings: {
                    selectedItem: null,
                    dataSource: {
                        data: window.detailModel.MeterReadings.Values,
                        pageSize: 10
                    },
                    pageable: {
                        buttonCount: 3,
                        info: true,
                        type: 'numeric',
                        pageSizes: [10, 20, 30, 50],
                        previousNext: true
                    },
                    skip: 0,
                    take: 20,
                    columns: [
                        { 'field': 'Date', 'type': 'date', 'title': 'Meter reading date', 'filterable': false, 'format': '{0:yyyy/MM/dd}' },
                        { 'field': 'Value', 'type': 'numeric', 'title': 'Meter reading', 'filterable': false },
                        {
                            'field': 'Type',
                            'type': 'text',
                            'title': 'Meter reading type',
                            'filterable': false,
                            template: function () {
                                const dataItem = arguments[0];

                                return ReadingTypes.get(dataItem.Type).displayName;
                            }
                        },
                        {
                            'field': 'Image',
                            'title': 'Image',
                            'type': 'text',
                            'filterable': false,
                            template: function () {
                                const dataItem = arguments[0];
                                if (dataItem && dataItem.Attachment) {
                                    return "Yes";
                                }

                                return 'No image';
                            }
                        },
                        {
                            'title': ' ',
                            'filterable': false,
                            'template': [
                                '<a class="anchor-view" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--view" title="View"></span></a>',
                                '<a class="anchor-edit" href="\\#"><a class="anchor-edit" href="\\#""><span class="icon-rowaction icon-rowaction--dark icon-rowaction--edit" title="Edit"></span></a>',
                                '<a class="anchor-remove" href="\\#""><span class="icon-rowaction icon-rowaction--dark icon-rowaction--trash-can" title="Remove Asset Type"></span></a>'
                            ].join(''),
                            'width': '120px'
                        }
                    ],
                    readingTypes: ReadingTypes.getAll(),
                    CurrentSourceSystem: window.detailModel.MeterReadings.CurrentSourceSystem
                },
                Documents: {
                    selectedItem: null,
                    dataSource: {
                        data: window.detailModel.Documents.Values,
                        pageSize: 10
                    },
                    pageable: {
                        buttonCount: 3,
                        info: true,
                        type: 'numeric',
                        pageSizes: [10, 20, 30, 50],
                        previousNext: true
                    },
                    skip: 0,
                    take: 20,
                    columns: [
                        { 'field': 'Name', 'type': 'text', 'title': 'Name', 'filterable': false },
                        { 'field': 'Assignment', 'type': 'text', 'title': 'Assignment', 'filterable': false },
                        { 'field': 'CreationDate', 'type': 'date', 'title': 'Upload date', 'filterable': false, 'format': '{0:yyyy/MM/dd}' },
                        { 'field': 'Author', 'type': 'text', 'title': 'Uploaded by', 'filterable': false },
                        { 'field': 'FileType', 'type': 'text', 'title': 'Type', 'filterable': false },
                        {
                            'title': ' ',
                            'filterable': false,
                            'template': [
                                '<a class="anchor-view" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--view" title="View"></span></a>',
                                '<a class="anchor-download" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--download" title="Download"></span></a>',
                                '<a class="anchor-remove" href="\\#""><span class="icon-rowaction icon-rowaction--dark icon-rowaction--trash-can" title="Remove Asset Type"></span></a>'
                            ].join(''),
                            'width': '120px'
                        }
                    ],
                    CurrentSourceSystem: window.detailModel.Documents.CurrentSourceSystem
                },
                SelectPreviewPhotoModel: {
                    selectedPhoto: null,
                    availablePhotos: []
                },
                ModalActive: false
            };
        },
        computed: {
            filteredAssetHistoryList: function () {
                const filterby = this.historyField;
                if (filterby == "All fields") {
                    return this.assetHistory;
                } else {
                    const filteredHistory = JSON.parse(JSON.stringify(this.assetHistory));
                    return filteredHistory.filter(function (historyItem) {
                        historyItem.changedFields = historyItem.changedFields.filter(function (changedField) {
                            return changedField.name == filterby;
                        })
                        return historyItem.changedFields.length > 0;
                    });
                }
            },
            assetLocation: function () {
                const street = this.detailsViewModel.AssetLocationStreet.Viewable ? this.detailsViewModel.AssetLocationStreet.Value : '';
                const city = this.detailsViewModel.AssetLocationCity.Viewable ? this.detailsViewModel.AssetLocationCity.Value : '';
                const country = this.detailsViewModel.AssetLocationCountry.Viewable ? this.detailsViewModel.AssetLocationCountry.Value : '';
                const text = [street, city, country].filter(Boolean).join(", ");
                return text;
            },
            invoiceLocation: function () {
                const street = this.detailsViewModel.InvoiceLocationStreet.Viewable ? this.detailsViewModel.InvoiceLocationStreet.Value : '';
                const city = this.detailsViewModel.InvoiceLocationCity.Viewable ? this.detailsViewModel.InvoiceLocationCity.Value : '';
                const country = this.detailsViewModel.InvoiceLocationCountry.Viewable ? this.detailsViewModel.InvoiceLocationCountry.Value : '';
                const text = [street, city, country].filter(Boolean).join(", ");
                return text;
            },
            viewableAssetTypeFieldList() {
                const list = this.detailsViewModel.AssetTypeFields.Value.filter(function (e) { return e.Viewable });
                return list;
            },
            viewableComputedFieldList() {
                const list = this.detailsViewModel.ComputedFields.Value.filter(function (e) { return e.Viewable });
                return list;
            }
        },
        mounted: function () {
            const _this = this;
            this.getAssetHistory();
            if (getUrlParameter('action') === 'edit') {
                this.edit();
            }

            const updatedModel = Object.assign({}, this.SelectPreviewPhotoModel,
                {
                    availablePhotos: this.detailsViewModel.Documents.Values.filter(function (e) {
                        return e.FileType.includes("Image") && e.Assignment.includes("Asset");
                    }),
                    selectedPhoto: null
                });

                const selectedPhotoIndex = updatedModel.availablePhotos.findIndex(v => v.Tags.includes("Preview"));

            if (selectedPhotoIndex >= 0) {
                updatedModel.selectedPhoto = updatedModel.availablePhotos[selectedPhotoIndex];
            }

            this.SelectPreviewPhotoModel = updatedModel;

            const connection = window.assetNotificationsHub;
            connection.on("NotifyEdited", function (assetId) {
                if (window.detailModel.Id === assetId) {
                    _this.addAlert({ "message": 'Asset successfuly edited. Page will be refreshed.', "isSuccess": true, "showPopup": true });
                    setTimeout(() => window.location.href = window.location.href.replace("&action=edit", ""), 2000);
                }
            });         
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            edit: function () {
                if (!window.detailModel.CanBeEdited || !window.detailModel.AssetTypeExists)
                {
                    return;
                }
                $('#nonleased-asset-edit-modal').modal('show');
            },
            addMeterReading: function () {
                this.meterReadingsModalActive = true;
                $('#asset-details--add-meter-reading').modal('show');
            },
            meterReadingModalClosedEvent: function () {
                this.meterReadingsModalActive = false;
            },

            addDocument: function () {
                $('#asset-details--add-document').modal('show');
            },
            meterReadingChangedEvent: function (message, eventType, item) {
                const _this = this;

                this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });

                const grid = this.$refs.metergrid.kendoWidget();
                const currentDatasource = grid.dataSource;

                axios.get("/MeterReadings/GetForNonLeasedAsset?assetId=" + window.detailModel.Id).then(((result) => {
                    result.data.MeterReadings.Values = result.data.MeterReadings.Values.map((entry) => {
                        const date = new Date(entry.Date);
                        return Object.assign({}, entry, { Date: date });
                    });

                    _this.MeterReadings.dataSource.data = result.data.MeterReadings.Values;
                    _this.detailsViewModel.MeterReadings.Values = result.data.MeterReadings.Values;

                    currentDatasource.data(result.data.MeterReadings.Values);

                    // Re-sort using the intial storting
                    currentDatasource.sort(currentDatasource.sort());
                    grid.setDataSource(currentDatasource);
                }));
            },
            documentChangedEvent: function (message, eventType, item) {
                const _this = this;

                this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });

                const grid = this.$refs.documentgrid.kendoWidget();
                const currentDatasource = grid.dataSource;

                axios.get("/Documents/GetForNonLeasedAsset?assetId=" + window.detailModel.Id).then(((result) => {
                    result.data.Documents.Values = result.data.Documents.Values.map((entry) => {
                        const date = new Date(entry.CreationDate);
                        return Object.assign({}, entry, { CreationDate: date });
                    });

                    _this.Documents.dataSource.data = result.data.Documents.Values;
                    _this.detailsViewModel.Documents.Values = result.data.Documents.Values;


                    const updatedModel = Object.assign({}, _this.SelectPreviewPhotoModel,
                        {
                            availablePhotos: result.data.Documents.Values.filter(function (e) {
                                return e.FileType.includes("Image") && e.Assignment.includes("Asset");
                            }),
                            selectedPhoto: null
                        });

                    const selectedPhotoIndex = updatedModel.availablePhotos.findIndex(v => v.Tags.includes("Preview"));

                    if (selectedPhotoIndex >= 0) {
                        updatedModel.selectedPhoto = updatedModel.availablePhotos[selectedPhotoIndex];
                    }

                    _this.SelectPreviewPhotoModel = updatedModel;

                    currentDatasource.data(result.data.Documents.Values);

                    // Re-sort using the intial storting
                    currentDatasource.sort(currentDatasource.sort());
                    grid.setDataSource(currentDatasource);
                }));
            },
            previewChangedEvent: function (message, newPreviewIndex) {
                this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });
                this.SelectPreviewPhotoModel.selectedPhoto = this.SelectPreviewPhotoModel.availablePhotos[newPreviewIndex];
            },
            getAssetHistory() {
                const _this = this;
                const id = this.detailsViewModel.Id;
                const assetTypeId = this.detailsViewModel.AssetTypeId;
                axios.get("/NonLeasedAssets/History?id=" + id + "&assetTypeId=" + assetTypeId)
                    .then(response => {
                        _this.assetHistory = response.data.changeSets;
                        _this.assetHistoryFilters = response.data.uniqueFilters;
                    }).catch(error => {
                        _this.ShowError = true;
                    });
            },
            showPhotoSelector: function () {
                if (!window.detailModel.CanBeEdited || !window.detailModel.AssetTypeExists)
                {
                    return;
                }
                this.ModalActive = true;
                $("#select-preview-photo").modal('show');
            },
            exportHistory: function () {
                const id = this.detailsViewModel.Id;
                const assetTypeId = this.detailsViewModel.AssetTypeId;
                axios.get("/NonLeasedAssets/ExportHistory?id=" + id + "&assetTypeId=" + assetTypeId, { responseType: 'blob' })
                    .then(response => {
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(response.data);
                        link.download = 'History_NonLeasedAsset_' + id + '_' + new Date().toISOString() + '.csv';
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }).catch(console.error);
            },
            formattedDate: function (date) {
                return formatDateTimeToDate(date);
            },
            meterReadingsGridOnDatabound: function (e) {
                const grid = this.$refs.metergrid.kendoWidget();
                const _this = this;
                grid.element.on('click', '.anchor-view',
                    function (e) {
                        e.preventDefault();
                        const reading = grid.dataItem($(this).closest("tr[data-uid"));
                        _this.MeterReadings.selectedItem = Object.assign(
                            {},
                            reading,
                            { Type: ReadingTypes.get(reading.Type).displayName });

                        $('#asset-details--view-meter-reading').modal('show');
                    });

                grid.element.on('click', '.anchor-edit',
                    function (e) {
                        e.preventDefault();
                        const reading = grid.dataItem($(this).closest("tr[data-uid"));
                        const parsedDate = new Date(reading.Date);

                        _this.MeterReadings.selectedItem = Object.assign(
                            {},
                            reading,
                            { Date: formatDate(parsedDate) });

                        $('#asset-details--edit-meter-reading').modal('show');
                    });

                grid.element.on('click', '.anchor-remove',
                    function (e) {
                        e.preventDefault();
                        const reading = grid.dataItem($(this).closest("tr[data-uid"));

                        _this.MeterReadings.selectedItem = Object.assign({}, reading);

                        $('#asset-details--delete-meter-reading').modal('show');
                    });
            },
            documentsGridOnDatabound: function (e) {
                const grid = this.$refs.documentgrid.kendoWidget();
                const _this = this;

                grid.element.on('click', '.anchor-view',
                    function (e) {
                        e.preventDefault();
                        const document = grid.dataItem($(this).closest("tr[data-uid"));
                        _this.Documents.selectedItem = Object.assign(
                            {},
                            document);

                        $('#asset-details--view-document').modal('show');
                    });

                grid.element.on('click', '.anchor-download',
                    function (e) {
                        e.preventDefault();
                        const document = grid.dataItem($(this).closest("tr[data-uid"));
                        window.open(document.DownloadUrl);
                    });

                grid.element.on('click', '.anchor-remove',
                    function (e) {
                        e.preventDefault();
                        const document = grid.dataItem($(this).closest("tr[data-uid"));

                        _this.Documents.selectedItem = Object.assign({}, document);

                        $('#asset-details--delete-document').modal('show');
                    });
            }
        }
    });
}

function formatDate(inputDate) {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date
        .toString()
        .padStart(2, '0');

    month = month
        .toString()
        .padStart(2, '0');

    // return as YYYY-MM-DD
    return year + "-" + month + "-" + date;
}

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}