import Vue from 'vue'
import { formatDateTimeToDate } from '../../Shared/date-utils';

module.export = Vue.component('viewAssetDocumentModal',
    {
        name: 'viewAssetDocumentModal',
        props: {
            item: Object
        },
        components: {},
        data: function () {
            return {
                currentItem: {},
                previewUrl: undefined
            };
        },
        methods: {
            formattedDate: function (date) {
                if (!date)
                    return;
                return formatDateTimeToDate(date.toISOString());
            },
            close: function () {
                this.currentItem = {};
                $('#asset-details--view-document').modal('hide');
            },
            loadBlobData: function (uri) {
                return new Promise((resolve, reject) => {
                    fetch(uri)
                        .then((response) => {
                            response.blob()
                                .then((blob) => resolve(blob))
                                .catch(e => reject(e));
                        }).catch(e => reject(e));
                });
            }
        },
        computed: {
            showPreviewType: function () {
                if (this.currentItem.FileType && this.currentItem.FileType.includes("Image")) {
                    return 'image'
                }
                else if (this.currentItem.FileType && this.currentItem.FileType.includes("pdf")) {
                    return 'pdf'
                }
                else if (this.currentItem.FileType && this.currentItem.FileType.includes("docx")) {
                    return 'doc'
                }
                else {
                    return undefined;
                }
            }
        },
        watch: {
            'item': function (newValue) {
                this.currentItem = newValue;

                if (this.currentItem.FileType.includes("docx")) {
                    this.loadBlobData(this.currentItem.ViewUrl).then((data) => {

                        // NOTE: docx is just a global variables because the dependency is loaded in the bundleBottom.js file
                        docx.renderAsync(data, document.getElementById("preview-word-file"), null)
                            .catch(e => console.error(e))
                    });
                }
            }
        }
    });