import Vue from 'vue'

module.export = Vue.component('addportfoliomodal',
    {
        name: 'addportfolio',
        props: {
            data: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                AddModel: {
                    PortfolioName: '',
                    PortfolioType: '',
                    PortfolioAssetType: ''
                },
                ConnectableTypes: ['Contract', 'Cost Center', 'Organization Number', 'Asset Type', 'Asset Location Country', 'Asset Location City', 'Agreement Number'],
                ConnectableAssetTypes: ['Leased Assets', 'Non Leased Assets'],
                SelectConnections: false,
                searchText: '',
                dataSource: null,
                columns: [
                    {
                        'field': 'Selected',
                        'title': ' ',
                        'filterable': false,
                        'template':
                            `<div class="checkbox">
                                <input type="checkbox" name="checkbox" #= Selected ? \'checked="checked"\' : "" #/>
                                <span class="checkmark"></span>
                            </div>`,
                        'width': '40px'
                    },
                    { 'field': 'Name', 'title': 'Name', 'filterable': false }
                ]
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            add: function (e) {
                e.preventDefault();
                this.ShowError = false;
                const _this = this;
                _this.clearAlert();
                let formattedType = _this.AddModel.PortfolioType.replace(/\s/g, '');
                (formattedType == "Contract") && (formattedType = formattedType + "Identifier");
                const formattedAssetType = _this.AddModel.PortfolioAssetType.replace(/\s/g, '');
                $("#saveButton").prop("disabled", true);
                const connections = _this.dataSource.filter(connection => connection.Selected).map(connection => connection.Id);

                $.ajax({
                    type: 'POST',
                    url: '/Portfolios/Add',
                    data: JSON.stringify({
                        name: _this.AddModel.PortfolioName,
                        type: formattedType,
                        assetType: formattedAssetType,
                        connections: connections
                    }),
                    success: function (data) {
                        if (data.success) {
                            _this.close();
                            _this.$emit('portfolio-added');
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not add portfolio: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not add portfolio due to a server error',
                                "isSuccess": false
                            });
                            $("#saveButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            next: function (e) {
                e.preventDefault();
                const _this = this;
                this.SelectConnections = true;
                let formattedType = this.AddModel.PortfolioType.replace(/\s/g, '');
                (formattedType == "Contract") && (formattedType = formattedType + "Identifier");
                const formattedAssetType = this.AddModel.PortfolioAssetType.replace(/\s/g, '');

                $.ajax({
                    type: 'GET',
                    url: `/Portfolios/AvailablePortfolioConnections?portfolioType=${formattedType}&portfolioAssetType=${formattedAssetType}`,
                    success: function (response) {
                        _this.dataSource = response.map((connection) => {
                            return {
                                Selected: false,
                                Id: connection.connectionValue,
                                Name: connection.connectionDisplay ? `${connection.connectionValue} (${connection.connectionDisplay})` : connection.connectionValue
                            };
                        });
                    },
                    error: function (error) {
                        reject(error);
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            portfolioAssetTypeChanged: function () {
                if (this.AddModel.PortfolioAssetType === 'Non Leased Assets') {
                    this.ConnectableTypes = ['Contract', 'Cost Center', 'Asset Type', 'Asset Location Country', 'Asset Location City', 'Agreement Number'];
                    if (this.AddModel.PortfolioType === 'Organization Number') {
                        this.AddModel.PortfolioType = 'Contract'
                    }
                } else {
                    this.ConnectableTypes = ['Contract', 'Cost Center', 'Organization Number', 'Asset Type', 'Asset Location Country', 'Asset Location City', 'Agreement Number'];
                }
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                this.AddModel = {
                    PortfolioName: '',
                    PortfolioType: '',
                    PortfolioAssetType: ''
                };
                this.SelectConnections = false;
                this.dataSource = null;
                $('#add-portfolio--enter-name').modal('hide');

                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            },
            search: function () {
                const filter = [];
                if (this.searchText) {
                    filter.push({ field: 'Name', operator: 'contains', value: this.searchText });
                }
                this.$refs.grid.kendoWidget().dataSource.filter(filter);
            },
            gridOnDatabound: function (e) {
                const _this = this;
                const grid = _this.$refs.grid.kendoWidget();

                // Unregister previous checkbox click events
                grid.element.off('click', '.k-grid-content tr');
                grid.element.on('click', '.k-grid-content tr', function (e) {
                    const row = grid.dataItem($(this).closest("tr[data-uid]"));
                    _this.dataSource = _this.dataSource.map(item => {
                        if (item.Name === row.Name) {
                            item.Selected = !item.Selected;
                        }
                        return item;
                    });
                });
            }
        }
    });