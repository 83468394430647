import Vue from 'vue'
import axios from 'axios';

module.export = Vue.component('addlesseemodal',
    {
        name: 'addlessee',
        props: {
            data: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                AddModel: {
                    portfolio: '',
                    CsdEmail: ''
                },
                lesseeList: [],
                dataSource: {
                    data: [],
                    serverPaging: true,
                    serverFiltering: true,
                },
                pagedData: {
                    tokens: {
                        index: -1,
                        values: []
                    },
                    offset: {
                        start: 1,
                        end: undefined
                    },
                    limit: 10,
                    totalCount: 0,
                    isFetching: undefined
                },
                columns: [
                    {
                        'field': 'selected',
                        'title': ' ',
                        'filterable': false,
                        'template':
                            `<div class="checkbox">
                                <input type="checkbox" name="checkbox" #= selected ? \'checked="checked"\' : "" #/>
                                <span class="checkmark"></span>
                            </div>`,
                        'width': '40px'
                    },
                    { 'field': 'lesseeNumber', 'title': 'Lessee Number', 'filterable': false },
                    { 'field': 'lesseeName', 'title': 'Lessee Name', 'filterable': false }
                ],
                backOffices: [],
                backOfficeFilter: '',
                searchText: ''
            };
        },
        created: function () {
            this.setBackOfficeReferences();
        },
        watch: {
            modalBeingShown: function () {
                this.clearAlert();
            }
        },
        computed: {
            prevDisabled: function () {
                return !this.hasPrevToken() || this.pagedData.isFetching;
            },
            nextDisabled: function () {
                return !this.hasNextToken() || this.pagedData.isFetching;
            }
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            hasPrevToken: function () {
                return this.pagedData.tokens.index >= 0;
            },
            hasNextToken: function () {
                return this.pagedData.tokens.values.length > 0 &&
                    this.pagedData.tokens.index < (this.pagedData.tokens.values.length + 1)
            },
            goToPrevPage: function (evt) {
                evt.preventDefault();

                if (this.hasPrevToken()) {
                    this.pagedData.tokens.index -= 1;

                    const pageToken = this.pagedData.tokens.values.splice(this.pagedData.tokens.index, 1)[0];

                    this.fetchPagedData(evt, pageToken).then(() => {
                        this.calculateOffset(false);
                    });
                }
            },
            goToNextPage: function (evt) {
                evt.preventDefault();
                if (this.hasNextToken()) {
                    this.pagedData.tokens.index += 1;

                    this.fetchPagedData(evt, this.pagedData.tokens.values[this.pagedData.tokens.index]).then(() => {
                        this.calculateOffset(true);
                    });
                }
            },
            calculateOffset: function (forward) {
                let newOffset = {};

                if (forward) {
                    newOffset = {
                        start: this.pagedData.offset.start + this.pagedData.limit,
                        end: undefined
                    };

                    newOffset.end = (newOffset.start + this.pagedData.limit) - 1;
                }
                else {
                    newOffset = {
                        start: undefined,
                        end: (this.pagedData.offset.start - 1)
                    };

                    newOffset.start = this.pagedData.offset.start - this.pagedData.limit;
                }

                Object.assign(this.pagedData.offset, newOffset);
            },
            setBackOfficeReferences: function () {
                const _this = this;
                axios.get("/Lessees/GetBackOfficeReferences")
                    .then(response => {
                        _this.backOffices = response.data.data.filter(x => {
                            return (typeof x.source !== "undefined" && typeof x.instance !== "undefined");
                        });

                        _this.backOfficeFilter = _this.backOffices[0];
                        this.fetchPagedData();
                    }).catch(error => {
                        // TODO: error msging hiervoor?
                    });
            },
            backOfficeChanged: function () {
                this.pagedData.tokens.index = -1;
                this.pagedData.tokens.values = [];
                this.pagedData.totalCount = 0;

                this.fetchPagedData();
            },
            fetchPagedData: function (evt, pageToken, searchTerm) {
                const _this = this;

                return new Promise((resolve, reject) => {
                    _this.pagedData.isFetching = true;

                    axios.post(
                        '/Lessees/GetLesseesFromBackOffice',
                        {
                            token: pageToken,
                            limit: _this.pagedData.limit
                        },
                        {
                            params: {
                                source: _this.backOfficeFilter.source,
                                instance: _this.backOfficeFilter.source,
                                type: _this.backOfficeFilter.type,
                                search: searchTerm
                            }
                        })
                        .then(response => {
                            _this.dataSource = response.data.items.map((lessee) => {
                                return {
                                    selected: (_this.lesseeList.findIndex(item => item.lesseeNumber == lessee.lesseeNumber) > -1),
                                    lesseeNumber: lessee.lesseeNumber,
                                    lesseeName: lessee.lesseeName
                                };
                            });

                            if (response.data.pageToken) {
                                _this.pagedData.tokens.values.push(response.data.pageToken);
                            }

                            _this.pagedData.totalCount = response.data.totalCount;
                            _this.pagedData.isFetching = false;

                            if (response.data.totalCount > _this.pagedData.limit) {
                                this.pagedData.offset.end = (_this.pagedData.offset.start + _this.pagedData.limit) - 1;
                            }
                            else {
                                this.pagedData.offset.end = response.data.totalCount;
                            }
                            resolve();
                        }).catch(error => {
                            reject(error);
                        });
                });
            },
            searchTextChanged: function () {
                if (this.searchText.trim().length === 0) {
                    this.searchLesseeList();
                }
            },
            searchLesseeList: function () {
                if (this.pagedData.isFetching)
                    return;

                this.pagedData.tokens.index = -1;
                this.pagedData.tokens.values = [];
                this.pagedData.totalCount = 0;

                this.fetchPagedData(null, null, this.searchText.trim());
            },
            clearSelection() {
                const grid = this.$refs.grid.kendoWidget();
                this.lesseeList = [];
                grid.dataSource.data().forEach(item => item.selected = false);
                grid.refresh();
            },
            add: function (e) {
                e.preventDefault();
                this.addLesseesFromBackOffice();
            },
            addLesseesFromBackOffice: function () {
                const _this = this;
                _this.clearAlert();
                $("#saveButton").prop("disabled", true);
                $.ajax({
                    type: 'POST',
                    url: '/Lessees/Add',
                    data: JSON.stringify({
                        lessees: _this.lesseeList.map(item => { return { lesseeNumber: item.lesseeNumber, lesseeName: item.lesseeName } }),
                        backOfficeSource: _this.backOfficeFilter.source
                    }),
                    success: function (data) {
                        if (data.success) {
                            _this.close();
                            _this.$emit('lessee-added');
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not add lessee(s): ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not add lessee(s) due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                this.clearSelection();
                this.AddModel = Object.assign({}, this.data);
                $('#add-lessee--add-lessee').modal('hide');

                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            },
            gridOnDatabound: function (e) {
                const _this = this;
                const grid = _this.$refs.grid.kendoWidget();

                // Unregister previous checkbox click events
                grid.element.off('click', '.k-grid-content tr');
                grid.element.on('click', '.k-grid-content tr', function (e) {
                    const item = grid.dataItem($(this).closest("tr[data-uid]"));
                    item.selected = !item.selected;
                    if (item.selected) {
                        _this.lesseeList.push(item);
                    } else {
                        _this.lesseeList.splice(_this.lesseeList.findIndex(i => i.lesseeNumber == item.lesseeNumber), 1);
                    }
                    grid.refresh();
                });
            }
        }
    });