import Vue from 'vue'
import VeeValidate from "vee-validate"
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import "../Grid/Grid.less"
import "./Index.less"

if (document.getElementById('personal-settings-details')) {
    if (document.getElementById('personal-settings')) {
        require('./Edit.js');

        Vue.use(GridInstaller);
        Vue.use(VeeValidate);

        new Vue({
            el: '#personal-settings-details',
            components: {},
            data: {
                EditModel: window.detailModel
            },
            mounted: function () {
                if (getUrlParameter('action') === 'edit') {
                    this.edit();
                }
            },
            methods: {
                edit: function () {
                    $('#edit-personal-settings-modal').modal('show');
                }
            }
        });

        function getUrlParameter(name) {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            const results = regex.exec(location.search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }
    }
}