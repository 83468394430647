import Vue from 'vue'
import VeeValidate from "vee-validate"

if (document.getElementById('bottom-container')) {
    require('../RedirectModal/SelectCountry.js');

    Vue.use(VeeValidate);

    new Vue({
        el: '#bottom-container',
        components: {},
        data: {
            SelectCountryModel: {
                title: '',
                elementId: 'bottom--select-country',
                hrefsProp: undefined,
                // Note: id prop equals index in allCountries array
                allCountries: [
                    {
                        id: 0,
                        name: 'United States',
                        hrefs: {
                            privacyStatement: 'https://www.lesseedirect.com/usprivacy',
                            disclaimer: '/Content/Disclaimer?country=US',
                            termsAndConditions: '/Content/TermsAndConditions?country=US'
                        }
                    },
                    {
                        id: 1,
                        name: 'Other Countries',
                        hrefs: {
                            privacyStatement: 'https://www.dllgroup.com/en/privacy-statement',
                            disclaimer: '/Content/Disclaimer',
                            termsAndConditions: '/Content/TermsAndConditions'
                        }
                    }
                ],
                countryId: -1
            }
        },
        methods: {
            showSelectCountry: function (title) {
                this.SelectCountryModel.title = title;
                this.SelectCountryModel.hrefsProp = this.camelCase(title);
                $("#bottom--select-country").modal('show');
            },
            camelCase: function (str) {
                return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                    return index == 0 ? word.toLowerCase() : word.toUpperCase();
                }).replace(/\s+/g, '');
            }
        }
    });    

    const showSelectCountryQueryParameter = (new URLSearchParams(window.location.search)).get('showSelectCountry');
    if (showSelectCountryQueryParameter) {
        v.showSelectCountry(showSelectCountryQueryParameter);
    }
}
