import Vue from 'vue'
import axios from "axios"
import { fetchJson } from '../Shared/request-utils'

module.export = Vue.component('leasedassetsupload',
    {
        name: "leasedassetsupload",
        props: {
            UploadModel: Object
        },
        data: function () {
            return {
                alerts: [],
                contractIdentifier: null,
                selectedType: null,
                dataSource: null,
                searchText: null,
                files: new FormData(),
                required: false,
                ShowSuccess: false,
                ShowProgress: false,
                ShowError: false,
                uploadProgressPercentage: 0,
                uploadFileName: "",
                uploadNotification: "",

            };
        },
        mounted: function() {
            const _this = this;
            const $assetTypeSelector = $("#assetTypeSelectorForUpload");

            _this.dataSource = new kendo.data.DataSource({
                serverFiltering: true,
                transport: {
                    read: function (options) {
                        if (options.data.filter &&
                            options.data.filter.filters.length > 0 &&
                            options.data.filter.filters[0].value.length > 0) {
                            _this.searchText = options.data.filter.filters[0].value;
                        }

                        fetchJson('/LeasedAssets/GetAssetTypesForUpload?search=' + _this.searchText)
                            .then(
                                (result) => {
                                    options.success(result);
                                },
                                (error) => {
                                    console.error(error);
                                });
                    }
                },
            });

            $assetTypeSelector.kendoDropDownList({
                filter: true,
                placeholder: "Search for asset type",
                autoBind: false,
                minLength: 3,
                enforceMinLength: true,
                dataSource: _this.dataSource,
                dataTextField: "assetTypeName",
                dataValueField: "id",
                template: ({ assetTypeName, masterAssetTypeName }) => assetTypeName + ' (' + masterAssetTypeName + ')',
                valueTemplate: () => _this.selectedType.assetTypeName + ' (' + _this.selectedType.masterAssetTypeName + ')',
                select: (e) => {
                    _this.selectedType = e.dataItem;
                }
            });

            $("#assetTypeSelectorForUpload-list .k-textbox").attr("placeholder", "Search for asset type");
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            startUpload() {
                const _this = this;

                this.files.set('selectedType', this.selectedType.id);

                axios.post('/UploadAssets/UploadLeasedAssets?contractId=' + this.contractIdentifier,
                    this.files,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        if (response.data.success) {
                            _this.ShowProgress = true;
                            const notifications = window.assetNotificationsHub;
                            notifications.on("NotifyUploadStarted", function (uploadId, originalFileName, total) {
                                if (uploadId == response.data.uploadId) {
                                    _this.uploadNotification = "Upload started";
                                }
                            });
                            notifications.on("NotifyUploadValidationFailed", function (uploadId, originalFileName, total) {
                                if (uploadId == response.data.uploadId) {
                                    _this.uploadNotification = "Upload validation failed";
                                    setTimeout(function () {
                                        _this.addAlert(
                                            {
                                                "message": 'File contains errors, please check the upload status',
                                                "isSuccess": false
                                            });
                                        $("#saveButton").removeAttr("disabled");
                                        _this.ShowError = true;
                                        _this.ShowProgress = false;
                                        _this.uploadFileName = "";
                                        _this.uploadNotification = "";
                                    }, 2000);
                                }
                            });
                            notifications.on("NotifyUploadValidationSucceeded", function (uploadId, originalFileName, total) {
                                if (uploadId == response.data.uploadId) {
                                    _this.uploadNotification = "Upload validation succeeded";
                                }
                            });
                            notifications.on("NotifyUploadProgress", function (uploadId, originalFileName, progress, failed, total) {
                                if (uploadId == response.data.uploadId) {
                                    _this.uploadNotification = `Uploading asset ${progress} of ${total} with ${failed} failed assets`;
                                    _this.uploadProgressPercentage = parseInt(progress * 100 / total);
                                }
                            });
                            notifications.on("NotifyUploadCompleted", function (uploadId, originalFileName, failed, total) {
                                if (uploadId == response.data.uploadId) {
                                    if (failed >= 0 && total >= 0) {
                                        const successful = total - failed;
                                        _this.uploadNotification = `Upload has completed with ${successful} assets and ${failed} failed assets`;
                                        setTimeout(function () {
                                            _this.ShowSuccess = true;
                                            _this.ShowProgress = false;
                                            _this.uploadFileName = "";
                                            _this.uploadNotification = "";
                                        }, 2000);
                                    } else {
                                        _this.uploadNotification = "Asset upload prematurely completed due to an error during upload validation."
                                        setTimeout(function () {
                                            this.addAlert(
                                                {
                                                    "message": `Could not upload file. Please check in properties of uploaded file, if file is not accidentally locked or anyhow damaged: ${response.data.data}`,
                                                    "isSuccess": false
                                                });
                                            _this.ShowSuccess = false;
                                            _this.ShowProgress = false;
                                            _this.uploadFileName = "";
                                            _this.uploadNotification = "";
                                        }, 2000);
                                    }
                                }
                            });
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not upload file. Please check in properties of uploaded file, if file is not accidentally locked or anyhow damaged: ${response.data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    }).catch(error => {
                        _this.addAlert(
                            {
                                "message": 'Could not upload file. Please check in properties of uploaded file, if file is not accidentally locked or anyhow damaged',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    });
            },
            fileChange(fileList) {
                this.required = false;
                this.uploadFileName = "";
                this.uploadNotification = "";
                for (let i = 0; i < fileList.length; i++) {
                    this.files.set("file", fileList[i], fileList[i].name);
                    this.uploadFileName += fileList[i].name;
                }
            },
            save() {
                $("#saveButton").prop("disabled", true);
                this.validate();
                if (!this.required) {
                    this.startUpload();
                }
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                this.ShowSuccess = false;
                this.ShowError = false;
                this.selectedType = null;
                this.uploadProgressPercentage = 0;
                this.uploadFileName = "";
                this.uploadNotification = "";
                this.contractIdentifier = null;
                this.files = new FormData();
                this.required = false;
                document.getElementById("uploadForm").reset();
                $('#leased-assets-upload-modal').modal('hide');

                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            },
            show: function () {
                this.contractIdentifier = null;
                this.selectedType = null;
                this.files = new FormData();
                $('#leased-assets-upload-modal').modal('show');
            },
            validate: function () {
                this.required = !(this.selectedType != null && this.selectedType.id.length > 0 && this.files.get("file"));
            },
            downloadTemplate: function () {
                axios.get('/UploadAssets/GetLeasedAssetsUploadTemplate?assetTypeFieldId=' + this.selectedType.id, { responseType: 'blob' })
                    .then(response => {
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(response.data);
                        link.download = 'LeasedTemplate_' + new Date().toISOString() + '.xlsx';
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }).catch(console.error);
            }
        },
        watch: {
            selectedType: function (val) {
                this.required = false;
            }
        }
    });

