function generateQrCodes(selectedItems, endpoint) {
    const container = $(".qr-codes-container");
    container.empty();
    selectedItems.sort(sortCustomerNames);

    selectedItems.forEach((item, index) => {
        const qrCodeContainer =
            "<div class='col-sm-6 qr-code-details text-center'>" +
                "<div class='qr-container' id='qr-" + index + "'></div>" +
                (item.customerName !== undefined ? "<div>Customer: " + addNewLines(item.customerName) + "</div>" : "") +
                "<div>S/N: " + addNewLines(item.serialNumber) + "</div>" +
                "<div>Model: " + addNewLines(item.assetName) + "</div>";
            "</div > "

        container.append(qrCodeContainer);

        $("#qr-" + index).kendoQRCode({
            value: window.location.origin + "/" + window.preLoadedData.AccountId + "/MeterReadings/" + endpoint + "?id=" + item.id,
            errorCorrection: "Q",
            encoding: "UTF_8",
            size: 140
        }); 

        const maxCodesPerPage = 6;
        const reachedMaxOnPage = (index + 1) % maxCodesPerPage == 0;
        if (reachedMaxOnPage && selectedItems.length > maxCodesPerPage) {
            container.append("<div class='page-break'></div>");
        }
    });
}

function sortCustomerNames(first, second) {
    if (first.customerName < second.customerName)
        return -1;
    if (first.customerName > second.customerName)
        return 1;
    return 0;
}

function addNewLines(text) {
    if (text.length > 25) {
        text = text.replace(/(.{20})/g, placeBreakpoint);
    }

    return text;
}

function placeBreakpoint(str, group1, group2) {
    return group1 + '<br />';
}

export { generateQrCodes }