import Vue from 'vue'

module.export = Vue.component('alerts', {
    name: 'alerts',
    props: {
        alerts: Array
    },
    data: function () {
        return { };
    },
    methods: {
        clearAlert: function (index) {
            //Clearing it on the parent since that is the one receiving the alerts as well.
            this.$parent.clearAlert(index);
        }
    },
    watch: {
        alerts: {
          immediate: true, 
          handler (newValue, oldVal) {
            const _this = this;
            if (newValue && newValue.length > 0 && newValue !== oldVal) {       
                for (let i = 0; i < newValue.length; i++) {
                    if (newValue[i].showPopup) {
                        $('#notification-message').removeAttr("style");
                        window.setTimeout(function () {
                            $('#notification-message').fadeOut(750, function () {
                                _this.clearAlert(i);
                            });
                        }, 3000);
                    }
                }
            }
          }
        }
      }
      
});