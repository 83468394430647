import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { splitToParts } from '../Shared/request-utils'

module.export = Vue.component('leasedassetsbulkedit',
    {
        name: "leasedassetsbulkedit",
        props: {
            selectedAssets: Array,
            assetCount: Number,
            assetsShouldBeExcluded: Boolean,
            assetFilter: Object,
            isDllUser: Boolean
        },
        components: {
            DatePicker
        },
        data: function () {
            return {
                alerts: [],
                ShowConfirmation: false,
                ShowFeedback: false,
                statuses: ['Booked', 'Terminated', 'Other'],
                customStatus: '',
                terminatedCount: 0,
                changedCount: 0,
                unChangedCount: 0,
                visibleAssetTypeFields: [],
                someFieldNoEditRight: false,
                assetIdsWithNotEditableFields: [],
                bulkUpdateLocation: '',
                bulkUpdateNotification: '',
                bulkUpdateProgressPercentage: 0,
                Items: {
                    Assets: {},
                    User: { HasChanged: false },
                    OrderNumber: { HasChanged: false },
                    CostCenter: { HasChanged: false },
                    MainCostCenter: { HasChanged: false },
                    AgreementNumber: { HasChanged: false },
                    AgreementLine: { HasChanged: false },
                    AgreementLineStatus: { HasChanged: false },         
                    AgreementType: { HasChanged: false },    
                    AssetStatusDll: { HasChanged: false },
                    AssetModel: { HasChanged: false },
                    CustomerName: { HasChanged: false },
                    CustomerReference: { HasChanged: false },
                    OrganizationNumber: { HasChanged: false },
                    InvoiceGroup: { HasChanged: false }, 
                    PartialStartNumber: { HasChanged: false }, 
                    InvoiceLocationStreet: { HasChanged: false },
                    InvoiceLocationCity: { HasChanged: false },
                    InvoiceLocationPostCode: { HasChanged: false },
                    InvoiceLocationRegion: { HasChanged: false },
                    InvoiceLocationCountry: { HasChanged: false },
                    AssetLocationStreet: { HasChanged: false },
                    AssetLocationCity: { HasChanged: false },
                    AssetLocationPostCode: { HasChanged: false },
                    AssetLocationRegion: { HasChanged: false },
                    AssetLocationCountry: { HasChanged: false },
                    AssetStatusCustomer: { HasChanged: false },
                    ExtensionRent: { HasChanged: false },
                    ExtensionDate: { HasChanged: false },
                    AgreementTerminationDate: { HasChanged: false },
                    CustomerGuaranteedResidualValue: { HasChanged: false },
                    DealerGuaranteedResidualValue: { HasChanged: false },
                    DllGuaranteedResidualValue: { HasChanged: false },
                    Price: { HasChanged: false },
                    Rent: { HasChanged: false },
                    StartDate: { HasChanged: false },
                    EndDate: { HasChanged: false },
                    Currency: { HasChanged: false },
                    ProductSku: { HasChanged: false },
                    ManufactureNumber: { HasChanged: false },
                    SerialNumber: { HasChanged: false },
                    Brand: { HasChanged: false },
                    ProductName: { HasChanged: false },
                    ContractIdentifier: { HasChanged: false },    
                    DealerContactPerson: { HasChanged: false },                
                    Insurance: { HasChanged: false },
                    Note: { HasChanged: false },
                    AssetTypeFields: []
                },
                InitalItems: {}
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            isEditable: function(fieldName) {
                return window.preLoadedData.AllowedBulkEditFields.includes(fieldName);
            },
            validateBeforeSubmit: function () {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        const vueObject = this;
                        vueObject.ShowConfirmation = true;
                    }
                });
            },
            hideConfirmBulkEditAsset: function () {
                const vueObject = this;
                vueObject.ShowConfirmation = false;
            },
            confirmedBulkEditAsset: function () {
                const vueObject = this;
                if (!this.assetsShouldBeExcluded) {
                    const totalCount = this.selectedAssets.length;
                    this.Items.Assets = this.selectedAssets.filter(a => a.assetStatusDll !== 'Terminated').map((a) => ({
                        id: a.id,
                        assetTypeId: a.assetTypeId
                    }));
                    vueObject.terminatedCount = totalCount - this.Items.Assets.length;
                    $("#saveButton").prop("disabled", true);
                    const bulksize = 250;
                    const assetsInParts = splitToParts(this.Items.Assets, bulksize);
                    vueObject.changedCount = 0;
                    vueObject.unChangedCount = 0;
                    for (let i = 0; i < assetsInParts.length; i++) {
                        setTimeout(() => {
                            const items = JSON.parse(JSON.stringify(vueObject.Items));
                            items.AssetStatusDll.Value = items.AssetStatusDll.Value == 'Other' ? vueObject.customStatus : items.AssetStatusDll.Value;
                            items.Assets = items.Assets.slice(i * 250, (i + 1) * 250);
                            items.AssetTypeFields = vueObject.visibleAssetTypeFields.map(a => ({
                                Name: a.Name,
                                Value: a.Value,
                                HasChanged: a.HasChanged
                            }));
                            this.bulkUpdateLeasedAssets(vueObject, items).then((response) => {
                                if (response.success) {
                                    vueObject.bulkUpdateLocation = response.location;
                                    vueObject.ShowFeedback = true;
                                    vueObject.changedCount = vueObject.changedCount + response.data.numberOfAssetsUpdatedSuccesfully;
                                    vueObject.unChangedCount = vueObject.unChangedCount + response.data.numberOfAssetsUpdatedFailed;
                                    if (!vueObject.someFieldNoEditRight) {
                                        vueObject.someFieldNoEditRight = response.someFieldNoEditRight;
                                    }
                                    if (response.data.assetIdsWithNotEditableFields.length > 0) {
                                        vueObject.assetIdsWithNotEditableFields.push(...response.data.assetIdsWithNotEditableFields);
                                    }
                                } else {
                                    vueObject.addAlert(
                                        {
                                            "message": `Could not bulk edit asset(s): ${response.data}`,
                                            "isSuccess": false
                                        });
                                    $("#saveButton").removeAttr("disabled");
                                }
                            },
                                (error) => {
                                    vueObject.addAlert(
                                        {
                                            "message": 'Could not bulk edit asset(s) due to a server error',
                                            "isSuccess": false
                                        });
                                    $("#saveButton").removeAttr("disabled");
                                });
                        }, 200);
                    }
                    vueObject.ShowConfirmation = false;
                    vueObject.ShowFeedback = true;
                } else {
                    vueObject.ShowConfirmation = false;
                    this.fetchPagedAssetIds().then((response) => {
                        this.builkUpdateLeasedAssetsFromResponseRecursively(vueObject, response);
                    },
                    (error) => {
                        vueObject.addAlert(
                            {
                                "message": 'Could not bulk edit asset(s) due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    });
                }
            },
            bulkUpdateLeasedAssets: function (vueObject, items) {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        type: 'POST',
                        url: '/BulkEdit/BulkUpdateLeasedAssets',
                        data: JSON.stringify(items),
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            fetchPagedAssetIds: function (pageToken) {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        type: 'POST',
                        url: '/LeasedAssets/QueryIds',
                        data: JSON.stringify(({
                            token: pageToken,
                            limit: 250,
                            filter: this.assetFilter
                        })),
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            builkUpdateLeasedAssetsFromResponseRecursively: function (vueObject, response) {
                if (response) {
                    vueObject.ShowFeedback = true;
                    const token = response.pageToken;

                    const items = JSON.parse(JSON.stringify(vueObject.Items));
                    items.AssetStatusDll.Value = items.AssetStatusDll.Value == 'Other' ? vueObject.customStatus : items.AssetStatusDll.Value;
                    const totalCount = response.items.length;
                    items.Assets = response.items.filter(a => a.status !== "Terminated" && !this.selectedAssets.some(a2 => a2.id === a.id)).map((a) => ({
                        id: a.id,
                        assetTypeId: a.assetTypeDefinitionId
                    }));
                    vueObject.terminatedCount = totalCount - items.Assets.length;
                    this.bulkUpdateLeasedAssets(vueObject, items).then((bulkEditResponse) => {
                        if (bulkEditResponse.success) {
                            vueObject.bulkUpdateLocation = bulkEditResponse.location;
                            vueObject.changedCount = vueObject.changedCount + bulkEditResponse.data.numberOfAssetsUpdatedSuccesfully;
                            vueObject.unChangedCount = vueObject.unChangedCount + bulkEditResponse.data.numberOfAssetsUpdatedFailed;
                            if (!vueObject.someFieldNoEditRight) {
                                vueObject.someFieldNoEditRight = bulkEditResponse.someFieldNoEditRight;
                            }
                            if (bulkEditResponse.data.assetIdsWithNotEditableFields.length > 0) {
                                vueObject.assetIdsWithNotEditableFields.push(...bulkEditResponse.data.assetIdsWithNotEditableFields);
                            }
                        } else {
                            vueObject.addAlert(
                                {
                                    "message": `Could not bulk edit asset(s): ${bulkEditResponse.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                        if (token) {
                            this.fetchPagedAssetIds(token).then((response) => {
                                this.builkUpdateLeasedAssetsFromResponseRecursively(vueObject, response);
                            },
                                (error) => {
                                    vueObject.addAlert(
                                        {
                                            "message": 'Could not bulk edit asset(s) due to a server error',
                                            "isSuccess": false
                                        });
                                    $("#saveButton").removeAttr("disabled");
                                });
                        }
                    },
                        (error) => {
                            vueObject.addAlert(
                                {
                                    "message": 'Could not bulk edit asset(s) due to a server error',
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        });
                }
            },
            extractFileName(disposition) {
                const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
                const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
            
                let fileName = null;
                if (utf8FilenameRegex.test(disposition)) {
                  fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
                } else {
                  // prevent ReDos attacks by anchoring the ascii regex to string start and
                  //  slicing off everything before 'filename='
                  const filenameStart = disposition.toLowerCase().indexOf('filename=');
                  if (filenameStart >= 0) {
                    const partialDisposition = disposition.slice(filenameStart);
                    const matches = asciiFilenameRegex.exec(partialDisposition );
                    if (matches != null && matches[2]) {
                      fileName = matches[2];
                    }
                  }
                }
                return fileName;
            },            
            download: function () {
                const self = this;
                const items = JSON.parse(JSON.stringify(this.Items));
                const changedFields = [];
                for (const key in items) {
                    if (items[key].HasChanged) {
                        changedFields.push({
                            name: key,
                            value: items[key].Value || null
                        });
                    }
                }

                var xhrOverride = new XMLHttpRequest();
                xhrOverride.responseType = 'arraybuffer';                

                $.ajax({
                    type: 'POST',
                    url: '/BulkEdit/GetFailedAssetsExcelFile',
                    xhr: function () {
                        return xhrOverride;
                    },
                    data: JSON.stringify(({
                        failedAssetsIds: this.assetIdsWithNotEditableFields,
                        changedFields
                    })),
                    success: function (response, status, xhr) {

                        var contentType = xhr.getResponseHeader("Content-Type");
                        var filename = self.extractFileName(xhr.getResponseHeader("Content-disposition"));

                         const blob = new Blob([response], { type: contentType });
                         let link = document.createElement('a');
                         link.href = window.URL.createObjectURL(blob);
                         link.download = filename;
                         link.click();
                    },
                    error: function (error) {
                        console.error(error);
                    },
                    contentType: "application/json"
                });
            },
            close: function () {
                this.bulkUpdateNotification = '';
                this.bulkUpdateProgressPercentage = 0;
                this.assetIdsWithNotEditableFields = [];
                this.ShowFeedback = false;
                this.clearAlert();
                this.Items = JSON.parse(JSON.stringify(this.InitalItems)); //Properties on the prototype chain and non-enumerable properties cannot be (deep)copied.

                $('#leased-assets-bulkedit-modal').modal('hide');

                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            },
            getSpecificAssetTypeFields: function (assetTypeIds) {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        type: 'POST',
                        url: '/BulkEdit/GetSpecificAssetTypeFields',
                        data: JSON.stringify(({
                            assetTypeIds: assetTypeIds
                        })),
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },            
            show: function (selectedAssets) {
                const _this = this;

                this.InitalItems = JSON.parse(JSON.stringify(this.Items)); //Properties on the prototype chain and non-enumerable properties cannot be (deep)copied.

                let assetTypeIds = [...new Set(selectedAssets.filter(a => a.assetStatusDll !== 'Terminated').map(a => a.assetTypeId))];

                this.getSpecificAssetTypeFields(assetTypeIds).then((response) => {
                    this.visibleAssetTypeFields = response.map(a => ({
                        Name: a.name,
                        Type: a.type,
                        Mandatory: false,
                        Value: null,
                        HasChanged: false
                    }));
                    $('#leased-assets-bulkedit-modal').modal('show');
                    this.bulkUpdateNotification = '';
                    this.bulkUpdateProgressPercentage = 0;
                    const notifications = window.assetNotificationsHub;
                    notifications.on("NotifyBulkUpdateStarted", function (bulkUpdateId, total) {
                        if (_this.bulkUpdateLocation.endsWith(bulkUpdateId)) {
                            _this.bulkUpdateNotification = `Started editing ${total} assets.`;
                        }
                    });
                    notifications.on("NotifyBulkUpdateProgress", function (bulkUpdateId, progress, total) {
                        if (_this.bulkUpdateLocation.endsWith(bulkUpdateId)) {
                            _this.bulkUpdateProgressPercentage = parseInt(progress * 100 / total);
                        }
                    });
                    notifications.on("NotifyBulkUpdateCompleted", function (bulkUpdateId, total) {
                        if (_this.bulkUpdateLocation.endsWith(bulkUpdateId)) {
                            _this.bulkUpdateNotification = `${total} assets successfully edited!`;
                        }
                    });
                });
            },
            complete: function () {
                this.$emit('refresh-data');
                this.close();
            },

        }
    });
