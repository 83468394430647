const padZero = (intToPad) => {
    return (intToPad < 10 ? "0" : "") + intToPad;
};

const formatDateTime = value => {
    if (/^([0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2})$/.test(value)) {
        return value;
    }
    else if (value) {
        const date = new Date(value);

        const year = date.getFullYear(),
            month = padZero(date.getMonth() + 1), // months are zero indexed
            day = padZero(date.getDate()),
            hour = padZero(date.getHours()),
            minute = padZero(date.getMinutes()),
            seconds = padZero(date.getSeconds());

        return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + seconds;
    }

    return "";
}

const formatDateTimeToDate = (value) => {
    if (/^([0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2})$/.test(value)) {
        return value;
    }
    else if (value) {
        const date = new Date(value);

        const year = date.getFullYear(),
            month = padZero(date.getMonth() + 1), // months are zero indexed
            day = padZero(date.getDate())

        return year + "-" + month + "-" + day;
    }

    return "";
}

export { formatDateTime, formatDateTimeToDate }
