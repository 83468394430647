import Vue from 'vue'

module.export = Vue.component('editportfoliomodal',
    {
        name: 'editportfolio',
        props: {
            data: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                EditModel: Object.assign({}, this.data), //create deep a copy of the object
                connectableTypes: window.detailModel.FormattedAssetType == 'Non Leased Assets' ? ['Contract', 'Cost Center', 'Asset Type', 'Asset Location Country', 'Asset Location City', 'Agreement Number'] : ['Contract', 'Cost Center', 'Organization Number', 'Asset Type', 'Asset Location Country', 'Asset Location City', 'Agreement Number'],
                connectableAssetTypes: ['Leased Assets', 'Non Leased Assets'],
                connectionType: window.detailModel.FormattedType,
                assetType: window.detailModel.FormattedAssetType
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();
                let formattedType = _this.connectionType.replace(/\s/g, '');
                (formattedType == "Contract") && (formattedType = formattedType + "Identifier");
                const typeChanged = formattedType !== _this.EditModel.PortfolioType;
                const formattedAssetType = _this.assetType.replace(/\s/g, '');
                const assetTypeChanged = formattedAssetType !== _this.EditModel.PortfolioAssetType;
                $("#saveButton").prop("disabled", true);
                $.ajax({
                    type: 'POST',
                    url: '/Portfolios/Edit',
                    data: JSON.stringify({
                        portfolioId: _this.EditModel.PortfolioId,
                        portfolioName: _this.EditModel.PortfolioName,
                        portfolioType: typeChanged ? formattedType : null,
                        portfolioAssetType: assetTypeChanged ? formattedAssetType : null
                    }),
                    success: function (data) {
                        if (data.success) {
                            _this.close();
                            let url = new URL(location.href);
                            url.searchParams.delete('action');
                            window.location.href = url.href;
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not change portfolio: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not change portfolio due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            assetTypeChanged: function() {
                if (this.assetType === 'Non Leased Assets') {
                    this.connectableTypes = ['Contract', 'Cost Center', 'Asset Type', 'Asset Location Country', 'Asset Location City', 'Agreement Number'];
                    if (this.connectionType === 'Organization Number') {
                        this.connectionType = 'Contract'
                    }
                } else {
                    this.connectableTypes = ['Contract', 'Cost Center', 'Organization Number', 'Asset Type', 'Asset Location Country', 'Asset Location City', 'Agreement Number'];
                }
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                this.EditModel = Object.assign({}, this.data);
                $('#edit-portfolio--change-name').modal('hide');

                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            }
        }
    });