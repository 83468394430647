// ASP.NET JSON request default limit 1000
function splitToParts(arr, bulkSize = 500) {
    const parts = [];
    for (let i = 0; i < Math.ceil(arr.length / bulkSize); i++) {
        parts.push(arr.slice(i * bulkSize, (i + 1) * bulkSize));
    }
    return parts;
}

function fetchJson(uri) {
    return new Promise((resolve, reject) => {
        $.ajax({
            url: uri,
            contentType: 'application/json',
            dataType: 'json',
            type: 'GET',
            success: function (response) {
                resolve(response);
            },
            error: function (error) {
                reject(error);
            },
        })
    });
}

export { splitToParts, fetchJson }