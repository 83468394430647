import Vue from 'vue'
import VeeValidate from "vee-validate"
import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import { toArray } from 'lodash';
import "../Grid/Grid.less"
import { fetchJson } from '../Shared/request-utils'

if (document.getElementById('vue-members-overview')) {
    const actionbar = require("../Actionbar/Actionbar.js");
    const alerts = require("../Alerts/Alerts.js");
    require("./Add.js");
    require("./Edit.js");

    Vue.use(GridInstaller);
    Vue.use(VeeValidate);

    const pagesizesession = "pagesize";
    let pagesize = sessionStorage.getItem(pagesizesession);
    if (!pagesize) {
        pagesize = 20;
    }

    new Vue({
        el: '#vue-members-overview',
        components: {},
        data: function () {
            return {
                HasBranding: window.hasBranding,
                dataSource: {
                    data: [],
                    transport: {
                        read: {
                            url: "/Settings/ManageMembers/List",
                            contentType: "application/json",
                            type: "POST"
                        },
                        parameterMap: function (options) {
                            return kendo.stringify(options);
                        }
                    },
                    serverPaging: false,
                    serverFiltering: false,
                    serverSorting: false,
                    pageSize: pagesize,
                    schema: {
                        data: "data",
                        total: "total"
                    }
                },
                AddModel: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    role: '',
                    countries: [],
                    allRoles: window.preLoadedData.AllRoles.filter(r => r.IsAllowedRole),
                    allCountries: window.preLoadedData.AllCountries
                },
                EditModel: {
                    id: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    role: '',
                    countries: [],
                    allRoles: window.preLoadedData.AllRoles,
                    allCountries: window.preLoadedData.AllCountries
                },
                pageable: {
                    buttonCount: 3,
                    info: true,
                    type: 'numeric',
                    pageSizes: [10, 20, 30, 50],
                    previousNext: true
                },
                columns: [
                    { 'field': 'firstName', 'title': 'First name', 'filterable': false, 'width': '20%' },
                    { 'field': 'lastName', 'title': 'Last name', 'filterable': false, 'width': '20%' },
                    { 'field': 'email', 'title': 'Email', 'filterable': false, 'width': '20%' },
                    { 'field': 'role', 'title': 'Role', 'filterable': false, 'width': '20%' },
                    { 'field': 'countries', 'title': 'Countries', 'filterable': false, 'width': '20%' },      
                    { 'title': ' ', 'filterable': false, 'template':  '<a class="anchor-edit" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--edit" title="Edit"></span></a>', 'width': '120px' }
                ],
                actionbar: window.preLoadedData.Actionbar,
                search: {
                    visible: true
                },
                alerts: []                
            }
        },
        created: function () {
            this.actionbar.searchVisible = true;
        },
        methods: {
            addTooltip: function addTooltipToTableHeaders(grid) {
                grid.element[0].querySelectorAll(".k-header").forEach(element => {
                    const title = $(element).data("title");

                    if (!/<[a-z\][\s\S]*>/i.test(title)) {

                        $(element).attr('title', title);
                    }
                });
            },
            refreshGrid: function (message) {
                if (message) {
                    this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });
                }
                const grid = this.$refs.grid.kendoWidget();
                grid.dataSource.read();
            },
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            actionbarAddMember: function() {
                this.$refs.addmember.show();
            },
            actionbarSearch: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                let filter = {};
                if (e !== null && e !== '') {
                    filter = { logic: 'or', filters: [] };
                    grid.columns.forEach(function (x) {
                        if (x.field) {
                            filter.filters.push({
                                field: x.field,
                                operator: 'contains',
                                value: e
                            });
                        }
                    });
                }
                grid.dataSource.filter(filter);
            },
            editMember: function(e, grid) {
                e.preventDefault();
                var _this = this;
                const selectedItems = grid.select().toArray().map(function (s) { return grid.dataItem(s).id; });
                if (selectedItems.length === 1) {
                    fetchJson('./ManageMembers/GetMemberDetails?id=' + selectedItems[0])
                    .then(
                        (result) => {
                            var editModel = Object.assign({}, result);
                            editModel.allRoles = window.preLoadedData.AllRoles;
                            editModel.allCountries = window.preLoadedData.AllCountries;
                            _this.$refs.editmember.show(editModel);
                        },
                        (error) => {
                            console.error(error);
                        });
                }
            },
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                grid.element.unbind('dblclick');
                grid.element.unbind('click');

                grid.element.on('dblclick', 'tbody tr[data-uid]', (e) => this.editMember(e, grid));
                grid.element.on('click', '.anchor-edit', (e) => this.editMember(e, grid));

                this.addTooltip(grid);
            }
        }
    });
}