import Vue from 'vue'
import "./Edit.less"

module.export = Vue.component('editmembermodal',
    {
        name: 'editmember',
        props: {
            data: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                EditModel: Object.assign({}, this.data), //create deep a copy of the object
                isSaving: false,
                countryValidator: undefined
            };
        },
        mounted: function() {
            const _this = this;
            const countries = _this.EditModel.allCountries;
            kendo.jQuery("#countriesSelectorEdit").kendoMultiSelect({
                dataSource: countries,
                filter: "contains",
                tagTemplate: kendo.template($("#tagTemplate").html()),
                placeholder: "Select country",
                autoClose: false,
                tagMode: "single",
                noDataTemplate: "No countries available",
                dataTextField: "Value",
                dataValueField: "Key",
                itemTemplate: `<div class="checkbox">
                        #= Value #
                        <input type="checkbox" name="checkbox" value="#= Key #"/>
                        <span class="checkmark"></span>
                    </div>`,
                change: function () {
                    const selected = this.value();
                    const items = this.listView.content.find("li");
                    items.each(function () {
                        const checkbox = $(this).find("input[type='checkbox']");
                        const value = checkbox.val();
                        checkbox.prop("checked", selected.indexOf(value) > -1);
                    });

                    _this.EditModel.countries = selected;
                    if (selected.length == 0) {
                        this.tagList.children().each(function() {
                            this.remove();
                        });
                    }
                },
                open: function () {
                    const selected = this.value();
                    const items = this.listView.content.find("li");
                    items.each(function () {
                        const checkbox = $(this).find("input[type='checkbox']");
                        const value = checkbox.val();
                        checkbox.prop("checked", selected.indexOf(value) > -1);
                    });
                }
            });

            this.countryValidator = kendo.jQuery("#countriesSelectorEdit").kendoValidator({
                rules: {
                  hasItems: function (input) {
                    if(input.is("[name=countries]")){
                      var ms = input.data("kendoMultiSelect");
                      if(ms.value().length < 1){
                        return false;
                      }
                    }
                    return true;
                  }
                },
                messages: {
                  hasItems: "Please select 1 or more countries."
                }
              }).data("kendoValidator"); 
            
            $("input[name=countries]").on("blur", function() {
                _this.countryValidator.validate();
            });

            $(".k-multiselect-wrap").append("<span class='glyphicon glyphicon-chevron-down'></span>");
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();

                _this.isSaving = true;

                const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                const isValidMail = re.test(String(_this.EditModel.email).toLowerCase());

                var isCountryValid = _this.countryValidator.validate();

                if (!isCountryValid) {
                    _this.isSaving = false;
                } else {
                    if (!isValidMail) {
                        _this.addAlert(
                            {
                                "message": 'Could not add member due to invalid email address',
                                "isSuccess": false
                            });
                        _this.isSaving = false;
                    } else {
                        $.ajax({
                            type: 'POST',
                            url: '/Settings/ManageMembers/Edit',
                            data: JSON.stringify({
                                id: _this.EditModel.id,
                                firstName: _this.EditModel.firstName,
                                lastName: _this.EditModel.lastName,
                                email: _this.EditModel.email,
                                roleId: _this.EditModel.role,
                                countries: _this.EditModel.countries
                            }),
                            success: function (data) {
                                if (data.success) {
                                    _this.close();
                                    _this.$emit('member-changed');
                                } else {
                                    _this.addAlert(
                                        {
                                            "message": `Could not edit member: ${data.data}`,
                                            "isSuccess": false
                                        });
                                }
                                _this.isSaving = false;
                            },
                            error: function (data) {
                                _this.addAlert(
                                    {
                                        "message": 'Could not edit member due to a server error',
                                        "isSuccess": false
                                    });
                                _this.isSaving = false;
                            },
                            contentType: "application/json",
                            dataType: 'json'
                        });
                    }
                }
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            show: function(data) {
                var _this = this;
                this.EditModel = Object.assign({}, data);
                const countriesSelector = kendo.jQuery("#countriesSelectorEdit").getKendoMultiSelect();
                countriesSelector.value(this.EditModel.countries);
                const items = countriesSelector.listView.content.find("li");
                items.each(function () {
                    const checkbox = $(this).find("input[type='checkbox']");
                    const value = checkbox.val();
                    checkbox.prop("checked", _this.EditModel.countries.indexOf(value) > -1);
                });   
                $('#memberoverview-edit-member').modal('show');
            },
            close: function () {
                this.clearAlert();
                $('#memberoverview-edit-member').modal('hide');

                // Enable save button again.
                this.isSaving = false;
            }
        }
    }
);
