import Vue from 'vue'
import VeeValidate from "vee-validate"
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import "../Grid/Grid.less"

if (document.getElementById('vue-user-overview')) {
    const actionbar = require("../Actionbar/Actionbar.js");
    const alerts = require("../Alerts/Alerts.js");
    require("./Add.js");
    require("./AddRequest.js");
    require("./ApprovalUsers.js");
    require("./Edit.js");

    Vue.use(GridInstaller);
    Vue.use(VeeValidate);

    const pagesizesession = "pagesize";
    let pagesize = sessionStorage.getItem(pagesizesession);
    if (!pagesize) {
        pagesize = 20;
    }
    const apiUrl = window.preLoadedData.SourceUrl;
    new Vue({
        el: '#vue-user-overview',
        components: {},
        data: function () {
            return {
                EditAndRemoveAllowed: window.preLoadedData.EditAndRemoveAllowed,
                AddModel: {
                    firstName: '',
                    lastName: '',
                    mail: '',
                    allRoles: window.preLoadedData.AllRoles,
                    allPortfolios: window.preLoadedData.AllPortfolios,
                    portfolios: [],
                    role: ''
                },
                AddRequestModel: {
                    firstName: '',
                    lastName: '',
                    mail: '',
                    allRoles: window.preLoadedData.AllRoles,
                    role: '',
                    allPortfolios: window.preLoadedData.AllPortfolios,
                    portfolios: [],
                    remmark: ''
                },
                EditModel: {
                    roleId: '',
                    allRoles: window.preLoadedData.AllRoles,
                    userId: '',
                    status: undefined
                },
                ApprovalModel: {
                    userStatus: ""
                },
                dataSource: {
                    data: [],
                    transport: {
                        read: {
                            url: "/Settings/ManageUsers/List",
                            contentType: "application/json",
                            type: "POST"
                        },
                        parameterMap: function (options) {
                            return kendo.stringify(options);
                        }
                    },
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    pageSize: pagesize,
                    schema: {
                        data: "data",
                        total: "total"
                    }
                },
                pageable: {
                    buttonCount: 3,
                    info: true,
                    type: 'numeric',
                    pageSizes: [10, 20, 30, 50],
                    previousNext: true
                },
                columns: {},
                actionbar: window.preLoadedData.Actionbar,

                search: {
                    visible: true
                },
                alerts: [],
                selectedUsers: [],
                userCount: 0,
            };
        },
        created: function () {
            this.loadColumns();

            this.actionbar.searchVisible = true;
        },
        mounted: function () {
            const grid = this.$refs.grid.kendoWidget();
            const _this = this;
            
            grid.element.on('click', '.visual-checkbox',
                function (e) {
                    e.preventDefault();
                    const cb = this.querySelector("input[type='checkbox']");
                    cb.checked = !cb.checked;
                    const dataItem = grid.dataItem($(this).closest("tr[data-uid"));
                    if (cb.checked) {
                        $(this.parentElement).addClass("state-selected");
                    } else {
                        $(this.parentElement).removeClass("state-selected");
                    }
                    dataItem.selected = cb.checked;

                    _this.selectOnChange();
                });
            _this.selectOnChange();
        },
        methods: {
            refreshGrid: function () {
                const grid = this.$refs.grid.kendoWidget();
                grid.dataSource.read();
            },
            approvalChanged: function (message, eventType, item) {
                this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });
                this.refreshGrid();
                const approvalUsersButton = this.findActionBarButton("ab-approval-users");
                if (approvalUsersButton != null) {
                    approvalUsersButton.enabled = false;
                }
            },
            addAlert: function (event) {
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            loadColumns: function (e) {
                this.columns = [
                    { 'field': 'name', 'title': 'Name', 'filterable': false, 'width': '20%' },
                    { 'field': 'email', 'title': 'Email', 'filterable': false, 'width': '20%' },
                    { 'field': 'role', 'title': 'Role', 'filterable': false, 'width': '20%' },
                    { 'field': 'status', 'title': 'Status', 'filterable': false, 'width': '30%', 'template': '<span class="user-status #: status.toLowerCase() #">#: status #</span>' },
                ];
                let rowactions = '';
                //add conditions to add following 
                if (this.EditAndRemoveAllowed) {
                    this.columns.unshift({ 'field': 'selected', 'title': ' ', 'filterable': false, 'template': '<div #= selected ? \'class="state-selected"\' : "" #><a class="visual-checkbox" href="\\#"><input type="checkbox" #= selected ? \'checked="checked"\' : "" # /></a></div>', 'width': '40px' });
                    rowactions += '<a class="anchor-edit" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--edit" title="Edit"></span></a>';
                    rowactions += '<a class="anchor-remove" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--trash-can" title="Remove user"></span></a>';                
                }



                if (rowactions != '') {
                    this.columns.push({ 'title': ' ', 'filterable': false, 'template': rowactions, 'width': '90x' });
                }
            },
            addTooltip: function addTooltipToTableHeaders(grid) {
                grid.element[0].querySelectorAll(".k-header").forEach(element => {
                    const title = $(element).data("title");

                    if (!/<[a-z\][\s\S]*>/i.test(title)) {

                        $(element).attr('title', title);
                    }
                });
            },
            actionbarSearch: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                let filter = {};
                let searchProperties = ["FirstName", "LastName", "Email"];
                if (e !== null && e !== '') {
                    filter = { logic: 'or', filters: [] };
                    searchProperties.forEach(function (x) {
                        if (x) {
                            filter.filters.push({
                                field: x,
                                operator: 'contains',
                                value: e.trim()
                            });
                        }
                    });
                }

                grid.dataSource.filter(filter);
            },
            actionbarSelectAll: function (checked) {
                const grid = this.$refs.grid.kendoWidget();
                if (checked) {
                    grid.dataSource.data().forEach(i => i.selected = true);
                    grid.element[0].querySelectorAll(".visual-checkbox").forEach(cb => $(cb.parentElement).addClass("state-selected"));
                    grid.element[0].querySelectorAll("input[type='checkbox']").forEach(cb => cb.checked = true);
                } else {
                    grid.dataSource.data().forEach(i => i.selected = false);
                    grid.element[0].querySelectorAll(".visual-checkbox").forEach(cb => $(cb.parentElement).removeClass("state-selected"));
                    grid.element[0].querySelectorAll("input[type='checkbox']").forEach(cb => cb.checked = false);
                }
                this.selectOnChange();
            },
            confirmedDeleteUser: function (e) {
                const selectedIds = [window.User.Id];
                const _this = this;

                $.ajax({
                    type: 'POST',
                    url: '/Settings/ManageUsers/Delete',
                    data: JSON.stringify({
                        userIds: selectedIds
                    }),
                    contentType: "application/json",
                    dataType: 'json',
                    success: function (data) {
                        $('#useroverview-delete-user').modal('hide');

                        if (!data.success) {
                            _this.addAlert({
                                "message": data.data,
                                "isSuccess": false
                            });
                        } else {
                            _this.refreshGrid();
                        }
                    }
                });
            },
            actionbarAddUser: function (e) {
                $('#useroverview-add-user').modal('show');
            },
            actionbarAddRequestUser: function (e) {
                $('#useroverview-add-request-user').modal('show');
            },
            actionbarApprovalUsers: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                const selected = grid.dataSource.data().filter(i => i.selected);
                if (selected) {
                    this.selectedUsers = selected;
                    this.userCount = selected.length;
                    $('#useroverview-approval-users').modal('show');
                }
            },
            showEditModal: function (e) {
                $('#useroverview-edit-user').modal('show');
            },
            actionbarDeleteUser: function () {
                const grid = this.$refs.grid.kendoWidget();
                const selectedItems = grid.dataSource.data().filter(i => i.selected);
                if (selectedItems.length >= 1) {
                    $('#useroverview-delete-user').modal('show');
                } else {
                    alert('Please select one or more users\'s to delete');
                }
            },
            save: function (event) {
                this.alert = "";
                const _this = this;
            },
            gridOnDatabound: function (e) {
                const data = e.sender.dataSource.data();
                const grid = this.$refs.grid.kendoWidget();
                
                const _this = this;

                if (!_this.EditAndRemoveAllowed) {
                    return;
                }

                grid.element.on('dblclick', 'tbody tr[data-uid]',
                    function (e) {
                        e.preventDefault();
                        const user = grid.dataItem($(this).closest("tr[data-uid"));

                        _this.EditModel = {
                            ..._this.EditModel,
                            userId: user.id,
                            roleId: user.roleId,
                            status: user.status,
                            modifiedOn: user.modifiedon,
                            approvalRemark: user.approvalRemark
                        };

                        _this.$refs.edituser.show(_this.EditModel);    
                    });

                //actionview handlers:
                grid.element.on('click', '.anchor-remove',
                    function (e) {
                        e.preventDefault();
                        const user = grid.dataItem($(this).closest("tr[data-uid"));

                        window.User = {
                            Id: user.id,
                            firstName: user.name,
                            lastName: user.lastname,
                            Mail: user.email
                        };;
                        $('#useroverview-delete-user').modal('show');
                    });
                grid.element.on('click', '.anchor-edit',
                    function (e) {
                        e.preventDefault();

                        const user = grid.dataItem($(this).closest("tr[data-uid"));

                        _this.EditModel = {
                            ..._this.EditModel,
                            userId: user.id,
                            roleId: user.roleId,
                            status: user.status,
                            modifiedOn: user.modifiedon,
                            approvalRemark: user.approvalRemark
                        };

                        _this.$refs.edituser.show(_this.EditModel);                        
                    });

                this.addTooltip(grid);
            },
            selectOnChange: function () {
                const grid = this.$refs.grid.kendoWidget();
                const selected = grid.dataSource.data().filter(i => i.selected);
    
                const selectAll = document.getElementById('actionbarSelectAll');
                if (selectAll) {
                    if (selectAll.checked && selected.length < grid.tbody[0].rows.length) {
                        selectAll.checked = false;
                    }
                    
                    const approvalUsersButton = this.findActionBarButton("ab-approval-users");
                    if (approvalUsersButton != null) {
                        approvalUsersButton.enabled = selected.length >= 1 && selected.every(i => i.approveUserAllowed);
                    }
                }
            },
            findActionBarButton: function (event) {
                return this.actionbar.ItemsRight.find(f => f.event === event) ? this.actionbar.ItemsRight.find(f => f.event === event) : this.actionbar.ItemsLeft.find(f => f.event === event);
            }
        }
    });
    $('.k-header').dblclick(function () {
        if (e.offsetY > $(this).outerHeight() - 2) {
            this.width = getTextWidth(this.textContent, this.font);
            //get column number
            const colNr = this.getAttribute("data-index");
            //select column
            //add search in class k-grid-content
            const gridCols = document.getElementById("user-overview-grid").getElementsByClassName("k-grid-content")[0].querySelector("colgroup").childNodes;
            gridCols[colNr].width = $(this).outerWidth();
        }
    })

    function getTextWidth(text, font) {
        // re-use canvas object for better performance
        const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");
        context.font = font;
        const metrics = context.measureText(text);
        //add space for the filter and arroww
        const tableIcons = 80;
        const total = metrics.width + tableIcons;
        return total;
    }
    const pageSizeDropDownList = document.getElementsByClassName('k-pager-sizes')[0].querySelector("[data-role=dropdownlist]");
    pageSizeDropDownList.onchange = function () {
        sessionStorage.setItem(pagesizesession, this.value);
    };
}