import Vue from 'vue'

module.export = Vue.component('addassettypemodal',
    {
        name: 'addassettype',
        props: {
            data: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                AddModel: {
                    AssetTypeName: '',
                    MasterAssetTypeName: '',
                    DefaultFieldsToBeHidden: null,
                },
                loading: false
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function (e) {
                e.preventDefault();
                this.$validator.validateAll().then((isValid) => {
                    if (isValid) {
                        this.addAssetTypeCall();
                    }
                });
            },
            addAssetTypeCall: function () {
                const _this = this;
                _this.clearAlert();
                $("#saveButton").prop("disabled", true);
                $.ajax({
                    type: 'POST',
                    url: '/AssetTypes/Add',
                    data: JSON.stringify({
                        assetTypeName: _this.AddModel.AssetTypeName,
                        masterAssetTypeName: _this.AddModel.MasterAssetTypeName,
                    }),
                    success: function (data) {
                        if (data.success) {
                            $('#add-asset-type').modal('hide');
                            window.GeneralUtilities.loading(true);
                            _this.$emit('redirect-to-details', data.data);
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not add Asset Type: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not add Asset Type due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                this.AddModel = Object.assign({}, this.data);
                this.$validator.reset();
                $('#add-asset-type').modal('hide');

                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            }
        }
    });