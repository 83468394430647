export const ReadingTypes = function () {
    let _configuredTypes = [];

    const _load = function (types) {
        _configuredTypes = types.map((x, index) => {
            return {
                id: index,
                displayName: x.Name,
                dataType: x.UnitType.DataType,
                prefix: x.UnitType.Prefix,
                suffix: x.UnitType.Suffix
            }
        })
    }

    const _get = function (name) {
        const index = _configuredTypes.map(x => x.displayName).indexOf(name);

        if (index >= 0) {
            return _configuredTypes[index];
        }

        return {
            id: -1,
            displayName: "-",
            dataType: undefined,
            prefix: undefined,
            suffix: undefined
        };
    }

    const _getAll = function () {
        return _configuredTypes;
    }

    return {
        load: _load,
        get: _get,
        getAll: _getAll
    }
}();