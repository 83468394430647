import Vue from 'vue'

module.export = Vue.component('addoreditfieldmodal',
    {
        name: 'addoreditfield',
        props: {
            data: Object,
            fielddata: Object,
            editcontext: String
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                EditModel: Object.assign({}, this.data), //create deep a copy of the object
                field: undefined, 
                ShowFeedback: false,
                changeFile: '',
                name: null,
                fieldType: '',
                isDefault: true,
                isLegacy: false,
                isExistingField: false,
                required: false,
                viewableBy: [],
                editableBy: [],

                // constants:
                fieldTypes: ['Text', 'Numeric', 'DateTime', 'Date'],
                users: ['Customer', (window.hasBranding ? 'DLL' : 'Vendor'), 'Partner'],
                disabledInformation: window.hasBranding
                    ? 'DLL and Partner fields are disabled because they do not have access to non-DLL assets regardless.'
                    : 'Vendor and Partner fields are disabled because they do not have access to non-Leased assets regardless.',
                existingFields: []
            };
        },
        watch: {
            fielddata: function () {
                this.field = this.fielddata ? Object.assign({}, this.fielddata) : undefined;
                if (this.field) {
                    this.name = this.field.Name;
                    this.fieldType = this.field.Type;
                    this.required = this.field.Required;
                    this.viewableBy = this.field.ViewableBy;
                    this.editableBy = this.field.EditableBy;
                    this.isDefault = this.field.IsDefault;
                    this.isLegacy = this.field.IsLegacy;
                } else {
                    this.name = null;
                    this.fieldType = '';
                    this.required = false;
                    this.viewableBy = (this.EditModel.isLesseeAssetType ? ['Customer'] : []);
                    this.editableBy = (this.EditModel.isLesseeAssetType ? ['Customer'] : []);
                    this.existingFields = this.EditModel.ExistingFields;
                }
            }
        },
        mounted: function () {
            this.existingFields = this.EditModel.ExistingFields;
            if (!this.field && this.EditModel.isLesseeAssetType) {
                this.viewableBy = ['Customer'];
                this.editableBy = ['Customer'];
            }
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            validateName: function (evt) {
                if (evt && evt.target && evt.target.value) {
                    const regex = /^[\p{L}\p{N}]+( [\p{L}\p{N}]+)*$/u;
                    const value = evt.target.value;
                    if (regex.test(value)) {
                        // Input is valid
                        this.$validator.errors.remove('name');
                    } else {
                        // Input is invalid
                        this.$validator.errors.add({
                            field: 'name',
                            msg: 'Special characters are not allowed'
                          });
                    }
                }
            },
            save: function (e) {
                e.preventDefault();
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        const _this = this;
                        _this.clearAlert();
                        let postUrl;
                        let data = {
                            id: _this.EditModel.AssetTypeId,
                            name: _this.name,
                            required: _this.required,
                            type: _this.fieldType,
                            viewableBy: !_this.isDefault ? _this.viewableBy.join(",") : "<Inherit>",
                            editableBy: !_this.isDefault ? _this.editableBy.join(",") : "<Inherit>"
                        }
                        if (this.field) {
                            data.originalName = _this.fielddata.Name;
                            if (this.editcontext == 'general') {
                                postUrl = '/AssetTypes/ChangeGeneralAssetTypeField'
                            } else if (this.editcontext == 'specific') {
                                postUrl = '/AssetTypes/ChangeSpecificAssetTypeField'
                            }
                        } else {
                            postUrl = '/AssetTypes/AddAssetTypeField'
                        }
                        $("#saveButton").prop("disabled", true);
                        $.ajax({
                            type: 'POST',
                            url: postUrl,
                            data: JSON.stringify(data),
                            success: function (data) {
                                if (data.success) {
                                    /* TODO: Add logic for being unable to change field types and such
                                       if (data.data) {
                                        _this.ShowFeedback = true;
                                        // TODO: make coherent with actual file.
                                        _this.changeFile = data.data.file;
                                    } else { */
                                    _this.close();
                                    window.GeneralUtilities.loading(true);
                                    location.reload();
                                    /* } */
                                } else {
                                    _this.addAlert(
                                        {
                                            "message": `Could not save changes: ${data.data}`,
                                            "isSuccess": false
                                        });

                                    $("#saveButton").prop("disabled", false);
                                }
                            },
                            error: function (data) {
                                _this.addAlert(
                                    {
                                        "message": 'Could not save changes due to a server error',
                                        "isSuccess": false
                                    });
                            },
                            contentType: "application/json",
                            dataType: 'json'
                        });
                    }
                });
            },

            nameChanged: function(e) {
                let existingFieldType = this.existingFields.find(x => x['name'] === this.name);
                    if (existingFieldType) {
                        this.fieldType = existingFieldType.type;
                        this.editableBy = existingFieldType.editableBy.split(',');
                        this.viewableBy = existingFieldType.viewableBy.split(',');
                        this.isExistingField = true;
                        this.isDefault = true;
                    } else {
                        this.isExistingField = false;
                    }                
            },

            requiredChanged: function(e) {
                this.isDefault = false;
            },

            viewableByChanged: function(e) {
                this.isDefault = false;
            },

            editableByChanged: function(e) {
                this.isDefault = false;
            },

            reset: function (e) {
                e.preventDefault();
                const _this = this
                $.ajax({
                    type: 'POST',
                    url: '/AssetTypes/ResetAssetTypeFieldRights',
                    data: JSON.stringify({
                        id: _this.EditModel.AssetTypeId,
                        name: _this.name,
                        context: _this.editcontext
                    }),
                    success: function (data) {
                        if (data.success) {
                            window.GeneralUtilities.loading(true);
                            location.reload();
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not reset asset type field rights: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not reset asset type field rights due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            download: function () {
                window.alert(this.changeFile);
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.ShowFeedback = false;
                this.clearAlert();
                this.field = undefined;
                this.EditModel = Object.assign({}, this.data);
                this.$emit('clearfield');
                $('#edit-assettype--add-field').modal('hide');

                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            }
        }
    });