import Vue from 'vue'
import { formatDateTimeToDate } from '../Shared/date-utils';

module.export = Vue.component('viewMeterReadingModal',
    {
        name: 'viewMeterReadingModal',
        props: {
            item: Object
        },
        components: {},
        data: function () {
            return {
                currentItem: {},
            };
        },
        methods: {
            formattedDate: function (date) {
                if (!date)
                    return;

                let parsedDate = date;
                if (parsedDate.indexOf("/Date(") !== -1) {
                    parsedDate = new Date(parseInt(date.replace(/\/Date\((.*?)\)\//gi, "$1")));
                }
                return formatDateTimeToDate(parsedDate);

            },
            close: function () {
                this.currentItem = {};
                $('#asset-details--view-meter-reading').modal('hide');
            },
        },
        watch: {
            'item': function (newValue) {
                this.currentItem = newValue;
            }
        }
    });