import Vue from 'vue'

module.export = Vue.component('userrightsmodal',
    {
        name: 'userrights',
        props: {
            user: Object,
            userRights: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                User: this.user,
                UserRights: this.userRights,
                columns: [
                    { 'field': 'Selected', 'title': ' ', 'filterable': false, 'template': '<div #= Selected ? \'class="state-selected"\' : "" #><a class="visual-checkbox" href="\\#"><input type="checkbox" #= Selected ? \'checked="checked"\' : "" # /></a></div>', 'width': '40px' },
                    { 'field': 'Name', 'title': 'Rights', 'filterable': false },
                ]
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function (e) {
                e.preventDefault();
                const grid = this.$refs.grid.kendoWidget();
                const rights = grid.dataSource.data().filter(i => i.Selected).map(i => i.Id);
                const _this = this;
                _this.clearAlert();
                $("#saveButton").prop("disabled", true);
                $.ajax({
                    type: 'POST',
                    url: '/Portfolios/SavePortfolioRights?userId=' + _this.user.Id,
                    data: JSON.stringify({
                        portfolioId: window.detailModel.PortfolioId,
                        rights: rights
                    }),
                    success: function (data) {
                        if (data.success) {
                            location.reload();
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not save portfolio rights: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not save portfolio rights due to a server error',
                                "isSuccess": false
                            });
                    $("#saveButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                $('#edit-portfolio--user-rights').modal('hide');

                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            },
            setAsDefault: function () {
                const grid = this.$refs.grid.kendoWidget();
                const rights = grid.dataSource.data().filter(i => i.Selected).map(i => i.Id);
                const _this = this;
                _this.clearAlert();
                $("#saveButton").prop("disabled", true);
                $.ajax({
                    type: 'POST',
                    url: '/Portfolios/SaveDefaultPortfolioRights',
                    data: JSON.stringify({
                        portfolioId: window.detailModel.PortfolioId,
                        rights: rights
                    }),
                    success: function (data) {
                        if (data.success) {
                            location.reload();
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not set default portfolio rights: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not set default portfolio rights due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                grid.element.on('click', '.visual-checkbox',
                    function (e) {
                        e.preventDefault();
                        const cb = this.querySelector("input[type='checkbox']");
                        cb.checked = !cb.checked;
                        const right = grid.dataItem($(this).closest("tr[data-uid"));
                        right.Selected = cb.checked;
                        if (cb.checked) {
                            $(this.parentElement).addClass("state-selected");
                        } else {
                            $(this.parentElement).removeClass("state-selected");
                        }
                    });
            }
        }
    });