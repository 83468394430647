import Vue from 'vue'
import "./ToggleMeterReadingFields.less"

module.export = Vue.component('togglemeterreadingfieldsmodal',
    {
        name: 'togglemeterreadingfields',
        props: {
            data: Object,
            fielddata: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                EditModel: Object.assign({}, this.data),
                ShowError: false
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function (e) {
                e.preventDefault();
                this.clearAlert();
                const _this = this;
                if (this.EditModel.MeterReadingFieldsEnabled) {
                    $.ajax({
                        type: 'POST',
                        url: '/AssetTypes/DisableMeterReadingFields',
                        data: JSON.stringify({
                            assetTypeId: _this.EditModel.AssetTypeId
                        }),
                        success: function (data) {
                            if (data.success) {
                                _this.close();
                                location.reload();
                            } else {
                                _this.addAlert(
                                    {
                                        "message": `Could disable meter reading fields: ${data.data}`,
                                        "isSuccess": false
                                    });
                            }
                        },
                        error: function (data) {
                            _this.addAlert(
                                {
                                    "message": 'Could disable meter reading fields due to a server error',
                                    "isSuccess": false
                                });
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                } else {
                    $.ajax({
                        type: 'POST',
                        url: '/AssetTypes/EnableMeterReadingFields',
                        data: JSON.stringify({
                            assetTypeId: _this.EditModel.AssetTypeId
                        }),
                        success: function (data) {
                            if (data.success) {
                                _this.close();
                                location.reload();
                            } else {
                                _this.addAlert(
                                    {
                                        "message": `Could enable meter reading fields: ${data.data}`,
                                        "isSuccess": false
                                    });
                            }
                        },
                        error: function (data) {
                            _this.addAlert(
                                {
                                    "message": 'Could enable meter reading fields due to a server error',
                                    "isSuccess": false
                                });
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                }
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.ShowFeedback = false;
                this.clearAlert();
                this.EditModel = Object.assign({}, this.data);
                $('#edit-assettype--add-field').modal('hide');
            }
        }
    });