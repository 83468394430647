import Vue from 'vue'
import axios from "axios"
import VeeValidate from "vee-validate"
import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import "./Details.less"
import "../Grid/Grid.less"

if (document.getElementById('contract-details')) {
    require("../Alerts/Alerts.js");
    require('./Edit.js');
    require("../Documents/Contracts/Add.js");
    require("../Documents/Contracts/Edit.js");
    require("../Documents/Contracts/Delete.js");   
    Vue.use(GridInstaller);
    Vue.use(VeeValidate);

    new Vue({
        el: '#contract-details',
        components: {},
        data: function () {
            return {
                alerts: [],
                EditModel: window.detailModel,
                AddPortfoliosModel: window.detailModel.SelectPortfolios,
                dataSource: {
                    data: window.detailModel.Portfolios,
                    pageSize: 20
                },
                pageable: {
                    buttonCount: 3,
                    info: true,
                    type: 'numeric',
                    pageSizes: [10, 20, 30, 50],
                    previousNext: true
                },
                skip: 0,
                take: 20,
                columns: [],
                Documents: {
                    selectedItem: null,
                    dataSource: {
                        data: window.detailModel.Documents.Values,
                        pageSize: 10
                    },
                    pageable: {
                        buttonCount: 3,
                        info: true,
                        type: 'numeric',
                        pageSizes: [10, 20, 30, 50],
                        previousNext: true
                    },
                    skip: 0,
                    take: 20,
                    columns: [
                        { 'field': 'Country', 'type': 'text', 'title': 'Country', 'filterable': false },
                        { 'field': 'DIN', 'type': 'text', 'title': 'DIN', 'filterable': false },
                        { 'field': 'DocumentName', 'type': 'text', 'title': 'Document name', 'filterable': false },
                        { 'field': 'Timestamp', 'type': 'datetime', 'title': 'Last modified', 'filterable': false, 'template': "#= kendo.toString(kendo.parseDate(new Date(Timestamp)), 'yyyy/MM/dd HH:mm') #" },
                        { 'field': 'AddedBy', 'type': 'text', 'title': 'Added by', 'filterable': false },
                        { 'field': 'DateAdded', 'type': 'date', 'title': 'Added on', 'filterable': false, 'format': '{0:yyyy/MM/dd}' },                        {
                            'title': ' ',
                            'filterable': false,
                            'template': [
                                '<a class="anchor-view--document" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--view" title="View"></span></a>',
                                '<a class="anchor-edit--document" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--edit" title="Edit"></span></a>',
                                '<a class="anchor-remove--document" href="\\#""><span class="icon-rowaction icon-rowaction--dark icon-rowaction--trash-can" title="Delete"></span></a>'
                            ].join(''),
                            'width': '120px'
                        }
                    ]
                }
            }
        },
        created: function () {
            this.loadColumns();
        },        
        mounted: function () {
            if (getUrlParameter('action') === 'edit') {
                this.edit();
            }
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            edit: function () {
                $('#edit-contract--change-contract').modal('show');
            },
            preEditParent: function (preEditCsdmail) {
                this.EditModel.CsdEmail = preEditCsdmail;
            },
            addPortfolio: function () {
                $('#edit-contract--add-portfolio').modal('show');
            },
            addDocument: function () {
                $('#contract-details--add-document').modal('show');
            },
            loadColumns: function (e) {
                this.columns.push({ 'field': 'Name', 'title': 'Portfolio', 'filterable': false });
                let rowactions = '';
                if (window.detailModel.PortfolioDetailsUrl !== null) {
                    rowactions += '<a class="anchor-view--portfolio" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--view" title="View Portfolio"></span></a>';
                }
                else {
                    rowactions += '<a class="anchor-view--portfolio" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--locked" title="No access to Portfolio"></span></a>';
                }
                this.columns.push({ 'title': ' ', 'filterable': false, 'template': rowactions, 'width': '90px' })
            },
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                const _this = this;
                grid.element.off('click', '.anchor-view--portfolio');
                grid.element.off('click', '.anchor-remove--portfolio');
                grid.element.on('click', '.anchor-view--portfolio',
                    function (e) {
                        e.preventDefault();
                        if (window.detailModel.PortfolioDetailsUrl !== null) {
                            const portfolio = grid.dataItem($(this).closest("tr[data-uid"));
                            window.location.href = ".." + window.detailModel.PortfolioDetailsUrl + "?id=" + portfolio.Id;
                        }                        
                    });
                grid.element.on('click', '.anchor-remove--portfolio', function (e) {
                    e.preventDefault();
                    const portfolio = grid.dataItem($(this).closest("tr[data-uid"));
                    _this.clearAlert();
                    $.ajax({
                        type: 'POST',
                        url: '/Contracts/RemovePortfolio',
                        data: JSON.stringify({
                            portfolioId: portfolio.Id,
                            contractId: window.detailModel.ContractId
                        }),
                        success: function (data) {
                            if (data.success) {
                                let url = new URL(location.href);
                                url.searchParams.delete('action');
                                window.location.href = url.href;
                            } else {
                                _this.addAlert(
                                    {
                                        "message": `Could not remove portfolio: ${data.data}`,
                                        "isSuccess": false
                                    });
                            }
                        },
                        error: function (data) {
                            _this.addAlert(
                                {
                                    "message": 'Could not remove portfolio due to a server error',
                                    "isSuccess": false
                                });
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            documentChangedEvent: function (message) {
                const _this = this;

                this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });

                const grid = this.$refs.documentgrid.kendoWidget();
                const currentDatasource = grid.dataSource;

                axios.get("/Documents/GetForContract?contractId=" + window.detailModel.ContractId).then(((result) => {
                    _this.Documents.dataSource.data = result.data.Documents.Values;
                    _this.EditModel.Documents.Values = result.data.Documents.Values;

                    currentDatasource.data(result.data.Documents.Values);

                    // Re-sort using the intial storting
                    currentDatasource.sort(currentDatasource.sort());
                    grid.setDataSource(currentDatasource);
                }));
            },
            documentsGridOnDatabound: function (e) {
                const grid = this.$refs.documentgrid.kendoWidget();
                const _this = this;
                grid.element.off('click', '.anchor-view--document');
                grid.element.off('click', '.anchor-edit--document');
                grid.element.off('click', '.anchor-remove--document');

                grid.element.on('click', '.anchor-view--document',
                    function (e) {
                        e.preventDefault();
                        const document = grid.dataItem($(this).closest("tr[data-uid"));
                        window.open(document.DownloadUrl, "_blank");
                    });

                grid.element.on('click', '.anchor-edit--document',
                    function (e) {
                        e.preventDefault();
                        const document = grid.dataItem($(this).closest("tr[data-uid"));
                        _this.Documents.selectedItem = Object.assign(
                            {},
                            document);

                        $('#contract-details--edit-document').modal('show');
                    });

                grid.element.on('click', '.anchor-remove--document',
                    function (e) {
                        e.preventDefault();
                        const dataItem = grid.dataItem($(this).closest("tr[data-uid"));

                        _this.Documents.selectedItem = Object.assign({}, dataItem);

                        $('#contract-details--delete-document').modal('show');
                    });
            }            
        }
    });

    function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
}