import Vue from 'vue'
import "./DownloadQr.less"

module.export = Vue.component('downloadqrcodesmodal',
    {
        name: "downloadqrcodesmodal",
        data: function () {
            return {
                assetHistoryFilters: null,
                assetHistory: null,
                historyField: "All fields",
                detailsViewModel: window.detailModel,
                statusClass: 'asset-status disabled',
                companyName: window.preLoadedData.CompanyName
            };
        },
        methods: {
            download: function () {
                const companyName = this.companyName.replace(/ /g, "_");
                const container = $(".qr-codes-container");
                kendo.drawing.drawDOM(container, {
                    paperSize: "A4",
                    landscape: false,
                    forcePageBreak: ".page-break"
                }).then(function (group) {
                    return kendo.drawing.exportPDF(group);
                })
                .done(function (data) {
                    kendo.saveAs({
                        dataURI: data,
                        fileName: companyName + "_Assets_QR_Export_" + new Date().toJSON().slice(0, 10).replace(/-/g, '') + ".pdf"
                    });

                    container.empty();
                });

                $('#download-qr-codes-modal').modal('hide');
            },
            close: function () {
                $(".qr-codes-container").empty();
                $('#download-qr-codes-modal').modal('hide');
            }
        }
    });