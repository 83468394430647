import Vue from 'vue'
import "./PhotoSelector.less"

module.export = Vue.component('selectpreviewphotomodal',
    {
        name: 'selectpreviewphotomodal',
        props: {
            data: Object,
            visible: Boolean
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                SelectPreviewPhotoModel: Object.assign({}, this.data),
                selectedPreviewPhotoIndex: null,
                RenderImages: false,
                previousIndex: -1
            };
        },
        watch: {
            'data': function (newData) {
                this.SelectPreviewPhotoModel = Object.assign({}, newData);

                if (this.SelectPreviewPhotoModel.selectedPhoto) {
                    this.selectedPreviewPhotoIndex = this.SelectPreviewPhotoModel.availablePhotos.findIndex(x => x.Id === this.SelectPreviewPhotoModel.selectedPhoto.Id);
                    this.previousIndex = this.selectedPreviewPhotoIndex;
                }
                else {
                    this.selectedPreviewPhotoIndex = -1;
                    this.previousIndex = -1;
                }
            },
            'visible': function (isVisible) {
                this.RenderImages = isVisible;
            }
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            resetForm: function () {
                this.clearAlert();
            },
            selectPhoto: function (index) {
                this.selectedPreviewPhotoIndex = index;
            },
            validateBeforeSubmit: function (e) {
                const _this = this;
                if (this.selectedPreviewPhotoIndex >= 0) {
                    $("#saveButton").prop("disabled", true);
                    const mdl = {
                        OldPreviewAttachment: this.previousIndex >= 0 ? this.SelectPreviewPhotoModel.availablePhotos[this.previousIndex] : null,
                        NewPreviewAttachment: this.SelectPreviewPhotoModel.availablePhotos[this.selectedPreviewPhotoIndex]
                    };
                    $.ajax({
                        type: 'POST',
                        url: '/Documents/UpdatePreview',
                        data: JSON.stringify(mdl),
                        success: function (data) {
                            if (data.success) {
                                _this.$emit('preview-changed', "Preview image changed succesfully.", _this.selectedPreviewPhotoIndex);
                                _this.previousIndex = _this.selectedPreviewPhotoIndex;
                                _this.resetForm();
                                $("#select-preview-photo").modal('hide');
                            }
                            else {
                                _this.addAlert(
                                    {
                                        "message": `Could not update preview image: ${data.data}`,
                                        "isSuccess": false
                                    });
                                $("#saveButton").removeAttr("disabled");
                            }
                        },
                        error: function (data) {
                            _this.addAlert(
                                {
                                    "message": 'Could not update preview image due to a server error',
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                }
                else {
                    _this.addAlert(
                        {
                            "message": 'Select one of the photos below',
                            "isSuccess": false
                        });
                }
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                $("#select-preview-photo").modal('hide');
                this.selectedPreviewPhotoIndex = -1;
                this.SelectPreviewPhotoModel = Object.assign({}, this.data);
                // Enable open button again.
                $("#saveButton").removeAttr("disabled");
            }
        }
    });
