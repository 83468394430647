import Vue from 'vue'

module.export = Vue.component('setdefaultrightsmodal',
    {
        name: 'setdefaultrights',
        props: {
            data: Array
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                EditModel: this.data, //create deep a copy of the object
                step: 1,
                fieldName: '',
                required: false,
                viewableBy: [],
                editableBy: [],

                currentField: null,

                //grid data
                dataSource: {
                    data: [],
                },
                columns: [
                    { 'field': 'FieldName', 'title': 'Name', 'filterable': false },
                    { 'field': 'Required', 'title': 'Required', 'filterable': false },
                    { 'field': "ViewableBy.replace(/,/g, ' /')", 'title': 'Viewable by', 'filterable': false },
                    { 'field': "EditableBy.replace(/,/g, ' /')", 'title': 'Editable by', 'filterable': false }
                ],

                // constants:
                users: ['Customer', 'DLL', 'Partner']
            };
        },
        watch: {
            editableBy: {
                handler(newVal, oldVal) {
                    let difference = newVal.filter(x => !oldVal.includes(x));
                    if (difference.length > 0) {
                        if (!this.viewableBy.includes(difference[0])) {
                            this.viewableBy.push(difference[0]);
                        }
                    }

                },
                deep: true
            },
            viewableBy: {
                handler(newVal, oldVal) {
                    let difference = oldVal.filter(x => !newVal.includes(x));
                    if (difference.length > 0) {
                        if (this.editableBy.includes(difference[0])) {
                            const i = this.editableBy.indexOf(difference[0]);
                            if (i > -1) {
                                this.editableBy.splice(i, 1);
                            }
                        }
                    }

                },
                deep: true
            },
        },
        mounted: function () {
            this.dataSource = this.EditModel;
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            gridOnDatabound: function () {
                const grid = this.$refs.fieldmaintenancegrid.kendoWidget();
                const _this = this;
                grid.element.on('click', 'tbody tr[data-uid]',
                    function (e) {
                        e.preventDefault();
                        const dataItem = grid.dataItem($(this).closest("tr[data-uid"));
                        _this.currentField = dataItem;
                    });
            },
            save: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();
                let postUrl;
                const data = {
                    name: _this.fieldName,
                    required: _this.required,
                    viewableBy: _this.viewableBy.join(","),
                    editableBy: _this.editableBy.join(","),
                    isPortalDefault : true
                }
                if (this.currentField.Definition == 'General') {
                        postUrl = '/AssetTypes/ChangeGeneralAssetTypeField'
                    } else {
                        postUrl = '/AssetTypes/ChangeSpecificAssetTypeField'
                    }
                $("#saveButton").prop("disabled", true);
                $.ajax({
                    type: 'POST',
                    url: postUrl,
                    data: JSON.stringify(data),
                    success: function (data) {
                        if (data.success) {
                            _this.close();
                            window.GeneralUtilities.loading(true);
                            location.reload();
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not update default asset field values: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not update default asset field values due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            next: function () {
                if (this.currentField != null) {
                    this.step = 2;
                    this.fieldName = this.currentField.FieldName;
                    this.required = this.currentField.Required;
                    this.viewableBy = this.currentField.ViewableBy.split(',');;
                    this.editableBy = this.currentField.EditableBy.split(',');;
                }
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                const grid = this.$refs.fieldmaintenancegrid.kendoWidget();
                grid.clearSelection();
                $('#set-default-rights').modal('hide');
                this.clearAlert();
                this.currentField = null;
                this.step = 1;
                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            }
        }
    });