import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import { splitToParts } from '../Shared/request-utils'
import 'vue2-datepicker/index.css'

module.export = Vue.component('nonleasedassetsbulkedit',
    {
        name: "nonleasedassetsbulkedit",
        props: {
            selectedAssets: Array,
            assetsShouldBeExcluded: Boolean,
            assetCount: Number,
            assetFilter: Object
        },
        components: {
            DatePicker
        },
        data: function () {
            return {
                alerts: [],
                ShowConfirmation: false,
                ShowFeedback: false,
                bulkUpdateLocation: '',
                bulkUpdateNotification: '',
                bulkUpdateProgressPercentage: 0,
                visibleAssetTypeFields: [],
                Items: {
                    Assets: {},
                    AgreementNumber: { HasChanged: false },
                    AgreementLine: { HasChanged: false },
                    AssetStatusCustomer: { HasChanged: false },
                    AssetModel: { HasChanged: false },
                    CustomerReference: { HasChanged: false },
                    OrderNumber: { HasChanged: false },
                    InvoiceLocationStreet: { HasChanged: false },
                    InvoiceLocationCity: { HasChanged: false },
                    InvoiceLocationCountry: { HasChanged: false },
                    AssetLocationStreet: { HasChanged: false },
                    AssetLocationCity: { HasChanged: false },
                    AssetLocationCountry: { HasChanged: false },
                    ExtensionRent: { HasChanged: false },
                    ExtensionDate: { HasChanged: false },
                    AgreementTerminationDate: { HasChanged: false },
                    CustomerGuaranteedResidualValue: { HasChanged: false },
                    Price: { HasChanged: false },
                    Rent: { HasChanged: false },
                    StartDate: { HasChanged: false },
                    EndDate: { HasChanged: false },
                    Currency: { HasChanged: false },
                    ProductSku: { HasChanged: false },
                    ManufactureNumber: { HasChanged: false },
                    SerialNumber: { HasChanged: false },
                    Brand: { HasChanged: false },
                    ProductName: { HasChanged: false },
                    User: { HasChanged: false },
                    MainCostCenter: { HasChanged: false },
                    CostCenter: { HasChanged: false },
                    DealerContactPerson: { HasChanged: false },
                    Insurance: { HasChanged: false },
                    Note: { HasChanged: false },
                    AssetTypeFields: []
                },
                InitalItems:{}
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            validateBeforeSubmit: function () {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        const vueObject = this;
                        this.ShowConfirmation = true;
                    }
                });
            },
            hideConfirmBulkEditAsset: function () {
                const vueObject = this;
                vueObject.ShowConfirmation = false;
            },
            confirmedBulkEditAsset: function () {
                const vueObject = this;
                if (!this.assetsShouldBeExcluded) {
                    this.Items.Assets = this.selectedAssets.map((a) => ({
                        id: a.id,
                        assetTypeId: a.assetTypeId
                    }));
                    
                    $("#saveButton").prop("disabled", true);
                    const bulksize = 250;
                    const assetsInParts = splitToParts(this.Items.Assets, bulksize);

                    for (let i = 0; i < assetsInParts.length; i++) {
                        setTimeout(() => {
                            const items = JSON.parse(JSON.stringify(vueObject.Items));
                            items.Assets = items.Assets.slice(i * 250, (i + 1) * 250);
                            items.AssetTypeFields = vueObject.visibleAssetTypeFields.map(a => ({
                                Name: a.Name,
                                Value: a.Value,
                                HasChanged: a.HasChanged
                            }));
                            this.bulkUpdateNonLeasedAssets(vueObject, items).then((response) => {
                                if (response.success) {
                                    vueObject.bulkUpdateLocation = response.location;
                                    vueObject.ShowFeedback = true;
                                } else {
                                    _this.addAlert(
                                        {
                                            "message": `Could not bulk edit asset(s): ${response.data}`,
                                            "isSuccess": false
                                        });
                                    $("#saveButton").removeAttr("disabled");
                                }                                
                            },
                            (error) => {
                                _this.addAlert(
                                    {
                                        "message": 'Could not bulk edit asset(s) due to a server error',
                                        "isSuccess": false
                                    });
                                $("#saveButton").removeAttr("disabled");
                            });
                        }, 200);
                    }
                    vueObject.ShowConfirmation = false;
                    vueObject.ShowFeedback = true;
                } else {
                    vueObject.ShowConfirmation = false;
                    this.fetchPagedAssetIds().then((response) => {
                        this.builkUpdateLeasedAssetsFromResponseRecursively(vueObject, response);
                    },
                    (error) => {
                        _this.addAlert(
                            {
                                "message": 'Could not bulk edit asset(s) due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    });
                }
            },
            bulkUpdateNonLeasedAssets: function (vueObject, items) {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        type: 'POST',
                        url: '/BulkEdit/BulkUpdateNonLeasedAssets',
                        data: JSON.stringify(items),
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            fetchPagedAssetIds: function (pageToken) {
                return new Promise((resolve, reject) => { 
                    $.ajax({
                        type: 'POST',
                        url: '/NonLeasedAssets/QueryIds',
                        data: JSON.stringify(({ 
                            token: pageToken,
                            limit: 250,
                            filter: this.assetFilter
                        })),
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            builkUpdateLeasedAssetsFromResponseRecursively: function (vueObject, response) {
                if (response) {
                    vueObject.ShowFeedback = true;
                    const token = response.pageToken;
                    const items = JSON.parse(JSON.stringify(vueObject.Items));
                    items.Assets = response.items.filter(a => !this.selectedAssets.some(a2 => a2.id === a.id)).map((a) => ({
                        id: a.id,
                        assetTypeId: a.assetTypeDefinitionId
                    }));
                    items.AssetTypeFields = vueObject.visibleAssetTypeFields.map(a => ({
                        Name: a.Name,
                        Value: a.Value,
                        HasChanged: a.HasChanged
                    }));
                    this.bulkUpdateNonLeasedAssets(vueObject, items).then((bulkEditResponse) => {
                        if (bulkEditResponse.success) {
                            vueObject.bulkUpdateLocation = bulkEditResponse.location;
                        } else {
                            vueObject.ShowError = true;
                        }
                        if (token) {
                            this.fetchPagedAssetIds(token).then((response) => {
                                this.builkUpdateLeasedAssetsFromResponseRecursively(vueObject, response);
                            },
                            (error) => {
                                _this.addAlert(
                                    {
                                        "message": 'Could not bulk edit asset(s) due to a server error',
                                        "isSuccess": false
                                    });
                                $("#saveButton").removeAttr("disabled");
                            });         
                        }                        
                    },
                    (error) => {
                        _this.addAlert(
                            {
                                "message": 'Could not bulk edit asset(s) due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    });
                }
            },
            close: function () {
                this.bulkUpdateNotification = '';
                this.bulkUpdateProgressPercentage = 0; 
                this.ShowFeedback = false;
                this.clearAlert();
                this.Items = JSON.parse(JSON.stringify(this.InitalItems)); //Properties on the prototype chain and non-enumerable properties cannot be (deep)copied.

                $('#nonleased-assets-bulkedit-modal').modal('hide');

                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            },
            getSpecificAssetTypeFields: function (assetTypeIds) {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        type: 'POST',
                        url: '/BulkEdit/GetSpecificAssetTypeFields',
                        data: JSON.stringify(({
                            assetTypeIds: assetTypeIds
                        })),
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            show: function(selectedAssets) {
                const _this = this;
                this.InitalItems = JSON.parse(JSON.stringify(this.Items)); //Properties on the prototype chain and non-enumerable properties cannot be (deep)copied.
                let assetTypeIds = [...new Set(selectedAssets.filter(a => a.assetStatusDll !== 'Terminated').map(a => a.assetTypeId))];

                this.getSpecificAssetTypeFields(assetTypeIds).then((response) => {
                    this.visibleAssetTypeFields = response.map(a => ({
                        Name: a.name,
                        Type: a.type,
                        Mandatory: false,
                        Value: null,
                        HasChanged: false
                    }));
                    $('#nonleased-assets-bulkedit-modal').modal('show');
                    this.bulkUpdateNotification = '';
                    this.bulkUpdateProgressPercentage = 0;
                    const notifications = window.assetNotificationsHub;
                    notifications.on("NotifyBulkUpdateStarted", function (bulkUpdateId, total) {
                        if (_this.bulkUpdateLocation.endsWith(bulkUpdateId)) {
                            _this.bulkUpdateNotification = `Started editing ${total} assets.`;
                        }
                    });
                    notifications.on("NotifyBulkUpdateProgress", function (bulkUpdateId, progress, total) {
                        if (_this.bulkUpdateLocation.endsWith(bulkUpdateId)) {
                            _this.bulkUpdateProgressPercentage = parseInt(progress * 100 / total);
                        }
                    });
                    notifications.on("NotifyBulkUpdateCompleted", function (bulkUpdateId, total) {
                        if (_this.bulkUpdateLocation.endsWith(bulkUpdateId)) {
                            _this.bulkUpdateNotification = `${total} assets successfully edited!`;
                        }
                    });  
                });
            },
            complete: function () {
                this.$emit('refresh-data');
                this.close();
            }
        }
    });
