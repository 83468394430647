import Vue from 'vue'
import 'vue2-datepicker/index.css'
import axios from "axios"

module.export = Vue.component('assetremovalmodal',
    {
        name: "assetremovalmodal",
        props: {
            selectedAssets: Array,
            assetCount: Number,
            assetsToBeRemovedCount: Number,
            assetsBackOfficeCount: Number,
            assetsBackOfficeIds: Array,
            assetsShouldBeExcluded: Boolean,
            assetFilter: Object
        },
        data: function () {
            return {
                alerts: []
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function () {
                this.clearAlert();
                let vueObject = this;
                $("#saveButton").prop("disabled", true);
                if (!this.assetsShouldBeExcluded) {
                    let selectedContractIds = this.selectedAssets.filter(i => i.assetReferences === null).map(i => i.contractId);
                    let selectedAssetIds = this.selectedAssets.filter(i => i.assetReferences === null).map(i => i.id);
                    this.removeAssets(selectedContractIds, selectedAssetIds).then((response) => {
                        if (!response.success) {
                            vueObject.addAlert({
                                "message": response.message,
                                "isSuccess": false
                            });
                        } else {
                            this.close();
                            this.$emit('alert', { "message": 'Asset(s) successfully removed', "isSuccess": true, "showPopup": true });
                        }
                    },
                    (error) => {
                        vueObject.addAlert({
                            "message": error.message,
                            "isSuccess": false
                        });
                    });
                } else {
                    this.fetchPagedAssetIds().then((response) => {
                        this.removeAssetsFromResponseRecursively(vueObject, response);
                    },
                    (error) => {
                        vueObject.ShowError = true;
                        vueObject.addAlert({
                            "message": error.message,
                            "isSuccess": false
                        });
                    });
                }
            },

            removeAssets: function (selectedContractIds, selectedAssetIds) {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        url: "/LeasedAssets/Remove",
                        data: JSON.stringify({ contracts: selectedContractIds, assetIds: selectedAssetIds }),
                        type: "POST",
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            download: function () {
                axios.get('/LeasedAssets/GetBackOfficeAssetsExcelFile?backOfficeAssetsIds=' + this.assetsBackOfficeIds.join(','), { responseType: 'blob' })
                    .then(response => {
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(response.data);
                        link.download = 'Back-Office-Assets-To-Be-Removed_' + new Date().toISOString() + '.xlsx';
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }).catch(console.error);
            },
            finish: function () {
                this.$emit('refresh');
                this.close();
            },
            close: function () {
                this.clearAlert();
                $('#asset-removal-modal').modal('hide');
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            show: function() {
                $('#asset-removal-modal').modal('show');
            }
        }
    });
