import Vue from 'vue'
import VeeValidate from "vee-validate"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { ReadingTypes } from './ReadingTypes.js'
import axios from "axios"

if (document.getElementById('add-meter-reading-mobile')) {
    Vue.use(VeeValidate);

    new Vue({
        el: '#add-meter-reading-mobile',
        components: {
            DatePicker
        },
        data: function () {
            return {
                relatedAsset: window.detailModel,
                lastReadingValue: "-",
                formData: new FormData(),
                newItem: {
                    date: new Date().toISOString().split('T')[0],
                    type: null,
                    value: null,
                    image: null
                },
                types: ReadingTypes.getAll().sort(x => x.displayName),
                selectedImage: null,
                isSaved: false,
                error: null,
                sourceSystem: window.detailModel.MeterReadings.CurrentSourceSystem
            };
        },
        mounted: function () {
            ReadingTypes.load(window.detailModel.MeterReadings.Attributes);
            this.types = ReadingTypes.getAll().sort(x => x.displayName);

            if (this.relatedAsset.MeterReadings.Values.length > 0) {
                const sortedReadings = this.relatedAsset.MeterReadings.Values.sort((a, b) => {
                    return new Date(b.Date) - new Date(a.Date);
                });
                const readingValue = sortedReadings[0];

                if (readingValue) {
                    let formattedReadingValue = "";
                    const readingType = ReadingTypes.get(readingValue.Type);

                    if (readingType.prefix && readingType.prefix.length > 0)
                        formattedReadingValue = readingType.prefix + " ";

                    formattedReadingValue += readingValue.Value;

                    if (readingType.suffix && readingType.suffix.length > 0)
                        formattedReadingValue += " " + readingType.suffix;

                    this.lastReadingValue = formattedReadingValue;
                }
            }
        },
        methods: {
            validateBeforeSubmit: function (event) {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        $("#saveButton").prop("disabled", true);

                        this.formData.set("assetId", this.relatedAsset.Id);
                        this.formData.set("type", this.newItem.type);
                        this.formData.set("date", this.newItem.date);
                        this.formData.set("value", this.newItem.value);
                        this.formData.set("sourceSystem", this.sourceSystem);

                        axios.post('/MeterReadings/Add',
                            this.formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }).then(() => {
                                this.isSaved = true;
                                this.newItem = {
                                    date: null,
                                    type: null,
                                    value: null,
                                    image: null
                                };
                                this.lastReadingValue = "-";
                                this.formData = new FormData();
                                this.error = null;
                            }).catch(error => {
                                $("#saveButton").prop("disabled", false)
                                console.error(error);
                                this.isSaved = false;
                                this.error = JSON.stringify(error);
                            });
                        return;
                    }
                });
            },
            imageChanged: function (e) {
                if (e.target.files.length === 1) {
                    const selectedFile = e.target.files[0];
                    this.selectedImage = selectedFile;

                    this.formData.set("file", this.selectedImage, this.selectedImage.name)
                }
            }
        }
    });
}