import Vue from 'vue'
if (document.getElementById('dropdown-user')) {

    let timer = 0;

    new Vue({
        el: '#dropdown-user',
        components: {},
        data: function () {
            return {
                topViewModel: {
                    activeAccountId: window.topViewModel.activeAccountId,
                    accounts: window.topViewModel.accounts
                },
                accountOptions: [...topViewModel.accounts],
                openPanel: false
            }
        },   
        methods: {
            filterAccount: function(event){
                window.clearTimeout (timer);
                timer = window.setTimeout(() => {
                    const matchingAccounts = this.topViewModel.accounts.filter(account => account.Name.toLowerCase().includes(event.target.value.toLowerCase()))
                    this.accountOptions = matchingAccounts;
                    this.openPanel = true;
                }, 250);                
            }
        }
    });    
}
