import Vue from 'vue'
import VeeValidate from "vee-validate"
import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'

if (document.getElementById('vue-contracts-overview')) {
    const actionbar = require("../Actionbar/Actionbar.js");
    const alerts = require("../Alerts/Alerts.js");
    require("./Add.js");
    require("./Edit.js");

    Vue.use(GridInstaller);
    Vue.use(VeeValidate);

    new Vue({
        el: '#vue-contracts-overview',
        components: {},
        data: function () {
            return {
                GridConfiguration: window.preLoadedData.GridConfiguration,
                HasBranding: window.hasBranding,
                AddModel: {
                    ContractName: '',
                    CsdEmail: '',
                    Type: (this.HasBranding ? "DLL" : "Leased")
                },
                dataSource: {
                    data: [],
                    transport: {
                        read: {
                            url: "/Contracts/List",
                            contentType: "application/json",
                            type: "POST"
                        },
                        parameterMap: function (options) {
                            return kendo.stringify(options);
                        }
                    },
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    pageSize: 20,
                    schema: {
                        data: "data",
                        total: "total"
                    }
                },
                pageable: {
                    buttonCount: 3,
                    info: true,
                    type: 'numeric',
                    pageSizes: [10, 20, 30, 50],
                    previousNext: true
                },
                columns: [
                    { 'field': 'selected', 'title': ' ', 'filterable': false, 'template': '<div #= selected ?\'class="state-selected"\' : "" #><a class="visual-checkbox" href="\\#"><input type="checkbox" #= selected ? \'checked="checked"\' : "" # /></a></div>', 'width': '40px' },
                    { 'field': 'contractName', 'title': 'Contract', 'filterable': false },
                    { 'field': 'backOffice', 'title': 'Source System', 'filterable': false, 'template': "<span class='single-line'>#: backOffice == null ? ' ' : backOffice #</span>" },
                    { 'field': 'lessee', 'title': 'Lesseee', 'filterable': false, 'template': "<span class='single-line'>#: lessee == null ? ' ' : lessee #</span>" },
                    { 'field': 'portfolios', 'title': 'Portfolios', 'filterable': false, 'template': '<span class="single-line">#: portfolios #</span>' },
                    { 'field': 'csdEmail', 'title': 'CSD email', 'filterable': false, 'template': "<span class='single-line'>#: csdEmail == null ? ' ' : csdEmail #</span>" },
                    { 'field': 'type', 'title': 'Type', 'filterable': false, 'template': '<span class="single-line">#: type #</span>' },
                    { 'title': ' ', 'filterable': false, 'template': '<a class="anchor-view" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--view" title="View"></span></a><a class="anchor-edit" href="\\#"">', 'width': '60px' }
                ],
                actionbar: window.preLoadedData.Actionbar,
                search: {
                    visible: true
                },
                alerts: []
            };
        }, 
        created: function () {
            this.actionbar.searchVisible = true;

            const deleteContractButton = this.actionbar.ItemsRight.find(f => f.event === "ab-delete-contracts") ? this.actionbar.ItemsRight.find(f => f.event === "ab-delete-contracts") : this.actionbar.ItemsLeft.find(f => f.event === "ab-delete-contracts");
            if (deleteContractButton != null) {
                deleteContractButton.visible = window.preLoadedData.rights.deleteContract;
            }

            const addContractButton = this.actionbar.ItemsRight.find(f => f.event === "ab-add-contract") ? this.actionbar.ItemsRight.find(f => f.event === "ab-add-contract") : this.actionbar.ItemsLeft.find(f => f.event === "ab-add-contract");
            if (addContractButton != null) {
                addContractButton.visible = window.preLoadedData.rights.addContract;
            }
        },
        mounted: function () {
            const grid = this.$refs.grid.kendoWidget();
            const _this = this;
            grid.element.on('click', '.visual-checkbox',
                function (e) {
                    e.preventDefault();
                    const cb = this.querySelector("input[type='checkbox']");
                    cb.checked = !cb.checked;
                    const dataItem = grid.dataItem($(this).closest("tr[data-uid"));
                    if (cb.checked) {
                        $(this.parentElement).addClass("state-selected");
                    } else {
                        $(this.parentElement).removeClass("state-selected");
                    }
                    dataItem.selected = cb.checked;

                    _this.selectOnChange();
                });
        },
        methods: {
            refreshGrid: function () {
                const grid = this.$refs.grid.kendoWidget();
                grid.dataSource.read();
            },
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            addTooltip: function addTooltipToTableHeaders(grid) {
                grid.element[0].querySelectorAll(".k-header").forEach(element => {
                    const title = $(element).data("title");

                    if (!/<[a-z\][\s\S]*>/i.test(title)) {

                        $(element).attr('title', title);
                    }
                });
            },
            actionbarSelectAll: function (checked) {
                const grid = this.$refs.grid.kendoWidget();
                if (checked) {
                    grid.dataSource.data().forEach(i => i.selected = true);
                    grid.element[0].querySelectorAll(".visual-checkbox").forEach(cb => $(cb.parentElement).addClass("state-selected"));
                    grid.element[0].querySelectorAll("input[type='checkbox']").forEach(cb => cb.checked = true);
                } else {
                    grid.dataSource.data().forEach(i => i.selected = false);
                    grid.element[0].querySelectorAll(".visual-checkbox").forEach(cb => $(cb.parentElement).removeClass("state-selected"));
                    grid.element[0].querySelectorAll("input[type='checkbox']").forEach(cb => cb.checked = false);
                }
                this.selectOnChange();
            },
            actionbarAddContract: function () {
                const addContract = this.$refs.addcontract;
                addContract.TypesDisabled = window.preLoadedData.HasDefaultContract;
                $('#add-contract--add-contract').modal('show');
            },
            actionbarDeleteContracts: function () {
                const grid = this.$refs.grid.kendoWidget();
                const selectedItems = grid.dataSource.data().filter(i => i.selected);
                if (selectedItems.length >= 1) {
                    $('#delete-contract--confirm').modal('show');
                } else {
                    alert('Please select one or more contracts to delete');
                }
            },
            confirmedDeleteContract: function () {
                const grid = this.$refs.grid.kendoWidget();
                const selectedItems = grid.dataSource.data().filter(i => i.selected).map(i => i.id);
                const _this = this;
                $.ajax({
                    type: "POST",
                    url: "/Contracts/Delete",
                    data: JSON.stringify({
                        contractIds: selectedItems
                    }),
                    success: function (data) {
                        $("#delete-contract--confirm").modal('hide');

                        if (!data.success) {
                            _this.addAlert({
                                "message": data.data,
                                "isSuccess": false
                            });
                        } else {
                            _this.refreshGrid();
                        }
                    },
                    error: function (data) {
                        _this.addAlert({
                            "message": "Could not delete contract(\'s)!",
                            "isSuccess": false
                        });
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            actionbarSearch: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                let filter = {};
                if (e !== null && e !== '') {
                    filter = { logic: 'or', filters: [] };
                    grid.columns.forEach(function (x) {
                        if (x.field) {
                            filter.filters.push({
                                field: x.field,
                                operator: 'contains',
                                value: e.trim()
                            });
                        }
                    });
                }
                grid.dataSource.filter(filter);
            },
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                grid.element.unbind('dblclick');

                grid.element.on('dblclick', 'tbody tr[data-uid]',
                    function (e) {
                        const selectedItems = grid.select().toArray().map(function (s) { return grid.dataItem(s).id; });
                        if (selectedItems.length === 1) {
                            window.location.href = "./Contracts/Details?id=" + selectedItems[0];
                        }
                    });

                grid.element.on('click', '.anchor-view',
                    function (e) {
                        e.preventDefault();
                        const selectedItems = grid.select().toArray().map(function (s) { return grid.dataItem(s).id; });
                        if (selectedItems.length === 1) {
                            window.location.href = "./Contracts/Details?id=" + selectedItems[0];
                        }
                    });

                grid.element.on('click', '.anchor-edit',
                    function (e) {
                        e.preventDefault();
                        const selectedItems = grid.select().toArray().map(function (s) { return grid.dataItem(s).id; });
                        if (selectedItems.length === 1) {
                            window.location.href = "./Contracts/Details?id=" + selectedItems[0] + "&action=edit";
                        }
                    });

                this.addTooltip(grid);
            },
            selectOnChange: function () {
                const grid = this.$refs.grid.kendoWidget();
                const selected = grid.dataSource.data().filter(i => i.selected);

                const selectAll = document.getElementById('actionbarSelectAll');
                if (selectAll.checked && selected.length < grid.tbody[0].rows.length) {
                    selectAll.checked = false;
                }
            }
        }
    });
    $('.k-header').dblclick(function () {
        if (e.offsetY > $(this).outerHeight() - 2) {
            this.width = getTextWidth(this.textContent, this.font);
            //get column number
            const colNr = this.getAttribute("data-index");
            //select column
            //add search in class k-grid-content
            let gridCols = document.getElementById("contracts-grid").getElementsByClassName("k-grid-content")[0].querySelector("colgroup").childNodes;
            gridCols[colNr].width = $(this).outerWidth();
        }
    })
}