import Vue from 'vue'
import VeeValidate from "vee-validate"
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import vSelect from 'vue-select'
import "../Grid/Grid.less"
import { fetchJson } from '../Shared/request-utils'
import { generateQrCodes } from '../Shared/qr-utils'
import { ReadingTypes } from '../MeterReadings/ReadingTypes.js'
import { getSubMemberName} from '../Shared/grid-utils'

if (document.getElementById('vue-leased-assets-overview')) {
    require("../Grid/GridConfiguration.js");
    require("../Actionbar/Actionbar.js");
    require("../Alerts/Alerts.js");
    require("./Termination.js");
    require("./Request.js");
    require("./Remove.js");
    require("./Upload.js");
    require("./Add.js");
    require("../ManagePreviews/ManagePreviews.js");
    require("../MeterReadings/ReadingTypes.js");
    require("../MeterReadings/Add.js");
    require("../Documents/Assets/Add.js");
    require("./BulkEdit.js");
    require("../DownloadQr/DownloadQr.js");
    require("../QueryableGrid/Grid.js");

    Vue.use(GridInstaller);
    Vue.use(VeeValidate);

    Vue.component('v-select', vSelect);
    const pagesizesession = "pagesize";
    let pagesize = sessionStorage.getItem(pagesizesession);
    if (!pagesize) {
        pagesize = 20;        
    }

    let gridConfigChangeTimer = 0;    

    new Vue({
        el: '#vue-leased-assets-overview',
        components: {},
        data: function () {
            return {
                actionbar: window.preLoadedData.Actionbar,
                GridConfiguration: window.preLoadedData.GridConfiguration,
                Grid: {
                    config: window.preLoadedData.GridConfiguration,
                    endpointQuery: "/LeasedAssets/Query",
                    endpointFilterValues: "/LeasedAssets/FetchFilterValues",
                    enableMultiSelect: true,
                    renderFieldValues: this.getCustomFieldValues,
                    renderRowActions: this.getCustomActions,
                    rowClickEvent: this.onRowClicked,
                    rowActionClickEvent: this.onRowActionClicked,
                    rowSelectedEvent: this.onRowSelectionChanged,
                    gridChangedEvent: this.onGridChanged,
                    gridFilteredEvent: this.onGridFiltered,
                    gridColumnReorderEvent: this.onGridColumnReordered,
                    pageSizes: {
                        default: 20,
                        values: [10, 20, 30, 50]
                    },
                    selectAll: undefined,
                    search: undefined,
                    globalSelection: undefined,
                    selectedCount: undefined,
                    totalCount: undefined,
                    export: undefined,
                    customFilter: undefined,
                    refreshLayout: undefined,
                    refreshData: undefined,
                    refreshCurrentPage: undefined,
                    setActionbar: undefined,
                    setActiveFilters: undefined,
                    setActiveSorting: undefined,
                    setPortfolioSelector: undefined
                },
                alerts: [],
                selectedAssets: [],
                assetCount: 0,
                assetsShouldBeExcluded: false,
                assetFilter: {},
                assetSort: {},
                assetsTerminatedCount: 0,
                assetsBackOfficeCount: 0,
                assetsBackOfficeIds: [],
                assetsToBeTerminatedCount: 0,
                assetsToBeRemovedCount: 0,
                activePortfolioFilterField: '',
                HasBranding: window.hasBranding,
                AssetRequest: {
                    RequestDate: null,
                    AdditionalInformation: '',
                    FirstName: '',
                    LastName: '',
                    Email: '',
                    PhoneNumber: '',
                    AssetsToTerminate: [],
                    AssetCount: 0,
                    AssetsShouldBeExcluded: false,
                    AssetFilter: {},
                },
                PartialTermination: {
                    TerminationDate: new Date(),
                    AssetsToTerminate: [],
                    AssetCount: 0,
                    AssetsShouldBeExcluded: false,
                    AssetFilter: {},
                },
                MeterReadings: {
                    readingTypes: [],
                    CurrentSourceSystem: ''
                },
                detailsViewModel: null,
                meterReadingsModalActive: false,
                allowedPortfolios: window.preLoadedData.AllowedPortfolios,
                gridPreviews: [],
                selectedPreview: undefined,
                AddModel: {
                    types: [],
                    contractIdentifiers: [],
                    allowedMeterUnitTypes: []
                },
                UploadModel: {
                    contractIdentifiers: []
                },
            };
        },
        mounted: function () {
            const _this = this;
            _this.Grid.setActionbar(_this.$refs.actionbar);
            this.fetchGridPreviews();
            const portfolios = _this.allowedPortfolios.map(portfolio => portfolio.Name);
            kendo.jQuery("#portfolioSelector").kendoMultiSelect({
                dataSource: portfolios,
                filter: "contains",
                tagTemplate: "#:values.length# portfolio(s) selected",
                placeholder: "Select portfolio(s)",
                autoClose: false,
                tagMode: "single",
                noDataTemplate: "No portfolios available",
                itemTemplate: `<div class="checkbox">
                        #= data #
                        <input type="checkbox" name="checkbox" value="#= data #"/>
                        <span class="checkmark"></span>
                    </div>`,
                change: function () {
                    const selected = this.value();
                    const items = this.listView.content.find("li");
                    items.each(function () {
                        const checkbox = $(this).find("input[type='checkbox']");
                        const value = checkbox.val();
                        checkbox.prop("checked", selected.indexOf(value) > -1);
                    });

                    _this.actionbarFilterPortfolio(selected);
                    if (selected.length == 0) {
                        this.tagList.children().each(function() {
                            this.remove();
                        });
                    }
                },
                open: function () {
                    const selected = this.value();
                    const items = this.listView.content.find("li");
                    items.each(function () {
                        const checkbox = $(this).find("input[type='checkbox']");
                        const value = checkbox.val();
                        checkbox.prop("checked", selected.indexOf(value) > -1);
                    });
                }
            });
            const portfolioSelector = kendo.jQuery("#portfolioSelector").getKendoMultiSelect();
            if (portfolioSelector) {
                _this.Grid.setPortfolioSelector(portfolioSelector);
            }

            $(".navbar-filter .k-multiselect").append("<span class='glyphicon glyphicon-chevron-down'></span>");
            const connection = window.assetNotificationsHub;
            connection.on("NotifyAdded", function () {
                _this.Grid.refreshCurrentPage();
            });
            connection.on("NotifyBulkUpdateCompleted", function () {
                _this.Grid.refreshCurrentPage(true);
            });
            connection.on("NotifyTerminationCompleted", function () {
                _this.Grid.refreshCurrentPage(true);
            });
            connection.on("NotifyBulkDeleteCompleted", function () {
                _this.Grid.refreshCurrentPage(true);
            });
            connection.on("NotifyUploadCompleted", function () {
                _this.Grid.refreshCurrentPage(true);
            });
        },
        created: function () {
            this.actionbar.searchVisible = true;
            this.actionbar.previewSelectorVisible = true;

            if (this.GridConfiguration.SelectedColumns.some(column => column.PropertyName === "portfolio")) {
                this.actionbar.portfolioFilterVisible = true;
            }

            //set extra rights
            const addAssetButton = this.actionbar.ItemsRight.find(f => f.event === "ab-add-asset") ? this.actionbar.ItemsRight.find(f => f.event === "ab-add-asset") : this.actionbar.ItemsLeft.find(f => f.event === "ab-add-asset");
            if (addAssetButton != null) {
                addAssetButton.visible = window.preLoadedData.rights.addAssetAllowed;
            }
            const uploadAssetsutton = this.actionbar.ItemsRight.find(f => f.event === "ab-upload-assets") ? this.actionbar.ItemsRight.find(f => f.event === "ab-upload-assets") : this.actionbar.ItemsLeft.find(f => f.event === "ab-upload-assets");
            if (uploadAssetsutton != null) {
                uploadAssetsutton.visible = window.preLoadedData.rights.assetUploadAllowed;
            }            
            const uploadStatusButton = this.actionbar.ItemsRight.find(f => f.event === "ab-upload-status") ? this.actionbar.ItemsRight.find(f => f.event === "ab-upload-status") : this.actionbar.ItemsLeft.find(f => f.event === "ab-upload-status");
            if (uploadStatusButton != null) {
                uploadStatusButton.visible = window.preLoadedData.rights.assetUploadAllowed;
            }
            const generateQrButton = this.actionbar.ItemsRight.find(f => f.event === "ab-generate-qr-code") ? this.actionbar.ItemsRight.find(f => f.event === "ab-generate-qr-code") : this.actionbar.ItemsLeft.find(f => f.event === "ab-generate-qr-code");
            if (generateQrButton != null) {
                generateQrButton.visible = window.preLoadedData.rights.generateQrCodesAllowed;
            }
        },
        methods: {
            /**
             * ActionBar Events
             */
            actionbarChangeColumns: function (e) {
                if (this.selectedPreview){
                    this.GridConfiguration.refreshColumns(this.selectedPreview.selectedColumns);
                }
                
                $('#grid-configuration-modal').modal('show');
            },
            actionbarSelectAll: function (e) {
                if (this.Grid.selectAll) {
                    this.Grid.selectAll();
                }
            },
            actionbarFilterPortfolio: function (selected) {
                const portfolios = this.allowedPortfolios.filter(portfolio => selected.includes(portfolio.Name));
                if (portfolios.length > 0) {
                    const connectionsPerField = [];
                    portfolios.forEach(portfolio => {
                        const connectionSet = connectionsPerField.find(connectionSet => connectionSet.fieldName === portfolio.FilterField);
                        if (connectionSet) {
                            portfolio.Connections.split(",").forEach(connection => {
                                if (connectionSet.connectionValues.indexOf(connection) === -1) {
                                    connectionSet.connectionValues.push(connection);
                                }
                            });
                        } else {
                            connectionsPerField.push({
                                fieldName: portfolio.FilterField,
                                connectionValues: portfolio.Connections.split(",")
                            });
                        }
                    });

                    const filters = connectionsPerField.map(connection => {
                        let filter = {};
                        filter["field"] = connection.fieldName;
                        filter["operator"] = "eq";
                        filter["value"] = connection.connectionValues;

                        return filter;
                    });

                    const customFilter = {};
                    customFilter["logic"] = "or";
                    customFilter["filters"] = filters;

                    this.Grid.customFilter(customFilter);
                } else {
                    const customFilter = {};
                    customFilter["logic"] = "and";
                    customFilter["filters"] = [];

                    this.Grid.customFilter(customFilter);
                }
            },
            actionbarSearch: function (e) {
                if (this.Grid.search) {
                    this.Grid.search(e);
                }
            },
            actionbarExport: function (e) {
                if (this.Grid.export) {
                    this.Grid.export();
                }
            },
            actionbarFullTermination: function (e) {
                if (this.prepareBulkData()) {
                    if (!this.assetsShouldBeExcluded) {
                        this.assetsTerminatedCount = this.selectedAssets.filter(i => i.assetStatusDll == "Terminated").length;
                        let assetsBackOffice = this.selectedAssets.filter(i => i.assetReferences != null);
                        this.assetsBackOfficeCount = assetsBackOffice.length;
                        this.assetsBackOfficeIds = assetsBackOffice.map(i => i.id);
                        this.assetsToBeTerminatedCount = this.selectedAssets.length - this.assetsBackOfficeCount - this.assetsTerminatedCount;
                        this.$refs.assettermination.show();
                    } else {
                        this.fetchTerminationData().then((response) => {
                            const selectedAssets = response.items.filter(a => !this.selectedAssets.some(a2 => a2.id === a.id)).map((a) => ({
                                id: a.id,
                                contractId: a.contractId,
                                status: a.status,
                                isBackofficeAsset: a.isBackofficeAsset
                            }));
                            this.assetsTerminatedCount = selectedAssets.filter(i => i.status == "Terminated").length;
                            let assetsBackOffice = selectedAssets.filter(i => i.isBackofficeAsset);
                            this.assetsBackOfficeCount = assetsBackOffice.length;
                            this.assetsBackOfficeIds = assetsBackOffice.map(i => i.id);
                            this.assetsToBeTerminatedCount = selectedAssets.length - this.assetsBackOfficeCount - this.assetsTerminatedCount;
                            this.$refs.assettermination.show();
                        });
                    }
                }
            },
            actionbarAssetRequest: function (e) {
                const _this = this;
                if (this.prepareBulkData()) {
                    $.ajax({
                        type: 'GET',
                        url: '/AssetRequest/GetPersonalSettings',
                        success: function (response) {
                            const personalSettings = Object.assign({}, response);
                            const selectedContractIds = _this.selectedAssets.map(i => i.contractId);
                            const assets = _this.selectedAssets.map(function (s) {
                                return {
                                    "assetId": s.id,
                                    "serialNumber": s.serialNumber,
                                    "contractId": s.contractId,
                                    "agreement": s.agreementNumber,
                                    "agreementLine": s.contractLine,
                                    "brand": s.brand,
                                    "model": s.assetName
                                };
                            });
                            _this.AssetRequest.FirstName = personalSettings.firstName;
                            _this.AssetRequest.LastName = personalSettings.lastName;
                            _this.AssetRequest.Email = personalSettings.email;
                            _this.AssetRequest.PhoneNumber = personalSettings.phoneNumber;
                            _this.AssetRequest.AssetsToTerminate = {
                                "contractId": selectedContractIds[0],
                                "assets": assets,
                            };
                            $("#asset-request-modal").modal('show');
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                }
            },
            actionbarRemoveAsset: function (e) {
                if (this.prepareBulkData()) {
                    let assetsBackOffice = this.selectedAssets.filter(i => i.assetReferences != null);
                    this.assetsBackOfficeCount = assetsBackOffice.length;
                    this.assetsBackOfficeIds = assetsBackOffice.map(i => i.id);
                    this.assetsToBeRemovedCount = this.selectedAssets.length - this.assetsBackOfficeCount;
                    this.$refs.assetremoval.show();
                }
            },
            actionbarGenerateQrCode: function (e) {
                const vueObject = this;
                if (this.prepareBulkData()) {
                    if (!this.assetsShouldBeExcluded) {
                        generateQrCodes(this.selectedAssets, "AddForLeasedAsset");
                        $("#download-qr-codes-modal").modal('show');
                    } else {
                        this.fetchPagedQrData().then((response) => {
                            generateQrCodes(response.items, "AddForLeasedAsset");
                            $("#download-qr-codes-modal").modal('show');
                        },
                            (error) => {
                                vueObject.ShowError = true;
                                vueObject.addAlert({
                                    "message": error.message,
                                    "isSuccess": false
                                });
                            });
                    }
                }
            },
            actionbarUploadStatus: function (e) {
                window.location.href = "./UploadStatus";
            },
            actionbarUploadAssets: function (e) {
                const _this = this;
                this.clearAlert();

                $.ajax({
                    type: 'GET',
                    url: '/LeasedAssets/Upload',
                    success: function (response) {
                        _this.UploadModel = Object.assign({}, response)
                        _this.$refs.leasedassetsupload.show(_this.UploadModel);

                        // Disable focusin event listeners on Bootstrap modal to enable Kendo DropDownList filter
                        $("#leased-assets-upload-modal").on('shown.bs.modal', function (e) {
                            $(document).off('focusin.modal');
                        });
                    },
                    error: function (error) {
                        reject(error);
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });

            },
            actionbarAddAsset: function (e) {
                const _this = this;
                _this.clearAlert();

                $.ajax({
                    type: 'GET',
                    url: '/LeasedAssets/Add',
                    success: function (response) {
                        _this.AddModel = Object.assign({}, response)
                        _this.$refs.leasedassetadd.show();

                        // Disable focusin event listeners on Bootstrap modal to enable Kendo DropDownList filter
                        $("#leased-asset-add-modal").on('shown.bs.modal', function (e) {
                            $(document).off('focusin.modal');
                        });
                    },
                    error: function (error) {
                        reject(error);
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            actionbarBulkEdit: function (e) {
                if (this.prepareBulkData()) {
                    this.$refs.leasedassetsbulkedit.show(this.selectedAssets);
                }
            },
            actionbarManagePreviews: function (e) {
                this.$refs.managepreviews.show(this.gridPreviews, "LeasedAssets");
            },
            prepareBulkData: function () {
                if (this.Grid.globalSelection) {
                    const globalSelection = this.Grid.globalSelection();
                    this.selectedAssets = globalSelection.items;
                    this.assetCount = this.Grid.selectedCount();
                    this.assetsShouldBeExcluded = globalSelection.selectAll;
                    this.assetFilter = globalSelection.filter;
                    this.assetSort = globalSelection.sort;
                    return true;
                }
                return false;
            },
            fetchTerminationData: function () {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        type: 'POST',
                        url: '/LeasedAssets/QueryTerminationData',
                        data: JSON.stringify(({
                            limit: this.assetCount,
                            filter: this.assetFilter
                        })),
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            fetchPagedAssetIds: function (pageToken) {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        type: 'POST',
                        url: '/LeasedAssets/QueryIds',
                        data: JSON.stringify(({
                            token: pageToken,
                            limit: 250,
                            filter: this.assetFilter,
                            sort: this.assetSort
                        })),
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            fetchPagedQrData: function () {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        type: 'POST',
                        url: '/LeasedAssets/QueryQrData',
                        data: JSON.stringify(({
                            limit: this.limit,
                            filter: this.assetFilter,
                            sort: this.assetSort
                        })),
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            /**
            * Grid Events
            */
            loadColumns: function () {
                // this is not implemented here, the new QueryableGrid component handles this
                // this function is only here because the GridConfiguration component references this and we want to be backwards compatible
            },
            getCustomFieldValues: function (gridColumns) {
                const modifiedColumns = Object.assign([], gridColumns)
                for (let column of modifiedColumns) {
                    if (column.field === "portfolio") {
                        column.filterable = false;
                        column.sortable = false;
                    }
                }

                const fieldColumnsWithEmptyMessage = modifiedColumns.filter(x => typeof x.field !== "undefined" && ((x.field.startsWith("computedFields.")) || (x.field.startsWith("assetTypeFields["))));
                if (fieldColumnsWithEmptyMessage.length > 0) {
                    fieldColumnsWithEmptyMessage.forEach((potentialEmptyField) => {
                        const fieldIndex = modifiedColumns.findIndex(x => x.field === potentialEmptyField.field);
                        if (fieldIndex >= 0) {
                            modifiedColumns[fieldIndex].template = function (dataItem) {

                                if (potentialEmptyField.field.startsWith("computedFields")) {
                                    const computedFieldName = getSubMemberName(potentialEmptyField.field);
                                    const computedFields = dataItem["computedFields"];

                                    if (potentialEmptyField.type === 'number') {
                                        const numericValue = computedFields[computedFieldName];
                                        if (!numericValue) {
                                            return '<a class="anchor-info" href="\\#"><span class="icon-rowaction icon-rowaction-small icon-rowaction--dark icon-rowaction--info icon-rowaction--warning" title="Field could not be computed because one of the values is missing."></span></a>';
                                        }

                                        return computedFields[computedFieldName];
                                    }
                                } else if (potentialEmptyField.field.startsWith("assetTypeFields[")) {
                                    const assetTypeFieldName = getSubMemberName(potentialEmptyField.field);
                                    const assetTypeFields = dataItem["assetTypeFields"];  

                                    if (assetTypeFields[assetTypeFieldName] === undefined || assetTypeFields[assetTypeFieldName] === null) {
                                        return '<a class="anchor-info" href="\\#"><span class="icon-rowaction icon-rowaction-small icon-rowaction--dark icon-rowaction--info icon-rowaction--warning" title="This field is unavailable for this asset."></span></a>';
                                    }

                                    return assetTypeFields[assetTypeFieldName];
                                }
                                else 
                                {
                                    return dataItem[potentialEmptyField.field];
                                }
                            };
                        }
                    });
                }
                return modifiedColumns;
            },
            getCustomActions: function () {
                let customFields = [];

                const customActions = [
                    '<a class="anchor-view custom-action" data-type="view" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--view" title="View"></span></a>',
                ];

                customFields.push(
                    {
                        'title': ' ',
                        'filterable': false,
                        'template': function (dataItem) {
                            const actions = [...customActions];
                            if (dataItem.editAssetAllowed) {
                                actions.push('<a class="anchor-edit custom-action" data-type="edit" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--edit" title="Edit"></span></a>');
                            }
                            if (dataItem.createMeterReadingAllowed) {
                                actions.push('<a class="anchor-add-meter-reading custom-action" data-type="add-meter-reading" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--meter" title="Add meter reading"></span></a>');
                            }
                            return actions.join('');
                        },
                        'width': '120px'
                    });

                return customFields;
            },
            onRowClicked: function (item) {
                window.location.href = "./LeasedAssets/Details?id=" + item.id + "&overviewUrl=" + encodeURIComponent(window.location.pathname + window.location.hash);;
            },
            onRowActionClicked: function (item, type) {
                if (!item)
                    return;

                const _this = this;
                switch (type) {
                    case "view":
                        window.location.href = "./LeasedAssets/Details?id=" + item.id + "&overviewUrl=" + encodeURIComponent(window.location.pathname + window.location.hash);
                        break;
                    case "edit":
                        window.location.href = "./LeasedAssets/Details?id=" + item.id + "&action=edit&overviewUrl=" + encodeURIComponent(window.location.pathname + window.location.hash);
                        break;
                    case "add-meter-reading":
                        $.get("/MeterReadings/GetForleasedAsset?assetId=" + item.id, function (assetModel) {
                            ReadingTypes.load(assetModel.MeterReadings.Attributes);

                            _this.MeterReadings.readingTypes = ReadingTypes.getAll();
                            _this.MeterReadings.CurrentSourceSystem = assetModel.MeterReadings.CurrentSourceSystem;
                            _this.detailsViewModel = assetModel;

                            _this.meterReadingsModalActive = true;
                            $('#asset-details--add-meter-reading').modal('show');
                        });

                        break;
                }
            },
            onRowSelectionChanged: function (item, selected) {
                if (selected) {
                    console.log("item '" + item.id + "' was checked")
                }
                else {
                    console.log("item '" + item.id + "' was unchecked")
                }
            },
            onGridChanged: function (selectedItems) {

                // NOTE: currently the selectedItems array only contains the items selected on the current page (not the items on the other pages as well)
                // And because of that, the authorization check (to determine whether the bulk operation controls should be enabled or not), is not complete 
                // as there can be an asset on a other page which blocks the bulk operation enability.

                const bulkEditButton = this.findActionBarButton("ab-bulk-edit");
                if (bulkEditButton != null) {
                    bulkEditButton.enabled = selectedItems.length > 1;
                }

                const fullTerminationButton = this.findActionBarButton("ab-full-termination");
                if (fullTerminationButton) {
                    fullTerminationButton.enabled = selectedItems.length >= 1;
                }

                const assetRequestButton = this.findActionBarButton("ab-asset-request");
                if (assetRequestButton) {
                    assetRequestButton.enabled = selectedItems.length >= 1;
                }

                const partialTerminationButton = this.findActionBarButton("ab-partial-termination");
                if (partialTerminationButton) {
                    partialTerminationButton.enabled = selectedItems.length >= 1;
                }

                const removeButton = this.findActionBarButton("ab-remove-asset");
                if (removeButton != null) {
                    removeButton.enabled = selectedItems.length >= 1 && selectedItems.every(item => item.removeAssetAllowed === true);
                }

                const generateQrButton = this.findActionBarButton("ab-generate-qr-code");
                if (generateQrButton) {
                    generateQrButton.enabled = selectedItems.length >= 1;
                }

                if (this.Grid.totalCount && this.Grid.selectedCount) {
                    const selectAll = document.getElementById('actionbarSelectAll');
                    if (selectAll.checked && this.Grid.selectedCount() < this.Grid.totalCount())
                    selectAll.checked = false;
                }
            },

            onGridColumnReordered: function(fieldName, newIndex, oldIndex){
                const _this = this;

                const changeOrder = (items) => {
                    const changedItem = items.splice(--oldIndex, 1)[0];
                    items.splice(--newIndex, 0, changedItem);                    
                }

                window.clearTimeout (gridConfigChangeTimer);

                gridConfigChangeTimer = window.setTimeout(() => {
                    const newGridConfig = {..._this.GridConfiguration};

                    changeOrder(newGridConfig.SelectedColumns);

                    newGridConfig.ColumnsConfiguration[oldIndex].Index = newIndex;
                    newGridConfig.ColumnsConfiguration[oldIndex].SortOrder = "0_" + newIndex;
                    newGridConfig.ColumnsConfiguration[newIndex].Index = oldIndex;
                    newGridConfig.ColumnsConfiguration[newIndex].SortOrder = "0_" + oldIndex;

                    newGridConfig.ColumnsConfiguration = newGridConfig.ColumnsConfiguration.sort((x, y) => x.Index - y.Index)           

                    $.ajax({
                        type: 'POST',
                        url: '/GridConfiguration/Save',
                        contentType: 'application/json',
                        dataType: 'json',                        
                        data: JSON.stringify({
                            gridName: _this.GridConfiguration.GridName,
                            selectedColumnIds: newGridConfig.SelectedColumns.map(x => x.Id)
                        }),                        
                        success: function (response) {
                            console.info("Stored new grid config");
                            _this.GridConfiguration = newGridConfig;
                        },
                        error: function (error) {
                            console.error(error);
                        },
                    })
                }, 250);                   
            },

            findActionBarButton: function (event) {
                return this.actionbar.ItemsRight.find(f => f.event === event) ? this.actionbar.ItemsRight.find(f => f.event === event) : this.actionbar.ItemsLeft.find(f => f.event === event);
            },

            /**
             * Alerts
             */
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            meterReadingChangedEvent: function (message) {
                this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });
            },
            meterReadingModalClosedEvent: function () {
                this.meterReadingsModalActive = false;
            },
            documentChangedEvent: function (message) {
                this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });
            },
            gridPreviewChangedEvent: function (message) {
                this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });
                this.fetchGridPreviews();
            },
            setGridPreview: function (preview) {                
                const _this = this;

                _this.selectedPreview = preview;
                _this.GridConfiguration.SelectedColumns = preview.selectedColumns.map(column => {
                    let columnSettings = {};
                    columnSettings["Id"] = column.id;
                    columnSettings["DisplayName"] = column.displayName;
                    columnSettings["PropertyName"] = column.propertyName;
                    columnSettings["PropertyType"] = column.propertyType;

                    return columnSettings;
                });
            },
            renderGridPreviewSelector() {
                const _this = this;
                kendo.jQuery("#previewSelector").kendoDropDownList({
                    dataTextField: "name",
                    dataValueField: "id",
                    dataSource: _this.gridPreviews,
                    noDataTemplate: "No previews available",
                    optionLabel: "Select preview",
                    change: function () {
                        const selected = this.value();
                        const preview = _this.gridPreviews.find(preview => preview.id === selected);
                        _this.setGridPreview(preview);
                        if (preview.activeFilters) {
                            _this.Grid.setActiveFilters(preview.activeFilters);
                        }

                        if (preview.activeSorting) {
                            _this.Grid.setActiveSorting(preview.activeSorting);
                        }

                        _this.GridConfiguration.refreshColumns(preview.selectedColumns);
                        _this.Grid.refreshLayout(preview);
                    },
                });

                _this.actionbar.previewSelectorRendered = true;
                $(".navbar-filter .k-picker").append("<span class='glyphicon glyphicon-chevron-down'></span>");
            },
            fetchGridPreviews: function() {
                let _this = this;

                fetchJson('/GridPreviews/List?gridType=LeasedAssets')
                    .then(
                        (result) => {
                            _this.gridPreviews = result;
                            this.renderGridPreviewSelector();
                        },
                        (error) => {
                            console.error(error);
                        });
            },
            refreshData: function () {
            },
        }
    });
}