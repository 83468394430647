import Vue from 'vue'

module.export = Vue.component('editcontractmodal',
    {
        name: 'editcontract',
        props: {
            data: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                EditModel: Object.assign({}, this.data),
                HasBranding: window.hasBranding,
                Types: {
                    1: { id: 'DLL', val: (this.HasBranding ? 'DLL' : 'Leased') },
                    2: { id: 'Non DLL', val: (this.HasBranding ? 'Non DLL' : 'Non Leased') },
                }
            };
        },
        created: function () {
            this.Types[Object.keys(this.Types)[0]].val = (this.HasBranding ? 'DLL' : 'Leased');
            this.Types[Object.keys(this.Types)[1]].val = (this.HasBranding ? 'Non DLL' : 'Non Leased');
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function (e) {
                e.preventDefault();
                const _this = this;
                $("#saveButton").prop("disabled", true);
                _this.clearAlert();
                const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                const isValidMail = re.test(String(_this.EditModel.CsdEmail).toLowerCase());

                if (!isValidMail) {
                    _this.addAlert(
                        {
                            "message": 'Could not edit contract due to invalid email address',
                            "isSuccess": false
                        });
                    $("#saveButton").removeAttr("disabled");
                } else {
                    $.ajax({
                        type: 'POST',
                        url: '/Contracts/Edit',
                        data: JSON.stringify({
                            contractId: _this.EditModel.ContractId,
                            contractName: _this.EditModel.ContractName,
                            // EmailAddress attribute is used, null is valid but empty string is not
                            csdEmail: _this.EditModel.CsdEmail === "" ? null : _this.EditModel.CsdEmail,
                            type: _this.EditModel.Type
                        }),
                        success: function (data) {
                            if (data.success) {
                                _this.$emit('pre-edit', _this.EditModel.CsdEmail);
                                _this.close();
                            } else {
                                _this.addAlert(
                                    {
                                        "message": `Could not edit contract: ${data.data}`,
                                        "isSuccess": false
                                    });
                                $("#saveButton").removeAttr("disabled");
                            }
                        },
                        error: function (data) {
                            _this.addAlert(
                            {
                                "message": 'Could not edit contract due to a server error',
                                "isSuccess": false
                            });
                            $("#saveButton").removeAttr("disabled");
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
            }
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                this.EditModel = Object.assign({}, this.data);
                $('#edit-contract--change-contract').modal('hide');

                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            }
        }
    });